const RefundExchange = ({ penalties, CurrencyCode, passengerType }) => {
    const refundArray = penalties?.filter((item) => item.type === "Refund");
    const exchangeArray = penalties?.filter((item) => item.type === "Exchange");

    return (
        <div>
            <div className="d-flex justify-content-around align-items-start w-fit mt-2">
                <div className="me-5">
                    <h5 className="pb-2">Exchange:</h5>
                    {exchangeArray?.map((it, ind) => (
                        <h6 key={ind}>
                            {it.applicability}: {it.changeable ? `${CurrencyCode()} ${it.amount}` : "NOT_CHANGE"}
                        </h6>
                    ))}
                </div>
                <div>
                    <h5 className="pb-2">Refund:</h5>
                    {refundArray?.map((it, ind) => (
                        <h6 key={ind}>
                            {it.applicability}: {it.refundable ? `${CurrencyCode()} ${it.amount}` : "NON-REFUNDABLE"}
                        </h6>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RefundExchange;
