import React, { useEffect, useState } from "react";
import FlightBookingDetails from "../components/BookingDetailsComponent/FlightBookingDetails";
import { useLocation, useParams } from "react-router-dom";
import VisaBookingDetails from "../components/BookingDetailsComponent/VisaBookingDetails";

function BookingDetails() {
 const { type, booking_id } = useParams();
   const location = useLocation();
  const types = location?.state?.type  || type ;
  const obj = {
    flight: <FlightBookingDetails />,
    "flight-group": <FlightBookingDetails />,
    visa: <VisaBookingDetails />,
  };
  return <div className="">{obj[types]}</div>;
}

export default BookingDetails;
