import React, { useState } from 'react'
import SabreCard from './SabreCard'

const Sabre = ({ data, key, index, expandFlightDetail, toggleDetail, sabre }) => {
    const [showOtherOption, setShowOtherOption] = useState(false);
    return (
        <div className='search_engin_result_box flight_card_shadow bg-white'
            id={`sabre${index}`}
        >
            <SabreCard
                data={data}
                sabre={sabre}
                key={key}
                index={"sabre" + index}
                isExpanded={expandFlightDetail === "sabre" + index}
                toggleDetail={toggleDetail}
                showOtherOption={showOtherOption}
                setShowOtherOption={setShowOtherOption}
            />
            {showOtherOption ? (
                <div>
                    {
                        data?.otherOptions?.map((otherOption, index) => {
                            return (
                                <SabreCard
                                    data={otherOption}
                                    sabre={sabre}
                                    key={index}
                                    index={"sabre" + index}
                                    isExpanded={expandFlightDetail === "sabre" + index}
                                    toggleDetail={toggleDetail}
                                />
                            )
                        })
                    }
                </div>
            ) : null}
        </div>
    )
}

export default Sabre
