import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Serene from "../FlightsTab/OneWaySearchResult/Serene";
import {
  setExpandFlightDetails,
  setReturnFlight,
  setselectedFlight,
} from "../../../redux/features/home/FlightSearchSlice";
import AirsialData from "../FlightsTab/OneWaySearchResult/AirSial";
import Airblue from "../FlightsTab/OneWaySearchResult/Airblue";
import Pia from "../FlightsTab/OneWaySearchResult/pia";
import PiaMulti from "../FlightsTab/MultiCitySearchResult/pia";
import SereneReturn from "../FlightsTab/ReturnSearchResult/Serene";
import AirsialReturn from "../FlightsTab/ReturnSearchResult/AirSial";
import AirblueReturn from "../FlightsTab/ReturnSearchResult/Airblue";
import PiaReturn from "../FlightsTab/ReturnSearchResult/pia";
import SabreCard from "../FlightsTab/OneWaySearchResult/SabreCard";
import RTSabreCard from "../FlightsTab/ReturnSearchResult/SabreCard";
import FlyJinnah from "../FlightsTab/OneWaySearchResult/FlyJinnah";
import Amadeus from "../FlightsTab/OneWaySearchResult/Amadeus";
import AmadeusSegmentCard from "../FlightsTab/OneWaySearchResult/amadeus/AmadeusSegmentCard";
import Timer from "./Timer";
import MultiAmadeusCard from "../FlightsTab/MultiCitySearchResult/multiAmadeus/MultiAmadeusCard";
import FlyJinnahSegmentCard from "../FlightsTab/ReturnSearchResult/FlyJinnah/FlyJinnahSegmentCard";
import PiaHitit from "../FlightsTab/ReturnSearchResult/PiaHitit";
import AirSial from "../FlightsTab/ReturnSearchResult/AirSial";
import SereneApi from "../FlightsTab/ReturnSearchResult/SereneApi";
import SereneApiOneWay from "../FlightsTab/OneWaySearchResult/SereneApi";
import MultiSabre from "../FlightsTab/MultiCitySearchResult/MultiSabre";

const SelectedFlights = ({
  cancel,
  duration,
  availabilityTime,
  setTimer,
  timer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedFlight, expandFlightDetail, returnFlight } = useSelector(
    (state) => state.FlightSearch
  );

  const toggleDetail = (index) => {
    if (expandFlightDetail === index) {
      // If the clicked item is already expanded, collapse it
      dispatch(setExpandFlightDetails(null));
    } else {
      // Expand the clicked item
      dispatch(setExpandFlightDetails(index));
    }
  };

  return (
    <div className="p-4 bg-white shadow-sm borderradius">
      <div className="d-flex justify-content-between align-items-center">
        <h4>
          Your Selected Flight{" "}
          {returnFlight?.logo === "AmadeusReturn" ? null : "Out Bound"}
        </h4>

        <div className="d-flex flex-column gap-2">
          <span
            className="color_primary fs-5 cursorpointer"
            onClick={() => {
              navigate("/search-results");
              dispatch(setselectedFlight(null));
              dispatch(setReturnFlight(null));
            }}
          >
            Change Flight{" "}
            <span className="ms-2">
              <FaRegEdit />
            </span>
          </span>

          <span className="color_primary fs-5 ">
            {cancel ? (
              <span className="text-danger color_primary fs-6 ">
                Booking availability is expired.
              </span>
            ) : (
              <Timer
                duration={duration}
                availabilityTime={availabilityTime}
                setTimer={setTimer}
                timer={timer}
              />
            )}
          </span>
        </div>
      </div>
      {/* //////////////////// one way  */}
      <div>
        {/* {selectedFlight?.logo === "Serene" && (
          <Serene
            serene={selectedFlight?.flight}
            index={"serene" + 1}
            isExpanded={expandFlightDetail === "serene" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )} */}

        {selectedFlight?.logo === "Serene" && (
          <SereneApiOneWay
            serene={selectedFlight?.flight}
            index={"serene" + 1}
            isExpanded={expandFlightDetail === "serene" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight}
          />
        )}
        {selectedFlight?.logo === "Sial" && (
          <AirsialData
            data={selectedFlight?.flight}
            index={"sial" + 1}
            isExpanded={expandFlightDetail === "sial" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "AirBlue" && (
          <Airblue
            data={selectedFlight?.flight}
            index={"airblue" + 1}
            isExpanded={expandFlightDetail === "airblue" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "HITIT" && (
          <Pia
            piadata={selectedFlight?.flight}
            index={"HITIT" + 1}
            isExpanded={expandFlightDetail === "HITIT" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "Sabre" && (
          <SabreCard
            data={selectedFlight?.flight}
            checkoutfare={selectedFlight?.fare}
            sabre={selectedFlight?.sabre}
            index={"Sabre" + 1}
            isExpanded={expandFlightDetail === "Sabre" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "FlyJinnah" && (
          <FlyJinnah
            data={selectedFlight?.flight}
            index={"FlyJinnah" + 1}
            isExpanded={expandFlightDetail === "FlyJinnah" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "FlyJinnahR" && (
          <FlyJinnahSegmentCard
            flyjinnahItem={selectedFlight?.flight}
            index={"FlyJinnah" + 1}
            isExpanded={expandFlightDetail === "FlyJinnah" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight?.baggageName}
            selectedFlight={selectedFlight}
            selOutbound={
              selectedFlight?.outboundFare?.["ns1:bundledServiceName"]
            }
            selInBound={selectedFlight?.inboundFare?.["ns1:bundledServiceName"]}
          />
        )}

        {selectedFlight?.logo === "Amadeus" && (
          <Amadeus
            amadeusItem={selectedFlight?.fare}
            index={"Amadues" + 1}
            isExpanded={expandFlightDetail === "Amadues" + 1}
            toggleDetail={toggleDetail}
            expandFlightDetail={expandFlightDetail}
            selectedFlight={selectedFlight}
          />
        )}
      </div>
      {/* //////////////////// return  */}
      {!(returnFlight?.logo === "AmadeusReturn") ? null : (
        <h4 className="py-3">Your Selected Flight In Bound</h4>
      )}
      <div>

        {
          selectedFlight?.logo === "SabreReturn" && (
            <RTSabreCard
              data={selectedFlight?.flight}
              checkoutfare={selectedFlight?.fare}
              sabre={selectedFlight?.sabre}
              index={"Sabre" + 1}
              isExpanded={expandFlightDetail === "Sabre" + 1}
              toggleDetail={toggleDetail}
              selectedFare={selectedFlight?.selectedBrandFare}
            />
          )
        }
        {
          selectedFlight?.logo === "SialReturn" && (
            <AirSial
              data={selectedFlight?.flight}
              key={"sial" + 1}
              index={"sial" + 1}
              isExpanded={expandFlightDetail === "sial" + 1}
              toggleDetail={toggleDetail}
              selectedFlight={selectedFlight}
            />
          )
        }
        {selectedFlight?.logo === "HITIT-RETURN" && (
          <PiaHitit
            piadata={selectedFlight?.flight}
            key={"Hitit" + 1}
            index={"Hitit" + 1}
            isExpanded={expandFlightDetail === "Hitit" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight}
          />
        )}

        {selectedFlight?.logo === "Serene-api" && (
          <SereneApi
            serene={selectedFlight?.flight}
            key={"Serene-Api" + 1}
            index={"Serene-Api" + 1}
            isExpanded={expandFlightDetail === "Serene-Api" + 1}
            toggleDetail={toggleDetail}
            selectedFareSerene={selectedFlight}
          />
        )}

        {returnFlight?.logo === "Serene" && (
          <SereneReturn
            serene={returnFlight?.flight}
            index={"serene" + 2}
            isExpanded={expandFlightDetail === "serene" + 2}
            toggleDetail={toggleDetail}
            selectedFare={returnFlight?.baggageName}
          />
        )}
        {returnFlight?.logo === "Sial" && (
          <AirsialReturn
            data={returnFlight?.flight}
            index={"sial" + 2}
            isExpanded={expandFlightDetail === "sial" + 2}
            toggleDetail={toggleDetail}
            selectedFare={returnFlight?.baggageName}
          />
        )}
        {selectedFlight?.logo === "AirBlueReturn" && (
          <AirblueReturn
            data={selectedFlight?.flight}
            key={"airblue" + 1}
            index={"airblue" + 1}
            isExpanded={expandFlightDetail === "airblue" + 1}
            selectedFlight={selectedFlight}
            toggleDetail={toggleDetail}
          />
          // <AirblueReturn
          //   data={returnFlight?.flight}
          //   index={"airblue" + 2}
          //   isExpanded={expandFlightDetail === "airblue" + 2}
          //   toggleDetail={toggleDetail}
          //   selectedFare={returnFlight?.baggageName}
          // />
        )}
        {returnFlight?.logo === "HITIT" && (
          <PiaReturn
            piadata={returnFlight?.flight}
            index={"HITIT" + 2}
            isExpanded={expandFlightDetail === "HITIT" + 2}
            toggleDetail={toggleDetail}
            selectedFare={returnFlight?.baggageName}
          />
        )}

        {selectedFlight?.logo === "AmadeusReturn" && (
          <AmadeusSegmentCard
            amadeusItem={selectedFlight?.fare}
            index={"Amadues" + 1}
            isExpanded={expandFlightDetail === "Amadues" + 1}
            toggleDetail={toggleDetail}
            expandFlightDetail={expandFlightDetail}
            selectedFlight={selectedFlight}
          />
        )}
      </div>
      <div>

        {/* multi */}
        {selectedFlight?.logo === "multiSabre" && (
          <MultiSabre
            sabreItem={selectedFlight?.flight}
            sabre={selectedFlight?.sabre}
            selectedFare={selectedFlight?.fare}
            index={"Sabre" + 1}
            isExpanded={expandFlightDetail === "Sabre" + 1}
            toggleDetail={toggleDetail}
            expandFlightDetail={expandFlightDetail}
          />
        )}

        {selectedFlight?.logo === "Amadeusamulti" && (
          <MultiAmadeusCard
            amadeusItem={selectedFlight?.fare}
            index={"Amadues" + 1}
            isExpanded={expandFlightDetail === "Amadues" + 1}
            toggleDetail={toggleDetail}
            expandFlightDetail={expandFlightDetail}
            selectedFlight={selectedFlight}
          />
        )}

        {selectedFlight?.logo === "HITIT-MULTI" && (
          <PiaMulti
            piadata={selectedFlight?.flight}
            key={"Hitit" + 1}
            index={"Hitit" + 1}
            isExpanded={expandFlightDetail === "Hitit" + 1}
            toggleDetail={toggleDetail}
            selectedFare={selectedFlight}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedFlights;
