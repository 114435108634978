import React, { useEffect, useState } from 'react'
import { CurrencyCode, CurrencyExchange, CurrencyExchangeToPKR } from '../../../../Utils/currencyExchange';
import SabreLogo from "../../../../assets/images/airlineLogo/SB.png";
import MultiSabreIteneries from './MultiSabreIteneries';
import { setExpandFlightDetails, setselectedFlight } from '../../../../redux/features/home/FlightSearchSlice';
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getSectorsName } from '../../../../Utils/FlightSector';
import { pricingairline } from '../../../../Utils/airlinePricing';
import { codeShareSabre, ExclusiveSetors } from '../../../../Utils/exclusiveSector';
import { TwoDecimalPoint } from '../../../../Utils/FixedTwoDecimal';
import SabrePenaltyAccordion from "../ReturnSearchResult/SabrePenaltyAccordion";
import { NODEURL } from '../../../../redux/features/ApiConfig';
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AirlineSeatLegroomNormalIcon from "@mui/icons-material/AirlineSeatLegroomNormal";
import { MdFlight, MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import Slider from 'react-slick/lib/slider';
import { IoIosStarOutline } from "react-icons/io";
import { Box } from '@mui/material';
import { LightTooltip } from './LightTooltip';

const MultiSabreCard = ({
    sabreItem,
    sabre,
    key,
    selectedFare,
    index,
    isExpanded,
    toggleDetail,
    showOtherOption,
    setShowOtherOption }) => {
    const dispatch = useDispatch()
    const [selectedTab, setSelectedTab] = useState("Fare options");
    const [selectedBrandFare, setSelectedBrandFare] = useState(sabreItem?.pricingInformation?.at(0));
    // get state from redux state
    const { airlinePricing } = useSelector(
        (state) => state?.price_airlines_suppRoleSlice
    );
    const { selectedFlight } = useSelector(
        (state) => state.FlightSearch
    );
    const { addAirLinesData } = useSelector(
        (state) => state?.price_airlines_suppRoleSlice
    );
    const { newSupplier } = useSelector((state) => state.supplier);
    const sabreSupplier = newSupplier?.find((it) => it?.supp_name === "IATA BSP");
    const { user } = useSelector((state) => state.user);
    //local storage data
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

    // flight segment
    const fligthSegment = sabreItem?.legs;

    const segmentLength = fligthSegment?.flatMap(({ schedules }) => schedules);

    // airline pricing
    const pricing = airlinePricing?.find((item) => {
        return (
            item.bg_id === searchValues?.agent?.agent_grp_id &&
            item?.add_airline?.code_alpha &&
            item?.add_airline?.code_alpha === fligthSegment?.at(0)?.schedules?.at(0)?.carrier?.marketing
        );
    });

    const airline = addAirLinesData?.find((it) => {
        return it.code_alpha === fligthSegment?.at(0)?.schedules?.at(0)?.carrier?.marketing;
    });

    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const calculatePassengerTotal = (fare, totalPax) => {
        const baseFare = CurrencyExchange(Number(Number(fare?.passengerTotalFare?.totalFare) - Number(fare?.passengerTotalFare?.totalTaxAmount)));
        const totalTaxes = CurrencyExchange(Number(fare?.passengerTotalFare?.totalTaxAmount));

        const price = pricingairline(
            airline,
            pricing,
            baseFare,
            totalTaxes,
            getSectorsName(locDeparture, locArrival),
            ExclusiveSetors(
                searchValues,
                pricing,
                fare?.fareComponents?.at(0)?.segments?.at(0)?.segment?.bookingCode
            ),
            codeShareSabre(segmentLength),
            // fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
            "ECONOMY",
            segmentLength?.length,
            fare?.passengerType !== "INF"
        );
        return price?.total_fare * totalPax;
    };

    const totalFarePrice = (paxesPrices) => {
        const total = paxesPrices.reduce((sum, pax) => {
            return sum + (pax?.passengerInfo ? calculatePassengerTotal(pax.passengerInfo, pax.passengerInfo?.passengerNumber) : 0);
        }, 0);

        return TwoDecimalPoint(total);
    };


    const calculatePassengerDiscountTotal = (fare, totalPax) => {
        const baseFare = CurrencyExchange(Number(Number(fare?.passengerTotalFare?.totalFare) - Number(fare?.passengerTotalFare?.totalTaxAmount)));
        const totalTaxes = CurrencyExchange(Number(fare?.passengerTotalFare?.totalTaxAmount));

        const price = pricingairline(
            airline,
            pricing,
            baseFare,
            totalTaxes,
            getSectorsName(locDeparture, locArrival),
            ExclusiveSetors(
                searchValues,
                pricing,
                fare?.fareComponents?.at(0)?.segments?.at(0)?.segment?.bookingCode
            ),
            codeShareSabre(segmentLength),
            // fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
            "ECONOMY",
            segmentLength?.length,
            fare?.passengerType !== "INF"
        );
        return price?.sp * totalPax;
    };

    const totalDiscountFarePrice = (paxesPrices) => {
        const total = paxesPrices.reduce((sum, pax) => {
            return sum + (pax?.passengerInfo ? calculatePassengerDiscountTotal(pax.passengerInfo, pax.passengerInfo?.passengerNumber) : 0);
        }, 0);

        if (total > 0) {
            return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
        }

    };

    const findPrices = (paxItem, paxType) => {
        const pax = paxItem?.find((paxItem) => paxItem?.passengerInfo?.passengerType === paxType)
        return {
            total: pax?.passengerInfo?.passengerTotalFare?.totalFare, basePrice: pax?.passengerInfo?.passengerTotalFare?.equivalentAmount, taxes: pax?.passengerInfo?.passengerTotalFare?.totalTaxAmount,
            paxesBreakUP: pax?.passengerInfo?.taxes
        }
    }



    const elapsedTime = (time) => {
        const duration = moment.duration(time, "minutes");
        const hours = duration.hours().toString().padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    function calculateTotalTimeDifference(time1, time2) {
        const moment1 = moment(time1, "HH:mm:ssZ");
        const moment2 = moment(time2, "HH:mm:ssZ");
        const timeDifference = Math.abs(moment1 - moment2);
        const duration = moment.duration(timeDifference);
        const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    }
    return (
        <div className="search_engin_result_box flight_card_shadow bg-white">
            <div
                className="shadow cursorpointer d-flex justify-content-center align-items-center"
                onClick={() => {
                    toggleDetail(index);
                }}
            >
                <div className="col-9">
                    {sabreItem?.legs?.map((itinery, index, array) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    borderBottom:
                                        index === array?.length - 1
                                            ? "none"
                                            : "1px solid lightgray",
                                }}
                            >
                                <MultiSabreIteneries
                                    // totalDiscount={totalDiscount}
                                    array={array}
                                    // timeDuration={timeDuration}
                                    selectedFlight={selectedFlight}
                                    segments={itinery?.schedules}
                                    sabreItem={sabreItem}
                                    toggleDetail={toggleDetail}
                                    index={index}
                                    showOtherOption={showOtherOption}
                                    setShowOtherOption={setShowOtherOption}
                                    isExpanded={isExpanded}
                                    calculateTotalTimeDifference={calculateTotalTimeDifference}
                                    elapsedTime={elapsedTime}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="col-3 left-shadow text-center align-self-stretch pt-5">
                    <div className=" pt-5">
                        <h6 className="text-danger">{totalDiscountFarePrice(sabreItem?.pricingInformation?.at(0)?.fare?.passengerInfoList)}</h6>
                        <div className="d-flex align-items-center justify-content-center pt-2">
                            <h4>{`${CurrencyCode()} ${totalFarePrice(sabreItem?.pricingInformation?.at(0)?.fare?.passengerInfoList)}`}</h4>
                            <img src={SabreLogo} alt="" width={35} height={35} className="mb-2 ms-2" />
                        </div>
                    </div>

                    <button
                        onClick={(e) => {
                            toggleDetail(index);
                        }}
                        className="button_style w-75 mb-2 cursorpointer mt-3"
                        type="button"
                    >
                        <div className="">
                            <MenuOpenIcon className="me-1" />
                            Flight Detail
                        </div>
                    </button>
                    {!selectedFare && (
                        <>
                            {sabreItem?.otherOptions?.length > 0 && (
                                <button
                                    className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setExpandFlightDetails(null));
                                        setShowOtherOption(!showOtherOption);
                                    }}
                                >
                                    <div>
                                        <UnfoldMoreIcon className="me-1" />
                                        {showOtherOption
                                            ? "Hide Options"
                                            : `${sabreItem?.otherOptions?.length} More Options`}
                                    </div>
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

            {isExpanded ? (
                <div className="p-4">
                    <div className="d-flex mb-4 flight_details_tabs">
                        {["Fare options", "Flight details", "Fare Rules"].map((item, index) => {
                            const active = selectedTab === item;
                            return (
                                <button
                                    key={index}
                                    onClick={() => setSelectedTab(item)}
                                    className={active ? "active" : ""}
                                >
                                    {item}
                                </button>
                            );
                        })}
                    </div>

                    {selectedTab === "Flight details" && (
                        <>
                            <div className="row rounded-2 shadow-sm border">
                                {
                                    fligthSegment?.map((segments, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`border-bottom py-4`}
                                            >
                                                {segments?.schedules?.map((item, inx, array) => {
                                                    return (
                                                        <ConnectedFlight
                                                            item={item}
                                                            inx={inx}
                                                            calculateTotalTimeDifference={
                                                                calculateTotalTimeDifference
                                                            }
                                                            elapsedTime={elapsedTime}
                                                            fligthSegment={array}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    )}

                    {
                        selectedTab === "Fare options" ? (
                            <AdditionalFares
                                data={sabreItem}
                                sabre={sabre}
                                selectedBrandFare={selectedBrandFare}
                                setSelectedBrandFare={setSelectedBrandFare}
                                totalFarePrice={totalFarePrice}
                                totalDiscountFarePrice={totalDiscountFarePrice}
                                calculatePassengerTotal={calculatePassengerTotal}
                                calculateTotalTimeDifference={calculateTotalTimeDifference}
                                findPrices={findPrices}
                                fligthSegment={fligthSegment}
                                segmentLength={segmentLength}
                                selectedFare={selectedFare}
                            />
                        ) : null}
                    <div>
                        {selectedTab === "Fare Rules" && (
                            <div className="rounded-2 search_engin_result_box bg-white p-4">
                                <SabrePenaltyAccordion selectedBrandFare={selectedBrandFare} CurrencyCode={CurrencyCode} />
                            </div>
                        )
                        }
                    </div>
                </div>
            ) : null}

        </div>
    )
}

export default MultiSabreCard;


// connected flight component
const ConnectedFlight = ({
    item,
    inx,
    calculateTotalTimeDifference,
    fligthSegment,
}) => {
    const [airport_name, setairport_name] = useState("");
    const [arr_airport_name, setArrairport_name] = useState("");
    useEffect(() => {
        const fetchDataDep = async () => {
            try {
                const response = await NODEURL.get(
                    `/api/v1/b2b/airportlist/filter/?query_param=${item?.departure?.airport}`
                );
                const data = response.data.data[0]?.airport_name;
                setairport_name(data);
            } catch (error) {
            }
        };

        const fetchDataArr = async () => {
            try {
                const response = await NODEURL.get(
                    `/api/v1/b2b/airportlist/filter/?query_param=${item?.arrival?.airport}`
                );
                const data = response.data.data[0]?.airport_name;
                setArrairport_name(data);
            } catch (error) {
            }
        };

        fetchDataDep();
        fetchDataArr();
    }, []);

    return (
        <div key={inx}>
            <div className="row align-items-center">
                <div className="col-2">
                    <img
                        src={`/NEW_LOGO/${item?.carrier?.operating}.png`}
                        alt="logo"
                        width={70}
                        height={70}
                    />
                    <h6 className="mt-3 ms-2">
                        {item?.carrier?.operating} {item?.carrier?.operatingFlightNumber}
                    </h6>
                </div>
                <div className="col-10 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="me-3">
                            <MdOutlineFlightTakeoff size={30} className="color_primary" />
                        </div>
                        <div className="">
                            <h6>{moment(item?.travdate).format("ddd DD-MMM-YY")}</h6>
                            <h6 className="my-3">
                                {moment(item?.departure?.time, "HH:mm:ssZ").format("HH:mm")}
                            </h6>
                            <h6>
                                {item?.departure?.airport} - {airport_name}
                            </h6>
                            {item?.departure?.terminal && (
                                <h6 className="color_primary">
                                    (Terminal - {item?.departure?.terminal})
                                </h6>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className="d-flex">
                            <MdFlight className="color_primary fs-5 rotate-right" />
                            <h6 className="text-center">Trip Time</h6>
                        </div>
                        <div className="border_rounded mb-2" />
                        <h6 className="text-center">{calculateTotalTimeDifference(item?.departure?.time, item?.arrival?.time)}</h6>
                    </div>
                    <div className="col-5 d-flex align-items-center ps-4">
                        <div className="me-3">
                            <MdOutlineFlightLand size={30} className="color_primary" />
                        </div>
                        <div>
                            <h6>{moment(item?.travdate).format("ddd DD-MMM-YY")}</h6>
                            <h6 className="my-3">
                                {moment(item?.arrival?.time, "HH:mm:ssZ").format("HH:mm")}
                            </h6>
                            <h6>
                                {item?.arrival?.airport} - {arr_airport_name}
                            </h6>
                            {item?.arrival?.terminal && (
                                <h6 className="color_primary">
                                    (Terminal - {item?.arrival?.terminal})
                                </h6>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {inx < fligthSegment.length - 1 && (
                <div className="col-12 d-flex justify-content-center align-items-center my-4">
                    <div className="border_rounded w-25" />
                    <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
                        <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
                        <h6 className="pt-2">
                            Layover time:{" "}
                            <span className="fs-5">
                                {" "}
                                {calculateTotalTimeDifference(
                                    item?.arrival?.time,
                                    fligthSegment[inx + 1]?.departure?.time
                                )}
                            </span>
                        </h6>
                    </div>
                    <div className="border_rounded w-25" />
                </div>
            )}
        </div>
    );
};

//additional fares component
const AdditionalFares = ({
    data,
    sabre,
    selectedBrandFare,
    setSelectedBrandFare,
    fligthSegment,
    totalFarePrice,
    totalDiscountFarePrice,
    findPrices,
    calculatePassengerTotal,
    calculateTotalTimeDifference,
    segmentLength,
    selectedFare,
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const segmentsLength = segmentLength?.length;

    const CustomPrevArrow = (props) => (
        <div
            onClick={props.onClick}
            className={`${data?.pricingInformation?.length <= 4 ? "d-none" : "d-block"
                } position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color`}
        >
            <FaChevronCircleLeft size={30} />
        </div>
    );
    const CustomNextArrow = (props) => (
        <div
            onClick={props.onClick}
            className={`${data?.pricingInformation?.length <= 4 ? "d-none" : "d-block"
                } position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index`}
        >
            <FaChevronCircleRight size={30} />
        </div>
    );

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };

    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

    const getAirportInfo = async (airportCode) => {
        const { data } = await NODEURL.get(
            `/api/v1/b2b/airport-list/${airportCode}`
        );
        const { code, city, airport_name } = data?.data;
        return `${code} - ${airport_name} - ${city}`;
    };

    const handleBooking = async (item) => {
        const segments = await Promise.all(
            segmentLength?.map(async (seg, index) => {

                const baggageSegment = item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.baggageInformation?.flatMap(({ segments }) => segments)?.at(index);
                let baggage = "nil baggage";
                if (baggageSegment) {
                    const { weight, unit, pieceCount } = baggageSegment;
                    if (weight > 0) {
                        baggage = `${weight} ${unit}`;
                    } else if (pieceCount > 0) {
                        baggage = `${pieceCount} piece${pieceCount > 1 ? "s" : ""}`;
                    }
                }

                const locations = await Promise.all([
                    getAirportInfo(seg?.departure?.airport),
                    getAirportInfo(seg?.arrival?.airport),
                ]);
                return {
                    departure_date: seg?.travdate,
                    baggage: baggage,
                    departure_time: moment(seg?.departure?.time, "hh:mm:ssZ").format("hh:mm"),
                    arrival_date: seg?.travdate,
                    arrival_time: moment(seg?.arrival?.time, "hh:mm:ssZ").format("hh:mm"),
                    origin_terminal: seg?.departure?.terminal || "",
                    desti_terminal: seg?.arrival?.terminal || "",
                    flight_number: String(seg?.carrier?.operatingFlightNumber),
                    number_in_party: "",
                    res_book_desig_code: item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(index)?.segments?.at(0)?.segment?.bookingCode,
                    status: "NN",
                    des_location: locations?.at(1),
                    dest_code: seg?.arrival?.airport,
                    operatingairline_code: seg?.carrier?.operating,
                    marketing_airline: seg?.carrier?.marketing,
                    orig_location: locations?.at(0),
                    orig_code: seg?.departure?.airport,
                    marriage_grp: "I",
                    cabin_class: item?.fare?.passengerInfoList?.at(0)
                        ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName,
                    segment_type: getSectorsName(
                        searchValues?.locationDep,
                        searchValues?.locationArrival
                    ),
                };
            })
        )
        let bookingBody = {
            sabre: sabre,
            flight: data,
            fare: item,
            gds: "IATA BSP",
            price: totalFarePrice(item?.fare?.passengerInfoList),
            ttl_agent_fare: TwoDecimalPoint(
                CurrencyExchangeToPKR(Number(totalFarePrice(item?.fare?.passengerInfoList) || 0))
            ),
            // isCodeShare,
            // isEconomy,
            segmentsLength,
            // isExclusiveSector,
            ttlagent_curr_fare: Number(totalFarePrice(item?.fare?.passengerInfoList) || 0),
            ttl_supplier_fare: ~~item?.fare?.totalFare?.totalPrice || 0,
            ttlsup_curr_fare:
                TwoDecimalPoint(~~CurrencyExchange(item?.fare?.totalFare?.totalPrice)) || 0,
            adlt_ttl_d_curr:
                CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.total) || 0,
            chld_ttl_d_curr:
                CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.total) || 0,
            inft_ttl_d_curr:
                CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.total) || 0,
            adultBaseFare:
                CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.basePrice) || 0,
            childBaseFare: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.basePrice) || 0,
            infantBaseFare: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.basePrice) || 0,
            adultTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.taxes) || 0,
            childTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.taxes) || 0,
            infantTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.taxes) || 0,
            ADLT_TAXES: findPrices(item?.fare?.passengerInfoList, "ADT")?.paxesBreakUP || 0,
            CHLD_TAXES: findPrices(item?.fare?.passengerInfoList, "CNN")?.paxesBreakUP || 0,
            INFT_TAXES: findPrices(item?.fare?.passengerInfoList, "INF")?.paxesBreakUP || 0,
            // isEconomy:
            //   brandFare?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin ===
            //   "ECONOMY",
            // baggageName: brandFare.travelerPricings
            //   ?.at(0)
            //   ?.fareDetailsBySegment?.at(0)?.brandedFare,
            flightNumber: segmentLength?.at(0)?.carrier?.operatingFlightNumber,
            depDate: moment(
                segmentLength?.at(0)?.travdate
            ).format("ddd DD-MMM-YY"),
            depTime: moment(segmentLength?.at(0)?.departure?.time, "HH:mm:ssZ").format("HH:mm"),
            arrDate: moment(
                segmentLength?.at(segmentLength?.length - 1)?.travdate
            ).format("ddd DD-MMM-YY"),
            arrTime: moment(segmentLength?.at(segmentLength?.length - 1)?.arrival?.time, "HH:mm:ssZ").format("HH:mm"),
            flightDuration: calculateTotalTimeDifference(
                segmentLength?.at(0)?.departure?.time,
                segmentLength?.at(segmentLength?.length - 1)?.arrival?.time
            ),
            logo: "multiSabre",
            code: item?.fare?.validatingCarrierCode,

            booking: {
                gds: "SABRE",
                pnr: "",
                agent_name: sabre?.agent_name,
                segments,
                tp_ur_code: "",
                platting_carrier: item?.fare?.governingCarriers,
                tp_ssr_verssion: 0,
                tp_trace_id: "",
            },
        };

        dispatch(
            setselectedFlight({
                ...bookingBody,
            })
        );

        navigate("/check-out");
    }

    return (
        <>
            <Slider {...settings}>
                {data?.pricingInformation?.at(0) && (() => {
                    const item = data?.pricingInformation?.at(0);

                    const baggage =
                        item?.fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation?.[0]?.segments?.at(
                            item?.fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation?.[0]?.segments.length - 1
                        );

                    if (item?.soldOut) {
                        return (
                            <div className="px-4 ms-2 mb-2 mt-2 pt-2">
                                <div className="fs-5 color_primary fw-bold">
                                    {item?.soldOut?.soldOutLegs?.at(0)?.brandDescription}
                                </div>
                                <div className="h5 text-danger" style={{ marginTop: 32 }}>
                                    Sold Out
                                </div>
                                <hr />
                            </div>
                        );
                    }

                    return (
                        <div
                            className="px-2 flight_brandFare_shodow ms-2 mb-2 mt-2 pt-2"
                            onClick={() => {
                                setSelectedBrandFare(item);
                                // handleBooking(item);
                            }}
                        >
                            {/* <div
                                className="text-danger d-flex align-items-center gap-2"
                            >
                                <AirlineSeatLegroomNormalIcon />{" "}
                                <h6 className="mt-2">
                                    {`${item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(0)?.segments?.at(0)?.segment?.seatsAvailable} Seat Left `}
                                </h6>
                            </div>
                            <hr /> */}
                            <div>
                                <div className="d-flex align-items-center pt-2">
                                    <Box width={40} className="color_primary">
                                        <IoIosStarOutline className="color_primary" size={24} />
                                    </Box>
                                    <h6 className="pt-2">Hand Carry: 7 Kg</h6>
                                </div>
                                <div className="d-flex align-items-center pt-3">
                                    <Box width={30} className="color_primary">
                                        <IoIosStarOutline className="color_primary" size={24} />
                                    </Box>
                                    <h6 className="ps-1 pt-2">
                                        Check Bag:{" "}
                                        {baggage?.weight !== undefined
                                            ? `${baggage?.weight === 0 ? "Nil Baggage" : `${baggage.weight} kg`}`
                                            : `${baggage?.pieceCount} piece`
                                        }
                                    </h6>
                                </div>
                                <Box display={"flex"} alignItems={"center"} pt={2}>
                                    <IoIosStarOutline className="color_primary" size={24} />
                                    <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} pt={2}>
                                    <Box>
                                        <IoIosStarOutline className="color_primary" size={24} />
                                    </Box>
                                    <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                                </Box>
                            </div>
                            <hr />
                            <h6 className="text-danger">{totalDiscountFarePrice(item?.fare?.passengerInfoList)}</h6>
                            <LightTooltip
                                title={
                                    <div className="p-3" onClick={(e) => e.stopPropagation()}>
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="d-flex me-4">
                                                <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                                <h5>* {searchValues?.adultsCount}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(0)?.passengerInfo, 1)}
                                            </h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end my-2">
                                            <div className="d-flex">
                                                <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                                <h5>* {searchValues?.childCount}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(1)?.passengerInfo, 1) || 0}
                                            </h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="d-flex">
                                                <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                                <h5>* {searchValues?.infantNo}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(2)?.passengerInfo, 1) || 0}
                                            </h6>
                                        </div>
                                    </div>
                                }
                                placement="top"
                            >
                                <button
                                    type="button"
                                    className={`${selectedBrandFare?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName === item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName ? "button_acitve" : "button_style"} px-4 mb-2`}
                                    onClick={() => {
                                        if (!selectedFare) {
                                            setSelectedBrandFare(item);
                                            handleBooking(item);
                                        }
                                    }}
                                >
                                    {`${CurrencyCode()} ${totalFarePrice(item?.fare?.passengerInfoList)}`}
                                </button>
                            </LightTooltip>
                        </div>
                    );
                })()}
            </Slider>

        </>
    );
};
