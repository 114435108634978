import React, { useState } from "react";

import NavigationBar from "./NavigationBar";
import ProfileHeader from "./ProfileHeader";

import "../../Styles/AuthHeader.css";
import "../../Styles/fontawesome/all.css";
import "../../Styles/fontawesome/fontawesome.css";
import "../../Styles/menubar.css";
import MobileMenu from "./MobileMenu";
import { useSelector } from "react-redux";

function Header() {
  const [showMobileMenu, setshowMobileMenu] = useState(false);
  return (
    <div className="no-print">
      <ProfileHeader setshowMobileMenu={setshowMobileMenu} />

      <NavigationBar />

      {/* mobile        ******************** */}
      {/* {showMobileMenu && (
        <div className="backdrop_blur_cotianer d-md-none">
          <MobileMenu setshowMobileMenu={setshowMobileMenu} />
        </div>
      )} */}
    </div>
  );
}

export default Header;
