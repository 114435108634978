import { useEffect, useState } from "react";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { CurrencyCode, CurrencyExchange, CurrencyExchangeToPKR } from "../../Utils/currencyExchange";

const PaxRecord = ({ booking, user, invoiceNumber, total }) => {
  const [viewStatement, setViewStatement] = useState();

  useEffect(() => {
    if (user?.user_id !== 1) {
      if (user?.agents_comp?.view_statement === "Only Total") {
        setViewStatement("Only Total");
      } else if (user?.agents_comp?.view_statement === "WithOut Discount/PSF") {
        setViewStatement("WithOut Discount/PSF");
      } else {
        setViewStatement("Full Statement");
      }
    } else {
      setViewStatement("Full Statement");
    }
  }, [user]);

  return (
    <>
      <h4 className="theme-text-color">Description:</h4>
      {/* Only For Admin */}
    

      {/* Agent ,Full Statment */}
      {viewStatement === "Full Statement" ? (
        <table class="table table-bordered border-dark align-items-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th scope="col" style={{ width: 200 }}>
                Passenger(Type)
              </th>
              <th scope="col" style={{ width: 200 }}>
                Ticket No
              </th>
              <th scope="col" style={{ width: 200 }}>
                PNR
              </th>
              <th scope="col" style={{ width: 200 }}>
                Sector
              </th>

              <th scope="col" style={{ width: 200 }}>
                Fare
              </th>
              <th scope="col" style={{ width: 200 }}>
                Taxes
              </th>
              <th scope="col" style={{ width: 200 }}>
                Total
              </th>
              <th scope="col" style={{ width: 200 }}>
                DIS/PSF
              </th>

              <th scope="col" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody
            className="text-dark "
            style={{ fontSize: "20px", color: "#000000" }}
          >
            {booking?.booking?.FlightBookingModels &&
              booking?.booking?.FlightBookingModels[0]?.pax_tables
                ?.filter((inv) => inv?.inv_number === invoiceNumber)
                ?.map((pax, index) => (
                  <tr>
                    <td>
                      {pax?.pax_type + "-" + pax?.fName + " " + pax?.lname}
                    </td>
                    <td>{pax?.tkt_number}</td>
                    <td>
                      {booking?.booking?.FlightBookingModels &&
                        booking?.booking?.FlightBookingModels[0]?.pnr}
                    </td>
                    <td>
                      {booking?.booking?.FlightBookingModels[0]?.FlightSegmentModels?.map(
                        (segment, i) => (
                          <>
                            {" "}
                            <span key={i}>
                              {segment?.origin?.split("-")[0]}
                            </span>
                            <span>{segment?.destination?.split("-")[0]} </span>
                          </>
                        )
                      )}
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <span className="text-primary">
                        {" "}
                        {CurrencyCode()} {pax?.baseFare_s_cur}
                      </span>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Number(pax?.ttl_tax_s_cur) +
                            Number(pax?.agt_gst_s_cur) +
                            Number(pax?.agt_wht_s_cur)
                          )}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Number(pax?.baseFare_s_cur) +
                            Number(pax?.ttl_tax_s_cur) +
                            Number(pax?.agt_gst_s_cur) +
                            Number(pax?.agt_wht_s_cur)
                          )}
                        </span>
                      </div>
                    </td>

                    <td>
                      {pax?.agt_psf_d_cur > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                          }}
                        >
                          <span className="text-primary">
                            {" "}
                            {CurrencyCode()} {pax?.agt_psf_s_cur}
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                          }}
                        >
                          <span className="text-primary">
                            {" "}
                            {CurrencyCode()} {pax?.agt_sp_s_cur}
                          </span>
                        </div>
                        // TwoDecimalPoint(
                        //   CurrencyExchange(pax?.pax?.agt_sp_d_cur)
                      )}
                    </td>

                    <td className="d-flex flex-column gap-2 text-primary">
                      {CurrencyCode() + " " + pax?.ttl_agt_pay_s_cur}
                    </td>
                  </tr>
                ))}
            <tr>
              <td colSpan="8" style={{ textAlign: "right", fontWeight: 700 }}>
                Grand Total
              </td>
              <td
                colSpan="1"
                style={{ fontWeight: 700 }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {total?.baseSlctC}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {/* Agent ,Only Total */}
      {viewStatement === "Only Total" ? (
        <table class="table table-bordered border-dark align-items-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th scope="col" style={{ width: 200 }}>
                Passenger(Type)
              </th>
              <th scope="col" style={{ width: 200 }}>
                Ticket No
              </th>
              <th scope="col" style={{ width: 200 }}>
                PNR
              </th>
              <th scope="col" style={{ width: 200 }}>
                Sector
              </th>

              <th scope="col" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody
            className="text-dark "
            style={{ fontSize: "20px", color: "#000000" }}
          >
            {booking?.booking?.FlightBookingModels &&
              booking?.booking?.FlightBookingModels[0]?.pax_tables
                ?.filter((inv) => inv?.inv_number === invoiceNumber)
                ?.map(
                  (pax, index) => (

                    (
                      <tr>
                        <td>
                          {pax?.pax_type + "-" + pax?.fName + " " + pax?.lname}
                        </td>
                        <td>{pax?.tkt_number}</td>
                        <td>
                          {booking?.booking?.FlightBookingModels &&
                            booking?.booking?.FlightBookingModels[0]?.pnr}
                        </td>
                        <td>
                          {booking?.booking?.FlightBookingModels[0]?.FlightSegmentModels?.map(
                            (segment, i) => (
                              <>
                                {" "}
                                <span key={i}>
                                  {segment?.origin?.split("-")[0]}
                                </span>
                                <span>
                                  {segment?.destination?.split("-")[0]}{" "}
                                </span>
                              </>
                            )
                          )}
                        </td>

                        <td className="d-flex flex-column gap-2 text-primary">
                          {CurrencyCode() + " " + pax?.ttl_agt_pay_s_cur}

                        </td>
                      </tr>
                    )
                  )
                )}
            <tr>
              <td colSpan="4" style={{ textAlign: "right", fontWeight: 700 }}>
                Grand Total
              </td>
              <td
                colSpan="1"
                style={{ fontWeight: 700 }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {total?.baseSlctC}

              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {/* Agent ,WithOut Discount/PSF */}
      {viewStatement === "WithOut Discount/PSF" ? (
        <table class="table table-bordered border-dark align-items-center ">
          <thead
            className="bg_primary text-white "
            style={{ fontSize: "20px" }}
          >
            <tr>
              <th scope="col" style={{ width: 200 }}>
                Passenger(Type)
              </th>
              <th scope="col" style={{ width: 200 }}>
                Ticket No
              </th>
              <th scope="col" style={{ width: 200 }}>
                PNR
              </th>
              <th scope="col" style={{ width: 200 }}>
                Sector
              </th>

              <th scope="col" style={{ width: 200 }}>
                Fare
              </th>
              <th scope="col" style={{ width: 200 }}>
                Taxes
              </th>
              <th scope="col" style={{ width: 200 }}>
                Total
              </th>
              {/* <th scope="col" style={{ width: 200 }}>
                DIS/PSF
              </th> */}

              <th scope="col" style={{ width: 200 }}>
                Receivable
              </th>
            </tr>
          </thead>
          <tbody
            className="text-dark "
            style={{ fontSize: "20px", color: "#000000" }}
          >
            {booking?.booking?.FlightBookingModels &&
              booking?.booking?.FlightBookingModels[0]?.pax_tables
                ?.filter((inv) => inv?.inv_number === invoiceNumber)
                ?.map((pax, index) => (
                  <tr>
                    <td>
                      {pax?.pax_type + "-" + pax?.fName + " " + pax?.lname}
                    </td>
                    <td>{pax?.tkt_number}</td>
                    <td>
                      {booking?.booking?.FlightBookingModels &&
                        booking?.booking?.FlightBookingModels[0]?.pnr}
                    </td>
                    <td>
                      {booking?.booking?.FlightBookingModels[0]?.FlightSegmentModels?.map(
                        (segment, i) => (
                          <>
                            {" "}
                            <span key={i}>
                              {segment?.origin?.split("-")[0]}
                            </span>
                            <span>{segment?.destination?.split("-")[0]} </span>
                          </>
                        )
                      )}
                    </td>

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <span className="text-primary">
                        {" "}
                        {CurrencyCode()} {pax?.baseFare_s_cur}
                      </span>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Number(pax?.ttl_tax_s_cur) +
                            Number(pax?.agt_gst_s_cur) +
                            Number(pax?.agt_wht_s_cur) +
                            Number(pax?.agt_psf_s_cur) -
                            Number(pax?.agt_sp_s_cur)
                          )}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <span className="text-primary">
                          {" "}
                          {CurrencyCode()}{" "}
                          {TwoDecimalPoint(
                            Number(pax?.baseFare_s_cur) +
                            Number(pax?.ttl_tax_s_cur) +
                            Number(pax?.agt_gst_s_cur) +
                            Number(pax?.agt_wht_s_cur) +
                            Number(pax?.agt_psf_s_cur) -
                            Number(pax?.agt_sp_s_cur)
                          )}
                        </span>
                      </div>
                    </td>



                    <td className="d-flex flex-column gap-2 text-primary">
                      {CurrencyCode() + " " + pax?.ttl_agt_pay_s_cur}
                    </td>
                  </tr>
                ))}
            <tr>
              <td colSpan="7" style={{ textAlign: "right", fontWeight: 700 }}>
                Grand Total
              </td>
              <td
                colSpan="1"
                style={{ fontWeight: 700 }}
                className="d-flex flex-column gap-2 text-primary"
              >
                {CurrencyCode()} {total?.baseSlctC}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
};

export default PaxRecord;
