import moment from "moment";

export const ExclusiveSetors = (searchValues, pricing, rbdCode) => {
  const array1 = pricing?.ApiPricingSectorModels[0]?.from?.split(",");
  const array2 = pricing?.ApiPricingSectorModels[0]?.to?.split(",");
  const rbdArray = pricing?.ApiPricingSectorModels[0]?.rbd?.split(",");

  if (pricing) {
    const travelDate = new Date(
      pricing?.ApiPricingSectorModels[0]?.travel_date
    );
    // ?.split("-")?.reverse()?.join()

    // Provide the format of the date string in the second argument
    const departingOn = new Date(
      searchValues?.departingOn?.split("-")?.reverse()?.join()
    );
    const isDateInRange = travelDate.getTime() >= departingOn.getTime();
    if (rbdCode) {
      if (
        array1?.includes(searchValues?.locationDep?.split("-")[0]) &&
        array2?.includes(searchValues?.locationArrival?.split("-")[0]) &&
        isDateInRange &&
        rbdArray?.includes(rbdCode)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        array1?.includes(searchValues?.locationDep?.split("-")[0]) &&
        array2?.includes(searchValues?.locationArrival?.split("-")[0]) &&
        isDateInRange
      ) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

export const codeShareSabre = (flight) => {
  const differentAirlinesFlights = flight?.find(
    (flight) => flight?.carrier?.marketing !== flight?.carrier?.operating
  );
  return differentAirlinesFlights ? true : false;
};

export const codeShareAmadeus = (flights) => {
  const differentAirlinesFlights = flights?.some(
    (flight) => flight?.carrierCode !== flight.operating?.carrierCode
  );
  return differentAirlinesFlights;
};



export const codeShareHITIT = (flight) => {

  const toArray = Array.isArray(flight) ? flight : [flight];

  const isCodeShare = toArray?.some((item) => {
    return (
      item?.flightSegment?.operatingAirline &&
      item?.flightSegment?.airline?.code !== item?.flightSegment?.operatingAirline?.code
    );
  });
  return isCodeShare;
};
