import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  asyncCreateFlightGroupTicketIssue,
  asyncGenerateOtpcode,
  asyncPostBookingId,
  getAsyncBookingDetails,
  setOtmMSGNull,
  setOtpNull,
} from "../../redux/features/booking/bookingDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../AppForm/BtnLoader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const GenerateOtp = ({
  id,
  setOtp,
  setActive_Tab,
  paxData,
  auto_manual,
  booking,
  state,
}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [delay, setDelay] = useState(false);
  const [regeneragte, setRegenrate] = useState(true);
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(120);
  const { isLoading, otpMSG, otpstatus } = useSelector(
    (state) => state?.booking
  );
  const check =
    auto_manual === 1 ? auto_manual === 1 : booking?.at(0)?.gds === "SERENE";

  const confirmOtpCode = () => {
    if (code === "") {
      setMessage("Please Enter Your OTP");
    } else {
      if (state === "Manual") {
        setDelay(true);
        setTimeout(() => {
          const otpbody = {
            book_main: id,
            code: code,
            paxData: paxData?.paxData,
          };
          dispatch(asyncCreateFlightGroupTicketIssue({ ...otpbody }))
            .unwrap()
            .then((response) => {
              dispatch(getAsyncBookingDetails({ id }));
              dispatch(setOtmMSGNull());
              setRegenrate(false);
              setDelay(false);
              setActive_Tab("Details")
            })
            .catch((err) => {
              dispatch(setOtmMSGNull());
              // setRegenrate(false);
              setDelay(false);
              setGetError(err);
              // setOtp("");
            });

        }, 3000);
      } else {
        setDelay(true);

        setTimeout(() => {
          const otpbody = { book_main: id, code: code };
          dispatch(asyncPostBookingId({ ...otpbody }))
            .unwrap()
            .then((response) => {
              dispatch(getAsyncBookingDetails({ id }));
              dispatch(setOtmMSGNull());
              setRegenrate(false);
              setDelay(false);
              setActive_Tab("Details")
            })
            .catch((err) => {
              dispatch(setOtmMSGNull());
              // setRegenrate(false);
              setDelay(false);
              setGetError(err);
              // setOtp("");
            });
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (regeneragte && timer >= 1 && otpMSG === null) {
      setTimeout(() => {
        setTimer((prv) => {
          return prv - 1;
        });
      }, 1000);
    } else if (otpMSG) {
      setTimer(120);
    } else {
      // dispatch(setOtpNull());
      setRegenrate(false);
    }
  }, [dispatch, timer, otpMSG]);

  const generateOtp = () => {
    dispatch(asyncGenerateOtpcode(id));
    setTimer(120);
    setRegenrate(true);
  };
  const [getError, setGetError] = useState();
  return (
    <div>
      <div className="col-12 col-sm-6 mx-auto rounded-2 shadow-sm my-5 pb-5 bg-white">
        <p className="otawix-heading box_title">Generate OTP</p>

        {!regeneragte ? (
          <div className="text-center pt-4">
            <button className="setup-btn" onClick={generateOtp}>
              {isLoading ? <BtnLoader /> : "Regenerate OTP"}
            </button>
          </div>
        ) : (
          <div className="col-12 px-4">
            <div className="col-6 mt-4 py-3 position-relative">
              <FormControl variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Enter Your OTP
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "number" : "password"}
                  autoComplete="off"
                  name="code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setMessage("");
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {message ? (
                <div className="text-danger mt-1 ">{message}</div>
              ) : (
                " "
              )}
              <div className=" mt-2">
                {otpMSG ? (
                  <div className="text-success">{otpMSG?.message}</div>
                ) : (
                  <>
                    <div className="text-success">{otpstatus?.message}</div>
                    <div className="pt-2">OTP will expire in {timer} Sec</div>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex flex-column gap-4 align-items-center">
              <div className="text-center">
                <button
                  className="setup-btn"
                  onClick={(e) => {
                    confirmOtpCode();
                  }}
                >
                  {delay ? <BtnLoader /> : "Confirm"}
                </button>
              </div>
              <div className="text-danger">{getError?.message}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateOtp;
