// src/redux/features/updateSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { NODEURL, URL2 } from '../ApiConfig'; // Update the path according to your file structure

// Async thunk to fetch booking update data
export const fetchBookingUpdate = createAsyncThunk(
  'updateSegments/fetchBookingUpdate', 
  async ({ gds, id, type }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/flights/update/${id}`, {
        gds,
        id,
        type
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const updateSlice = createSlice({
  name: 'updateSegments',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookingUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchBookingUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default updateSlice.reducer;
