import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAccountData,
  asyncGetMainAccountData,
  asyncGetSubAccountData,
  handleEmptysubAcountData,
} from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import BtnLoader from "../../../AppForm/BtnLoader";
import { AsyncCreatVoucherEntries } from "../../../../redux/features/Voucher/VoucherSlice";
import { useNavigate } from "react-router-dom";

function OpeningBalance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { mainAcountData, subAcount } = useSelector(
    (state) => state.chartAccount
  );
  useEffect(() => {
    dispatch(asyncGetMainAccountData());
  }, []);
  // Check if the login user has permission to opening balance
  const opBalancePermission =
    user?.user_id !== 1
      ? userPermission?.find(
          (it) =>
            it?.user_id === user?.user_id &&
            it?.page_level.permission_url === "/op-balance"
        )
      : true;
  const [accoutData, setAccountData] = useState({
    id: 0,
    title: "",
  });
  const accountArray = [
    {
      id: 1,
      title: "Assets",
    },
    { id: 2, title: "Liabilities" },
    { id: 3, title: "Capital" },
    { id: 4, title: "Income" },
    { id: 5, title: "Expances" },
  ];
  const getSubsidiaryItems = (items) => {
    return items?.reduce((acc, item) => {
      if (item.acc_nature === "SUBSIDIARY") {
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        const subsidiaryChildren = getSubsidiaryItems(item.children);
        if (subsidiaryChildren.length > 0) {
          acc.push(...subsidiaryChildren); // Only include subsidiary children
        }
      }
      return acc;
    }, []);
  };

  // Initialize state with index as keys and objects containing debit and credit fields
  const [inputValues, setInputValues] = useState(
    getSubsidiaryItems(subAcount)?.map((fl, index) => ({
      debit: "",
      credit: "",
      cur: "",
      roe: "",
    }))
  );

  // Handle input change for debit and credit fields
  const handleInputChange = (e, index, field) => {
    const updatedValues = [...inputValues];
    updatedValues[index] = {
      ...updatedValues[index],
      [field]: e.target.value,
    };
    setInputValues(updatedValues);
  };
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(true);
  // Handle form submission
  const handleSubmit = () => {
    const result = getSubsidiaryItems(subAcount)
      ?.map((item, index) => ({
        cur: item?.agents_comp?.length
          ? item?.agents_comp?.at(0)?.agent_group?.currency?.curr_code
          : "PKR",
        roe: item?.agents_comp?.length
          ? item?.agents_comp?.at(0)?.agent_group?.currency?.roe
          : "1.00",
        accountId: item.acc_id,
        d_cur_debit: Number(inputValues[index]?.debit) || 0,
        d_cur_credit: Number(inputValues[index]?.credit) || 0,
        s_cur_debit: 0,
        s_cur_credit: 0,
        transctionDescription: "Opening Balance",
      }))
      .filter((fltr) => fltr.d_cur_debit || fltr.d_cur_credit);
    let totals;
    if (result) {
      totals = result.reduce(
        (acc, item) => {
          acc.ttlDebitDefultCur += item.d_cur_debit;
          acc.ttlCreditDefultCur += item.d_cur_credit;
          return acc;
        },
        { ttlDebitDefultCur: 0, ttlCreditDefultCur: 0 }
      );
    }
    const total = totals.ttlDebitDefultCur - totals.ttlCreditDefultCur;

    const object = {
      cur: "PKR",
      roe: "1.0",
      accountId: 45,
      d_cur_debit: total < 0 ? Math.abs(total) : 0,
      d_cur_credit: total > 0 ? Math.abs(total) : 0,
      s_cur_debit: 0,
      s_cur_credit: 0,
      transctionDescription: "Opening Balance",
    };
    const finalResult = total == 0 ? result : [...result, object];
    setMessage(false);
    const formData = new FormData();
    if (totals) {
      formData.append("voucherType", 1);
      formData.append("voucherDescription", "Opening Balance");
      formData.append("Accrued", "Accrued");
      formData.append("savedDate", new Date());
      formData.append("transactionDetails", JSON.stringify(finalResult));
    }

    setTimeout(() => {
      dispatch(AsyncCreatVoucherEntries({ body: formData }));
      navigate("/save-voucher");
      setMessage(true);
    }, 3000);
  };
  return (
    <div className="Full_height">
      <div className="manual_invoices  mx-auto">
        <div className="manual_invoices_header">
          <div>Opening Balance</div>
        </div>
        <div className="pt-5 px-2 ">
          <FormControl variant="standard" className="w-100" sx={{}}>
            <InputLabel id="demo-simple-select-standard-label">
              Select Account
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={accoutData?.id || ""} // Use id as the value
              onChange={(e) => {
                const selectedId = e.target.value; // Get selected id
                const selectedItem = accountArray.find(
                  (item) => item.id === selectedId
                ); // Find the object
                setAccountData((prev) => ({
                  ...prev,
                  id: selectedItem?.id || "", // Update id
                  title: selectedItem?.title || "", // Update title
                }));
                setInputValues(
                  getSubsidiaryItems(subAcount)?.map(() => ({
                    debit: "",
                    credit: "",
                    cur: "",
                    roe: "",
                  }))
                );
              }}
              label="Select status"
            >
              {accountArray?.map((list) => (
                <MenuItem
                  value={list.id} // Pass id as the value
                  sx={{ m: 1, bgcolor: "#fff" }}
                  key={list?.id}
                  onClick={(e) => {
                    dispatch(
                      asyncGetSubAccountData({
                        data: list?.id,
                      })
                    );
                  }}
                >
                  {list?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="row py-4 px-2 px-md-4 align-items-end">
          {getSubsidiaryItems(subAcount)?.map((item, index) => (
            <div key={index} className="fs-6 d-flex  align-items-center ">
              <div style={{ width: "40%" }}>
                <span>
                  {item.acc_id} {item.acc_title}
                </span>
              </div>

              <TextField
                id="standard-basic"
                label="Debit"
                variant="standard"
                type="number"
                value={inputValues[index]?.debit}
                onChange={(e) => handleInputChange(e, index, "debit")}
              />
              <TextField
                style={{ marginLeft: "10px" }}
                id="filled-number"
                label="Credit"
                type="number"
                variant="standard"
                value={inputValues[index]?.credit}
                onChange={(e) => handleInputChange(e, index, "credit")}
              />
            </div>
          ))}
        </div>
        {message ? (
          <div className="text-center py-4">
            <button
              className="button_style px-4"
              type="submit"
              onClick={handleSubmit}
              disabled={
                !opBalancePermission}
            >
              Submit
            </button>
            {showMessage ? (
              <p className="text-danger text-center ">
                Debit And Credit Both Side Must Be Equal
              </p>
            ) : null}
          </div>
        ) : (
          <div className="text-center py-4">
            <button className="button_style px-4">
              <BtnLoader />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default OpeningBalance;
