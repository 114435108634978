import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../DeleteModal";
import {
  asyncEditAgentsData,
  asyncGetAgentData,
  asyncGetAgentGroup,
} from "../../redux/features/setupRestPages/agentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../../Utils/ColumnOptions";
import {
  asyncDeletePendingAgency,
  asyncGetNewAgencyReg,
} from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { ChangeStatus } from "../../Utils/ChangeStatus";
import { Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { asyncGetAllUserData } from "../../redux/features/agency/usersSlice";

const Agents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /***********************Use selector *********************/
  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  const { allUsersData } = useSelector((state) => state?.usersSlice);
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  const { message } = useSelector((state) => state.notification);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  /**************  Use state ******************/
  const [tabs, setTabs] = useState("active");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowData, setrowData] = useState();

  /***********Use Effect *******************/
  useEffect(() => {
    dispatch(asyncGetAgentData());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetAllUserData());
  }, [dispatch]);

  let activeAgentComp = newAgencyRegData?.filter(
    (it) => it?.status === 1 && it?.agents_users[0]?.role_id !== 1
  );

  let nonActiveAgentComp = newAgencyRegData?.filter(
    (it) => it?.status === 0 && it?.agents_users[0]?.role_id !== 1
  );
  let pendingAgentComp = newAgencyRegData?.filter(
    (it) => it?.status === 3 && it?.agents_users[0]?.role_id !== 1
  );

  //if the user has permission to update status
  const updateStatus = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/agency-status"
    )
    : true;
  // if the login user has permissions to edit the agent
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/update-new-agency"
    )
    : true;

  // if the user has permission to delete the pricing
  const deletePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/reject-pending-agency"
    )
    : true;
  // if the user has permission to edit pending agents
  const editPendingAgency = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
      (it) =>
        it?.user_id === user?.user_id &&
        it?.page_level.permission_url === "/reject-pending-agency"
    )
    : true;

  /**************************** Active Column **************/
  const active_columns = [
    {
      name: "agent_id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <>
              {updateStatus !== undefined || user?.user_id === 1 ? (
                <div className="checkbox-rect">
                  <input
                    value={value}
                    checked={value}
                    type="checkbox"
                    id={"with_sar_" + id}
                    onChange={(event) => {
                      updateValue(!value);
                      ChangeStatus(
                        "/api/v1/b2b/new-agency-status",
                        !value,
                        id,
                        "status",
                        dispatch
                      );

                      setTimeout(() => {
                        dispatch(asyncGetNewAgencyReg());
                      }, "500");
                    }}
                  />
                  <label htmlFor={"with_sar_" + id} className="h6"></label>
                </div>
              ) : null}
            </>
          );
        },
      },
    },
    {
      name: "agent_grp_id",
      label: "Agent Group",
      options: {
        customBodyRender: (value) => {
          let agentName = agentGroup?.find((it) => it.agent_grp_id === value);
          return <div>{agentName?.agent_grp_name}</div>;
        },
      },
    },
    {
      name: "agent_id",
      label: "Agent ID",
      options: {
        customBodyRender: (value) => {
          return <div>{`TA- ${value}`}</div>;
        },
      },
    },
    {
      name: "agent_type",
      label: "Agent Type",
    },
    {
      name: "agent_name",
      label: "Agent Name",
    },
    {
      name: "manager",
      label: "Agent Manager",
      options: {
        customBodyRender: (value, tableMeta) => {
          let office_manager = allUsersData?.find(
            (it) => it?.user_id === value
          );
          return (
            <div>
              {office_manager?.f_name} {office_manager?.l_name}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) => {
          const loc = value.split("]").join(" ")
          return <div>{loc}</div>;
        },
      },
    },
    {
      name: "email",
      label: "Agent Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "mobile_no",
      label: "Mobile",
    },
    {
      name: "credit_limit",
      label: "Credit Limit",
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={(e) => {
                  navigate("/edit-agency/" + id);
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
            </>
          );
        },
      },
    },
  ];
  /************************** Inactive column ***************/
  const in_active_columns = [
    {
      name: "agent_id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <>
              {updateStatus !== undefined || user?.user_id === 1 ? (
                <div class="checkbox-rect">
                  <input
                    value={value}
                    checked={value}
                    type="checkbox"
                    id={"with_sar_" + id}
                    onChange={(event) => {
                      updateValue(!value);
                      ChangeStatus(
                        "/api/v1/b2b/new-agency-status",
                        !value,
                        id,
                        "status"
                      );
                      setTimeout(() => {
                        dispatch(asyncGetNewAgencyReg());
                      }, "500");
                    }}
                  />
                  <label htmlFor={"with_sar_" + id} className="h6"></label>
                </div>
              ) : null}
            </>
          );
        },
      },
    },
    {
      name: "add_by",
      label: "Agent Group",
      options: {
        customBodyRender: (value) => {
          let agentName = agentGroup?.find((it) => it.agent_grp_id === value);
          return <div>{agentName?.agent_grp_name}</div>;
        },
      },
    },
    {
      name: "agent_id",
      label: "Agent ID",
    },
    {
      name: "agent_type",
      label: "Agent Type",
    },
    {
      name: "agent_name",
      label: "Agent Name",
    },
    {
      name: "manager",
      label: "Agent Manager",
      options: {
        customBodyRender: (value, tableMeta) => {
          let office_manager = allUsersData?.find(
            (it) => it?.user_id === value
          );
          return (
            <div>
              {office_manager?.f_name} {office_manager?.l_name}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) => {
          const loc = value.split("]").join(" ")
          return <div>{loc}</div>;
        },
      },
    },
    {
      name: "email",
      label: "Agent Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "mobile_no",
      label: "Mobile",
    },
    {
      name: "credit_limit",
      label: "Credit Limit",
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              {editPermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="btn-success rounded edit-delete-icon-button me-1"
                  onClick={(e) => navigate("/edit-agency/" + id)}
                >
                  <i className="fa fa-thin fa-pencil"></i>
                </button>
              ) : null}
            </>
          );
        },
      },
    },
  ];
  /******************* PENDING COLUMN ********************** */
  const pending_columns = [
    {
      name: "agent_id",
      label: "id",
      options: {
        display: false,
      },
    },

    {
      name: "agent_id",
      label: "Agent ID",
    },

    {
      name: "agent_name",
      label: "Agent Name",
    },

    {
      name: "address",
      label: "Location",
      options: {
        customBodyRender: (value) => {
          let loc = value?.replace(/]/g, "/");
          return <div>{loc}</div>;
        },
      },
    },
    {
      name: "email",
      label: "Agent Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "mobile_no",
      label: "Mobile",
    },
    {
      name: "credit_limit",
      label: "Credit Limit",
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              {(editPendingAgency !== undefined || user?.user_id === 1) && (
                <button
                  className="btn-success rounded edit-delete-icon-button me-1"
                  onClick={(e) => navigate("/edit-agency/" + id)}
                >
                  <i className="fa fa-thin fa-pencil"></i>
                </button>
              )}
              {(deletePermission !== undefined || user?.user_id === 1) && (
                <button
                  className="btn-danger rounded edit-delete-icon-button me-1"
                  onClick={(e) => {
                    setShowDeleteModal(true);
                    setrowData(tableMeta.rowData);
                  }}
                >
                  <i className="fa fa-thin fa-trash"></i>
                </button>
              )}
            </>
          );
        },
      },
    },
  ];

  return (
    <div className="pb-4  ">
      <div className="col-11 mx-auto rounded-2 shadow-sm my-5 pb-4 bg-white">
        <div className="agents-container">
          <div className="agents-navigation-tabs">
            <Badge
              badgeContent={activeAgentComp?.length || "0"}
              color="success"
              max={9999}
            >
              <div
                className={`h5  ${tabs === "active" ? "active-link" : "agents_details_links"
                  }`}
                onClick={() => setTabs("active")}
              >
                Active
              </div>
            </Badge>
            <Badge
              badgeContent={nonActiveAgentComp?.length || "0"}
              color="error"
              max={9999}
            >
              <div
                className={`h5  ${tabs === "inactive" ? "active-link" : "agents_details_links"
                  }`}
                onClick={() => setTabs("inactive")}
              >
                InActive
              </div>
            </Badge>
            <Badge badgeContent={pendingAgentComp?.length || "0"} color="info" max={9999}>
              <div
                className={`h5  ${tabs === "pending" ? "active-link" : "agents_details_links"
                  }`}
                onClick={() => setTabs("pending")}
              >
                Pending
              </div>
            </Badge>
          </div>
          <p className="text-center my-3 confirm_msg">
            {message ? "Agent has been InActive" : ""}
          </p>
          {/* ******************* Delete Model ************ */}
          <DeleteModal
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            onClick={(e) => {
              dispatch(asyncDeletePendingAgency(rowData[0]));
              setShowDeleteModal(false);
            }}
          />

          {/*************   Tables *****************/}
          {tabs === "active" && (
            <div className="col-12 mt-2">
              <MUIDataTable
                className="muidatatable"
                title={"Active Agents Details"}
                data={activeAgentComp}
                columns={active_columns}
                options={options}
              />
            </div>
          )}
          {tabs === "inactive" && (
            <div className="col-12 mt-2">
              <MUIDataTable
                className="muidatatable"
                title={"InActive Agents Details"}
                data={nonActiveAgentComp}
                columns={in_active_columns}
                options={options}
              />
            </div>
          )}
          {tabs === "pending" && (
            <div className="col-12 mt-2">
              <MUIDataTable
                className="muidatatable"
                title={"Pending Agents Details"}
                data={pendingAgentComp}
                columns={pending_columns}
                options={options}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Agents;
