const getAmount = (type, pricing, isEconomy, isCodeShare) => {
  let amount = 0;
  if (isCodeShare) {
    amount = isEconomy ? pricing?.[`codeshare_com_${type}_eco`] : pricing?.[`codeshare_com_${type}_b_f`]
  } else {
    amount = isEconomy ? pricing?.[`com_${type}_eco`] : pricing?.[`com_${type}_b_f`]
  }
  return amount;
}
const getAmountOption = (type, pricing, isEconomy, isCodeShare) => {
  let amount = 0;
  if (isCodeShare) {
    amount = isEconomy ? pricing?.[`codeshare_com_${type}_option_eco`] : pricing?.[`codeshare_com_${type}_option_b_f`]
  } else {
    amount = isEconomy ? pricing?.[`com_${type}_option_eco`] : pricing?.[`com_${type}_option_b_f`]
  }
  return amount;
}

export const pricingairline = (
  airline,
  pricing,
  BasePrice,
  Taxes,
  sector,
  isExclusive,
  isCodeShare,
  isEconomy = true,
  segmentsCount = 1,
  isNotInfant = true,
) => {
  const exclusivePrice = pricing?.ApiPricingSectorModels?.at(0);
  const initAmount = {
    gst: pricing?.gst || 0,
    wht: pricing?.wht || 0,
    dom: isExclusive ? exclusivePrice?.price : getAmount("dom", pricing, isEconomy),
    siti: isExclusive ? exclusivePrice?.price : getAmount("intl", pricing, isEconomy, isCodeShare && pricing?.code_share_intl),
    soto: isExclusive ? exclusivePrice?.price : getAmount("soto", pricing, isEconomy, isCodeShare && pricing?.code_share_soto),


    dom_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("dom", pricing, isEconomy),
    siti_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("intl", pricing, isEconomy, isCodeShare && pricing?.code_share_intl),
    soto_option: isExclusive ? exclusivePrice?.price_option : getAmountOption("soto", pricing, isEconomy, isCodeShare && pricing?.code_share_soto),

    dom_percent: isExclusive ? true : pricing?.com_dom_type ? false : true,
    siti_percent: isExclusive ? true : pricing?.com_intl_type ? false : true,
    soto_percent: isExclusive ? true : pricing?.com_soto_type ? false : true,

    is_segment_wise: pricing?.is_segment_wise,
    subsidary_amount: isNotInfant ? pricing?.subsidary_amount || 0 : 0,
    subsidary_option: isNotInfant ? pricing?.subsidary_option : "NET NET",
  };
  let total_fare = 0;
  let supplier_total_fare = 0;
  let sp = 0;
  let psf = 0;
  let income = 0;
  let income_type = "Net";
  let other_income = isExclusive ? 0 : initAmount?.is_segment_wise ? Number(initAmount?.subsidary_amount) * segmentsCount : Number(initAmount?.subsidary_amount);
  let other_income_type = isExclusive ? "Net Net" :  initAmount?.subsidary_option;
  let discount_given = 0;
  let agent_wht = 0;
  let agent_gst = 0;
  let agt_wht_per_d_s_cur = 0;
  
  let sup_sp_per_d_s_cur = (sector === "siti" ? airline?.com_intl : airline?.[`com_${sector}`]) || 0; 
  let airline_disc = ((Number(BasePrice) / 100) * Number(sup_sp_per_d_s_cur));
  let sup_wht_per_d_s_cur = Number(airline?.wht) || 0;
  let airline_wht = airline_disc / 100 * sup_wht_per_d_s_cur;
  
  let sup_psf_per_d_s_cur = 0;
  let sup_psf_d_cur = 0;
  let sup_gst_per_d_s_cur = 0;
  let sup_gst_d_cur = 0;

  let agt_psf_per_d_s_cur = 0;
  let agt_sp_per_d_s_cur = 0;
  let agt_gst_per_d_s_cur = 0;

  BasePrice = Number(BasePrice)
  Taxes = Number(Taxes)

  if (pricing) {
    let isPercent = initAmount[`${sector}_percent`];
    let option = initAmount[`${sector}_option`];
    let amount = isPercent ? (BasePrice * Number(initAmount[sector])) / 100 : Number(initAmount[sector]);
    if(option === 'PSF') {
      agt_psf_per_d_s_cur = isPercent ? initAmount[sector] : 0;
      psf = amount;
      agent_gst = amount / 100 * Number(initAmount?.gst);
      discount_given = amount - (other_income_type === "Discount" ? other_income : 0) + agent_gst;
      agt_gst_per_d_s_cur = initAmount?.gst;
    } else if(option === 'Discount') {
      agt_sp_per_d_s_cur = isPercent ? initAmount[sector] : 0;
      sp = amount;
      agent_wht = amount / 100 * Number(initAmount?.wht);
      discount_given = amount + (other_income_type === "Discount" ? other_income : 0) - agent_wht;
      agt_wht_per_d_s_cur = initAmount?.wht;
    } else {
      discount_given = (other_income_type === "Discount" ? other_income : 0)
    }
    
    discount_given = Math.abs(discount_given)
    supplier_total_fare = BasePrice + Taxes - airline_disc + airline_wht;
    total_fare = BasePrice + Taxes - sp + agent_wht + psf + agent_gst;
    total_fare = total_fare + (other_income_type === "Discount" ? (-other_income) : (other_income))
    income = total_fare - supplier_total_fare;
    income_type = income > 0 ? 'PSF' : income === 0 ? 'Net' : 'Disc-given'


    return {
      BasePrice: BasePrice,
      Taxes: Taxes,
      total_fare,
      supplier_total_fare,
      income_type,
      income: Math.abs(income),
      discount_given,
      airline_disc: airline_disc,
      airline_wht: airline_wht,
      agent_wht: agent_wht,
      other_income: other_income,
      other_income_type: other_income_type === "Net Net" ? "Net" : other_income_type === "PSF" ? "income" : "Disc-given",
      sp,
      psf,
      agent_gst: agent_gst,
      initAmount: initAmount,
      pricing: pricing,
      agt_wht_per_d_s_cur: agt_wht_per_d_s_cur,
      sup_psf_per_d_s_cur: sup_psf_per_d_s_cur,
      sup_psf_d_cur: sup_psf_d_cur,
      sup_gst_per_d_s_cur: sup_gst_per_d_s_cur,
      sup_gst_d_cur: sup_gst_d_cur,
      sup_sp_per_d_s_cur: sup_sp_per_d_s_cur,
      sup_wht_per_d_s_cur: sup_wht_per_d_s_cur,
      agt_psf_per_d_s_cur: agt_psf_per_d_s_cur,
      agt_sp_per_d_s_cur: agt_sp_per_d_s_cur,
      agt_gst_per_d_s_cur: agt_gst_per_d_s_cur,
    };
  } else {
    const total = BasePrice + Taxes + (other_income_type === "Discount" ? -(other_income) : other_income);
    const supplier_total = BasePrice + Taxes;
    const totalIncome = supplier_total - total
    let incomeType = "";
    if (totalIncome < 0) {
      incomeType = "disc_given";
    } else if (totalIncome > 0) {
      incomeType = "PSF";
    } else {
      incomeType = "";
    }
    return {
      BasePrice: BasePrice,
      Taxes: Taxes,
      total_fare: total,
      supplier_total_fare: supplier_total,
      income: Math.abs(totalIncome),
      income_type: incomeType,
      discount_given: Number(discount_given) + (other_income_type === "Discount" ? other_income : 0),
      airline_disc: airline_disc,
      airline_wht: airline_wht,
      agent_wht: agent_wht,
      other_income: other_income,
      other_income_type: other_income_type,
      sp: incomeType === "disc_given" ? Math.abs(totalIncome) : 0,
      psf: psf,
      agent_gst: agent_gst,
      initAmount: initAmount,
      pricing: pricing,
      agt_wht_per_d_s_cur: agt_wht_per_d_s_cur,
      sup_psf_per_d_s_cur: sup_psf_per_d_s_cur,
      sup_psf_d_cur: sup_psf_d_cur,
      sup_gst_per_d_s_cur: sup_gst_per_d_s_cur,
      sup_gst_d_cur: sup_gst_d_cur,
      sup_sp_per_d_s_cur: sup_sp_per_d_s_cur,
      sup_wht_per_d_s_cur: sup_wht_per_d_s_cur,
      agt_psf_per_d_s_cur: agt_psf_per_d_s_cur,
      agt_sp_per_d_s_cur: agt_sp_per_d_s_cur,
      agt_gst_per_d_s_cur: agt_gst_per_d_s_cur,
    };
  }
};
