import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  ClickAwayListener,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import { FaSortDown } from "react-icons/fa";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetSubAccountData } from "../redux/features/Finance/Account/ChartOfAccountSlice";

function CustomDropdown({
  placehlder,
  setAgent,
  toggle,
  setToggle,
  index,
  item,
  account,
  setAccount,
  search,
  setSearch,
  accId,
  setAccid,
}) {
  const { mainAcountData, subAcount } = useSelector(
    (state) => state.chartAccount
  );

  const dispatch = useDispatch();
  const [searchTitle, setSearchTitle] = useState();
  const handleAccountSelection = (selectedTitle) => {
    const updatedAccounts = [...account];
    updatedAccounts[index] = { title: selectedTitle };
    setAccount(updatedAccounts);
  };
  const RecursiveList = ({ items, margin = 0 }) => (
    <ul>
      {items.map((item, ind) => (
        <li
          key={index}
          style={{
            marginLeft: `${margin}ch`,
            marginTop: 10,
            cursor: item.acc_nature === "SUBSIDIARY" ? "pointer" : "default",
          }}
          className="fs-5"
          onClick={(e) => {
            e.stopPropagation();
            if (item.acc_nature === "SUBSIDIARY") {
              setAgent(item?.acc_title);
              handleAccountSelection(item?.acc_title);
              setAccid(item?.acc_id);
              setToggle({
                ...toggle,
                ["open_" + index]: !toggle?.["open_" + index],
              });
            }
          }}
          onMouseEnter={(e) => {
            if (item.acc_nature === "SUBSIDIARY") {
              e.currentTarget.style.backgroundColor = "gray";
              e.currentTarget.style.color = "white";
            }
          }}
          onMouseLeave={(e) => {
            if (item.acc_nature === "SUBSIDIARY") {
              e.currentTarget.style.backgroundColor = "";
              e.currentTarget.style.color = "black";
            }
          }}
        >
          {item.acc_title}
          {item.children && item.children.length > 0 && (
            <RecursiveList items={item.children} margin={margin + 1} />
          )}
        </li>
      ))}
    </ul>
  );
  const recursiveFilter = (data, searchTitle) => {
    return data
      .map((item) => {
        const lowerTitle = item.acc_title?.toLowerCase();
        const lowerSearch = searchTitle?.toLowerCase();

        // Check if current item matches
        const isMatch = lowerTitle.includes(lowerSearch);

        // Recursively filter children if they exist
        const filteredChildren = item.children
          ? recursiveFilter(item.children, searchTitle)
          : [];

        // Include the item if it's a match or if any children are matches
        if (isMatch || filteredChildren.length > 0) {
          return {
            ...item,
            children: filteredChildren,
          };
        }

        return null; // Exclude item if no match
      })
      .filter((item) => item !== null); // Remove null values
  };
  const obj = {
    0: mainAcountData,
    1: searchTitle ? recursiveFilter(subAcount, searchTitle) : subAcount,
  };
 

  return (
    <Box>
      {/* ist two input filed */}
      <FormControl variant="standard" sx={{ mt: 3, width: "50ch" }}>
        <InputLabel
          htmlFor="standard-adornment-account"
          sx={{
            transform:
              account?.[index]?.title && "translate(0, -6px) scale(0.75)",
          }}
        >
          {placehlder}
        </InputLabel>

        <Input
          id="standard-adornment-weight"
          endAdornment={
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          }
          aria-describedby="standard-weight-helper-text"
          type="text"
          value={account?.[index]?.title}
          onChange={(e) => {
            const inputData = e.target.value;
            const updatedAccounts = [...account];
            updatedAccounts[index] = { title: inputData?.toUpperCase() };
            setAccount(updatedAccounts);
            if (index !== 0) {
              setSearchTitle(inputData);
            }
            // You can use inputData as needed
          }}
          onClick={(e) => {
            if (index === 0) {
              setAccount((prevAccount) => {
                const updatedAccounts = [...prevAccount];
                if (updatedAccounts[1]) {
                  updatedAccounts[1].title = "";
                }
                return updatedAccounts;
              });
            }
            setToggle({
              ...toggle,
              ["open_" + index]: !toggle?.["open_" + index],
            });
          }}
        />
      </FormControl>
      {toggle["open_" + index] && (
        <ClickAwayListener
          onClickAway={(e) => {
            setToggle({
              ...toggle,
              ["open_" + index]: !toggle?.["open_" + index],
            });
          }}
        >
          <Box
            sx={{
              minWidth: "50ch",
              maxWidth: "auto",
              position: "absolute",
              background: "white",
              zIndex: 1,
              boxShadow: 1,
              p: 2,
              maxHeight: "50ch",
              overflow: "auto",
              height: "auto",
            }}
          >
            {obj?.[index]?.map((item, i) =>
              index === 0 ? (
                // parent account
                <Typography
                  sx={{
                    py: 1,
                    cursor: "pointer",
                    "&:hover": {
                      background: "#f0f0f0", // Adjust the background color on hover
                    },
                    opacity: item?.acc_title === "Subsidiary" ? 0.5 : 1,
                    pointerEvents:
                      item?.acc_title === "Subsidiary" ? "none" : "auto",
                  }}
                  onClick={(e) => {
                    handleAccountSelection(item?.acc_title);

                    setAccid(item?.acc_id);
                    setToggle({
                      ...toggle,
                      ["open_" + index]: !toggle?.["open_" + index],
                    });
                    dispatch(asyncGetSubAccountData({ data: item?.acc_id }));
                  }}
                >
                  {item?.acc_title}
                </Typography>
              ) : (
                <li key={i} className="fs-5">
                  {item.acc_title}
                  {item.children && item.children.length > 0 && (
                    <RecursiveList items={item.children} />
                  )}
                </li>
              )
            )}
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default CustomDropdown;
