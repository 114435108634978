import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, FieldArray } from "formik";
import ReactDatePicker from "react-datepicker";
import { dropMenuProp } from "../../../../../Utils/MenuProps";
import { pax_type } from "../helper";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { useSelector } from "react-redux";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const PaxDetail = ({ values, setFieldValue, errors, touched }) => {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const isPkr = values.paxDetail?.at(0).cur_label === "PKR" ? true : false;
  const Objdata = {
    ADULT: [{ pax_title: "MR" }, { pax_title: "MS" }, { pax_title: "MRS" }],
    CHILD: [{ pax_title: "MSTR" }, { pax_title: "MISS" }],
    INFANT: [{ pax_title: "INFT" }],
  };
  return (
    <FieldArray
      name="paxDetail"
      render={(arrayHelpers) => (
        <div>
          {values?.paxDetail?.map(
            (visa, index) => (
              console.log("visa", visa),
              (
                <div
                  className="black_border rounded p-3 mb-2 overflow-hidden"
                  key={index}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="h4 d-flex gap-4 align-items-center justify-content-center">
                      <p className="pt-4">Pax {index + 1}</p>
                      <p>
                        <TextField
                          id="visa_number"
                          label="Document No| PNR"
                          variant="standard"
                          className="w-100"
                          value={visa.visa_number}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(
                              `paxDetail.${index}.visa_number`,
                              value
                            );
                          }}
                        />
                      </p>
                      {errors.paxDetail &&
                        errors?.paxDetail[index] &&
                        errors.paxDetail[index].visa_number &&
                        touched.paxDetail &&
                        touched.paxDetail[index] &&
                        touched.paxDetail[index].dob && (
                          <p
                            className="text-danger  position-absolute"
                            style={{
                              marginTop: "80px",
                              fontSize: "14px",
                              marginLeft: "60px",
                            }}
                          >
                            {errors.paxDetail[index].visa_number}
                          </p>
                        )}
                    </div>

                    <div>
                      {index >= 1 && (
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="button_style bg-danger"
                        >
                          - PAX
                        </button>
                      )}
                      {index === 0 && (
                        <button
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              pax_type: visa?.pax_type,
                              pax_title: visa?.pax_title,
                              f_name: "",
                              l_name: "",
                              dob: "",
                              nationality: "",
                              passport_no: "",
                              currency: visa.currency,
                              cur_label: visa.cur_label,
                              roe: visa.roe,
                              curr_pay: visa?.curr_pay,
                              curr_rec: visa?.curr_rec,
                              pkr_pay: visa?.pkr_pay,
                              pkr_rec: visa?.pkr_rec,
                              Supplier_cod: visa?.Supplier_cod,
                              Supplier_roe: visa?.Supplier_roe,
                            })
                          }
                          className="button_style"
                        >
                          + PAX
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="row align-items-end">
                      <div className="" style={{ width: "120px" }}>
                        <TextField
                          label="Pax Type"
                          variant="standard"
                          className="w-100"
                          value={visa.pax_type}
                          onChange={(e) =>
                            setFieldValue(
                              `paxDetail.${index}.pax_type`,
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-sm-2" style={{ width: "120px" }}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id={`paxDetail.${index}.pax_title`}>
                            Title
                          </InputLabel>
                          <Select
                            id={`paxDetail.${index}.pax_title`}
                            value={values?.paxDetail[index]?.pax_title} // Correctly accessing pax_title
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue(
                                `paxDetail.${index}.pax_title`,
                                value
                              );
                            }}
                            MenuProps={dropMenuProp}
                          >
                            {/* Check if Objdata[visa.pax_type] exists before mapping */}
                            {Objdata[visa?.pax_type]?.map((item) => (
                              <MenuItem
                                key={item.pax_title}
                                value={item?.pax_title}
                              >
                                {item?.pax_title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          name={`paxDetail.${index}.pax_title`}
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <div className="col-2">
                        <TextField
                          label="First Name"
                          variant="standard"
                          className="w-100"
                          value={visa.f_name}
                          onChange={(e) => {
                            setFieldValue(
                              `paxDetail.${index}.f_name`,
                              e.target.value.toUpperCase()
                            );
                          }}
                        />
                        {errors.paxDetail &&
                          errors?.paxDetail[index] &&
                          errors.paxDetail[index].f_name &&
                          touched.paxDetail &&
                          touched.paxDetail[index] &&
                          touched.paxDetail[index].f_name && (
                            <div
                              className="text-danger  position-absolute"
                              style={{ marginTop: "12px" }}
                            >
                              {errors.paxDetail[index].f_name}
                            </div>
                          )}
                      </div>
                      <div className="col-2">
                        <TextField
                          label="Last Name"
                          variant="standard"
                          className="w-100"
                          value={visa.l_name}
                          onChange={(e) => {
                            setFieldValue(
                              `paxDetail.${index}.l_name`,
                              e.target.value.toUpperCase()
                            );
                          }}
                        />
                        {errors.paxDetail &&
                          errors.paxDetail[index] &&
                          errors.paxDetail[index].l_name &&
                          touched.paxDetail &&
                          touched.paxDetail[index] &&
                          touched.paxDetail[index].l_name && (
                            <div
                              className="text-danger  position-absolute"
                              style={{ marginTop: "12px" }}
                            >
                              {errors.paxDetail[index].l_name}
                            </div>
                          )}
                      </div>
                      <div className="" style={{ width: "130px" }}>
                        <ReactDatePicker
                          placeholderText="DOB"
                          selected={visa.dob}
                          value={visa.dob}
                          onChange={(date) => {
                            setFieldValue(`paxDetail.${index}.dob`, date);
                          }}
                          maxDate={new Date()}
                          className="date_picker"
                          showYearDropdown
                          dropdownMode="select"
                        />
                        {errors.paxDetail &&
                          errors.paxDetail[index] &&
                          errors.paxDetail[index].dob &&
                          touched.paxDetail &&
                          touched.paxDetail[index] &&
                          touched.paxDetail[index].dob && (
                            <div
                              className="text-danger  position-absolute"
                              style={{ marginTop: "12px" }}
                            >
                              {errors.paxDetail[index].dob}
                            </div>
                          )}
                      </div>
                      <div className="col-2">
                        <CountryDropdown
                          id="visa_nationality"
                          className="inputs w-100 absolute"
                          preferredCountries={["pk"]}
                          value={visa.nationality}
                          handleChange={(e) =>
                            setFieldValue("nationality", e.target.value)
                          }
                        ></CountryDropdown>
                      </div>
                      <div className="col-2 d-flex flex-column mt-2">
                        <TextField
                          value={visa.passport_no}
                          onChange={(e) => {
                            setFieldValue(
                              `paxDetail.${index}.passport_no`,
                              e.target.value.toUpperCase()
                            );
                          }}
                          label="Pasport No"
                          variant="standard"
                          className="w-100"
                        />
                        {errors.paxDetail &&
                          errors.paxDetail[index] &&
                          errors.paxDetail[index].passport_no &&
                          touched.paxDetail &&
                          touched.paxDetail[index] &&
                          touched.paxDetail[index].passport_no && (
                            <div
                              className="text-danger  position-absolute"
                              style={{ marginTop: "56px" }}
                            >
                              {errors.paxDetail[index].passport_no}
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      className="row align-items-start "
                      style={{ marginTop: "40px" }}
                    >
                      <div className="col-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id={`cur_label`}>Currency</InputLabel>
                          <Select
                            id={`cur_label`}
                            value={visa.cur_label}
                            onChange={(e) => {
                              let value = e.target.value;
                              let currency = currencyData?.find(
                                (it) => it.curr_code === value
                              );

                              let updatedValue = values.paxDetail.map((it) => ({
                                ...it,
                                cur_label: value,
                                currency: currency,
                                roe: currency?.roe,
                                // reset this field
                                curr_pay: 0,
                                curr_rec: 0,
                                pkr_pay: 0,
                                pkr_rec: 0,
                              }));
                              setFieldValue("paxDetail", updatedValue);
                            }}
                            label="Currency"
                            MenuProps={dropMenuProp}
                            required
                            inputProps={{
                              readOnly: index > 0,
                            }}
                          >
                            {currencyData?.map((it, ind) => (
                              <MenuItem value={it.curr_code} key={ind}>
                                {it.curr_code}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-2">
                        <TextField
                          value={visa.roe}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(`paxDetail.${index}.roe`, value);
                            setFieldValue(
                              `paxDetail.${index}.pkr_pay`,
                              TwoDecimalPoint(value * visa.curr_pay)
                            );
                            setFieldValue(
                              `paxDetail.${index}.pkr_rec`,
                              TwoDecimalPoint(value * visa.curr_rec)
                            );
                          }}
                          label="ROE"
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: index > 0,
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={visa.curr_pay}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(`paxDetail.${index}.curr_pay`, value);
                            setFieldValue(
                              `paxDetail.${index}.pkr_pay`,
                              TwoDecimalPoint(value * visa.roe)
                            );
                          }}
                          label={`${isPkr ? "Curr" : visa.cur_label} PAY`}
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={isPkr}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={visa.curr_rec}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(`paxDetail.${index}.curr_rec`, value);
                            setFieldValue(
                              `paxDetail.${index}.pkr_rec`,
                              TwoDecimalPoint(value * visa.roe)
                            );
                          }}
                          label={`${isPkr ? "Curr" : visa.cur_label} REC`}
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={isPkr}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={visa.pkr_pay}
                          onChange={(e) => {
                            setFieldValue(
                              `paxDetail.${index}.pkr_pay`,
                              e.target.value
                            );
                          }}
                          label="PKR PAY"
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                        />
                        <div className="text-primary fs-6 mt-3">
                          {visa?.Supplier_cod}{" "}
                          {TwoDecimalPoint(
                             visa?.pkr_pay /
                              visa?.Supplier_roe
                          )}
                        </div>
                      </div>
                      <div className="col-2">
                        <TextField
                          value={visa.pkr_rec}
                          onChange={(e) => {
                            setFieldValue(
                              `paxDetail.${index}.pkr_rec`,
                              e.target.value
                            );
                          }}
                          label="PKR REC"
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                        />
                        {values?.agent && values?.agent_name !== "" && (
                          <div className="text-primary fs-6 mt-3">
                            {CurrencyCode()}{" "}
                            {TwoDecimalPoint(CurrencyExchange(visa.pkr_rec))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          )}
        </div>
      )}
    />
  );
};

export default PaxDetail;
