import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../../../CustomDrp";
import SearchIcon from "@mui/icons-material/Search";
import Segment from "./Segment";
import PAX from "./PAX";
import Fare from "./Fare";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AsyncGetSingleVoucherEntriesThrougTKTORINV } from "../../../../redux/features/Voucher/VoucherSlice";
import { useNavigate } from "react-router-dom";
import BtnLoader from "../../../AppForm/BtnLoader";

function ManualCredit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { getTktOrInv } = useSelector((state) => state.voucherEntries);
  const [startDate, setStartDate] = React.useState("");
  const [ServiceType, setServiceType] = React.useState("Journal Voucher");
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const validationSchema = Yup.object({
    SearchPNRInvoiceNo: Yup.string().required("This field is required"),
  });

  // Initial form values
  const initialValues = {
    SearchPNRInvoiceNo: "",
  };
  // Check if the login user has permission to manual voucher entry
  const manualCreditPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/manual-credit"
      )
    : true;
  const [message, setMessage] = useState(false);
  const [displayMessage, setDisplay] = useState("");
  const [searchedData, setSearchedData] = useState("");
  return (
    <div className=" finance-container Full_height">
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header px-md-5">
          <div className="w-100 text-center">Manual Credit</div>
        </div>
        <div className="px-5 py-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              let id = values?.SearchPNRInvoiceNo;

              setMessage(true);
              dispatch(AsyncGetSingleVoucherEntriesThrougTKTORINV({ id }))
                .then((res) => {
                  setTimeout(() => {
                    let data = res?.payload?.data;
                    console.log("data", data);
                    const check =
                      data?.message?.split("/")?.at(1)?.split("-")?.at(0) !==
                      "CN";
                    setSearchedData(data);
                    if (check) {
                      if (data?.data?.voucher_id) {
                        setMessage(false);
                        const booking_id = data?.data?.acc_voucher_trans
                          ?.at(0)
                          ?.trans_description?.split("/")
                          ?.at(0);
                        const tkt_number = data?.data?.acc_voucher_trans
                          ?.at(0)
                          ?.trans_description?.split("/")
                          ?.at(4);
                        const voucherId = data?.data?.voucher_id;
                        const voucherNo = data?.data?.voucher_no;

                        navigate("/manual-credit-new", {
                          state: {
                            voucherId: booking_id,
                            tkt_voucher_no: tkt_number.trim(),
                            voucher_id: voucherId,
                            voucher_no: voucherNo,
                          },
                        });
                      } else {
                        setMessage(false);
                        setDisplay(data?.message);
                        // setDisplay("Data Not Found");
                      }
                    } else {
                      setMessage(false);
                      setDisplay(data?.message);
                    }
                  }, 3000);
                })
                .catch((err) => setDisplay("Data Not Found"));
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="col-6 col-md-3 w-100">
                  <Field name="SearchPNRInvoiceNo">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Search TKT / Invoice No"
                        variant="standard"
                        className="w-100"
                        onClick={(e) => {
                          console.log("e", e.target.value);
                        }}
                      />
                    )}
                  </Field>
                  <p className="text-danger">
                    {errors.SearchPNRInvoiceNo
                      ? errors.SearchPNRInvoiceNo
                      : null}
                  </p>
                </div>

                <div className="d-flex justify-content-center align-items-center  mt-5 flex-column gap-2">
                  <div className="">
                    {message ? (
                      <div
                        className="border px-4  mt-4 selectbtn_style"
                        type="submit"
                      >
                        {" "}
                        <BtnLoader />{" "}
                      </div>
                    ) : (
                      <button
                        className="border px-4  mt-4 selectbtn_style"
                        type="submit" // Change type to "button" to prevent form submission
                        disabled={!manualCreditPermission}
                      >
                        Search
                      </button>
                    )}
                  </div>
                  <p className="text-danger">
                    {" "}
                    {displayMessage ? (
                      <div>
                        <span>{displayMessage?.split("/")?.at(0)}</span>{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            const booking_id =
                              searchedData?.data?.acc_voucher_trans
                                ?.at(0)
                                ?.trans_description?.split("/")
                                ?.at(0);
                            const tkt_number =
                              searchedData?.data?.acc_voucher_trans
                                ?.at(0)
                                ?.trans_description?.split("/")
                                ?.at(4);
                            const voucherId = searchedData?.data?.voucher_id;

                            navigate("/manual-credit-new", {
                              state: {
                                voucherId: booking_id,
                                // postVoucher: postVoucher,
                                tkt_voucher_no: tkt_number,
                                voucher_id: voucherId,
                                voucher_no: displayMessage?.split("/")?.at(1),
                              },
                            });
                          }}
                        >
                          {displayMessage?.split("/")?.at(1)}
                        </span>
                      </div>
                    ) : null}
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
    // <div className="finance-container ">
    //   <div className="manual_invoices width_95percent ">
    //     <div className="manual_invoices_header">
    //       <div>Manual Credit</div>
    //     </div>
    //     <div className="px-2 px-md-4 h-20">
    //       <div className="row py-4 align-items-end  flex justify-content-center">
    //         {/* <div className="col-6 col-md-3">
    //           <CustomDropdown
    //             arry={["Flight", "Insurance", "Visa"]}
    //             placehlder="Service Type"
    //             setfunction={setServiceType}
    //           />
    //         </div> */}
    //         <div className="col-6 col-md-3">
    //           <TextField
    //             label="Search PNR / Invoice No"
    //             variant="standard"
    //             className="w-100"
    //           />
    //         </div>
    //         <div className="col-12 col-md-3 mt-3 mt-md-0">
    //           <Button
    //             variant="contained"
    //             startIcon={<SearchIcon />}
    //             disabled={!manualCreditPermission}
    //           >
    //             Search
    //           </Button>
    //         </div>
    //       </div>

    //       {/* <hr />
    //       <div>
    //         <PAX />
    //         <hr />
    //         <Segment />
    //         <hr />
    //         <Fare />
    //       </div>

    //       <hr /> */}

    //       {/* total  */}
    //       {/* <div className=" py-2">
    //         <div className="h5 text-center my-2">Total</div>
    //         <div className="d-flex justify-content-center align-items-center flex-column my-4">
    //           <div className="h6 mb-2">Customers = 330 PKR</div>
    //           <div className="h6 mb-2">Supplier = 330 PKR</div>

    //           <Button
    //             size="medium"
    //             variant="contained"
    //             disabled={!manualCreditPermission}
    //           >
    //             Submit
    //           </Button>
    //         </div>
    //       </div> */}
    //     </div>

    //     {/* ********** */}
    //   </div>
    // </div>
  );
}

export default ManualCredit;
