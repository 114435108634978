import moment from 'moment';
import React from 'react'
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md'

const MultiSabreIteneries = ({
    totalDiscount,
    array,
    timeDuration,
    segments,
    sabreItem,
    toggleDetail,
    index,
    showOtherOption,
    setShowOtherOption,
    isExpanded,
    calculateTotalTimeDifference,
    selectedFlight,
    elapsedTime
}) => {
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const multiDepartureSectors = searchValues?.multi_des_from;
    const multiArrivalSectors = searchValues?.multi_des_to;

    const getCity = (loc) => {
        let city = loc?.split("-")[2]?.toUpperCase();
        return city;
    };

    const getDepartureLocation = (index) => {
        if (index === 0) {
            return locDeparture;
        } else {
            return multiDepartureSectors[index - 1];
        }
    };

    const getArrivalLocation = (index) => {
        if (index === 0) {
            return locArrival;
        } else {
            return multiArrivalSectors[index - 1];
        }
    };
    return (
        <div>
            <div
                className="d-flex py-4"
            >
                <div className="col-2">
                    <div className="text-center">
                        <img
                            src={`/NEW_LOGO/${segments?.at(0)?.carrier?.marketing}.png`}
                            alt="logo"
                            width={90}
                            height={90}
                        />
                        <div className="pt-3">
                            {segments?.map((code, index, arr) => (
                                <span className="h6">
                                    {`${code?.carrier?.marketing} ${code?.carrier?.marketingFlightNumber} ${index < arr.length - 1 ? " - " : ""}`}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff
                                    size={30}
                                    className="color_primary"
                                />
                            </div>
                            <div>
                                <h6>
                                    {moment(segments?.at(0)?.travdate).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(segments?.at(0)?.departure?.time, "HH:mm:ssZ").format("HH:mm")}
                                </h6>
                                <h6>
                                    {segments?.at(0)?.departure?.airport} -{" "}
                                    {getCity(getDepartureLocation(index))}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="my-3 text-center">
                                {calculateTotalTimeDifference(
                                    segments?.at(0)?.departure?.time,  // First departure time
                                    segments?.at(segments?.length - 1)?.arrival?.time // Last arrival time
                                )}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                {segments?.length === 1
                                    ? "Non Stop"
                                    : segments?.length === 2
                                        ? "One Stop" :
                                        segments?.length === 3
                                            ? "Two-Stop" : "Three-Stop"}
                            </h6>

                            {segments?.length > 1 && (
                                <h6 className="text-center">
                                    {segments
                                        ?.slice(1)
                                        ?.map((seg) => seg?.departure?.airport)
                                        ?.join(", ")}
                                </h6>
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand
                                    size={30}
                                    className="color_primary"
                                />
                            </div>
                            <div>
                                <h6>
                                    {moment(segments?.at(0)?.travdate).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(segments?.at(segments?.length - 1)?.arrival?.time, "HH:mm:ssZ").format("HH:mm")}
                                </h6>
                                <h6>
                                    {segments?.at(segments.length - 1)?.arrival?.airport} - {" "}
                                    {getCity(getArrivalLocation(index))}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiSabreIteneries
