import { useDispatch } from "react-redux";
import {
  asyncGenerateOtpcode,
  getAsyncSearchPNR,
} from "../../redux/features/booking/bookingDetailsSlice";
import BtnLoader from "../AppForm/BtnLoader";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import moment from "moment";
import { FieldArray, Form, Formik } from "formik";
import { asyncUpdateFlightGroupPassenger } from "../../redux/features/promotion/FlightGroupsSlice";
import { TextField } from "@mui/material";
import {
  UniqueInputValues,
  UniqueInputVlues,
} from "../../Utils/UniqueInputValus";

const PaxTicketIssue = ({
  ticket,
  booking_id,
  agent_acc_id,
  active_tab,
  setMessageToggle,
  setActive_Tab,
  setOtp,
  isLoading,
  setIsLoading,
  paxs,
  setPaxData,
  paxData,
  auto_manual,
  user,
  booking,
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  // const [paxData, setPaxData] = useState([]);
  const [pnrError, setPnrError] = useState({});
  const [checkUniqueInputValue, setCheckUniqueInputValue] = useState({});
  const [paxState, setPaxState] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPaxData(
      paxs?.map((p, ind) => ({
        pax_id: p?.pax_id,
        first_name: p?.fName,
        tkt_no: "",
      }))
    );
  }, [paxs?.length]);

  const debouncedHandleChange = debounce((e, index) => {
    const param = e.target.value;
    setLoading(true); // Start loading

    dispatch(getAsyncSearchPNR(param))
      .then((response) => {
        setPnrError({
          ...pnrError,
          ["open_" + index]: response?.payload ? true : false,
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
    .finally(() => {
      setLoading(false); // Stop loading
    });
  }, 300);
  const isAnyPnrErrorTrue = Object.values(pnrError).some(
    (value) => value === true
  );

  return (
    <>
      {/* Radion button */}
      <div>
        {auto_manual === 0 ? (
          <div className="d-flex ">
            <span
              className="form-control me-5"
              style={{
                display: "flex",
                flexDirection: "row",

                paddingLeft: "10px",
              }}
            >
              <input
                type="radio"
                id="without_statement"
                value="cheque"
                name="currency"
                checked={state === "Auto"}
                onChange={() => setState("Auto")}
              />
              <label for="without_statement" className="h6">
                Auto
              </label>
            </span>
            <span
              className="form-control me-5"
              style={{
                display: "flex",
                flexDirection: "row",

                gap: 8,

                paddingLeft: "10px",
              }}
            >
              <input
                type="radio"
                id="with_sar"
                value="cheque"
                name="currency"
                disabled={user.agent_id !== 1}
                checked={state === "Manual"}
                onChange={() => setState("Manual")}
              />
              <label for="with_sar" className="h6">
                Manual
              </label>
            </span>
          </div>
        ) : null}
      </div>

      <div>
        <Formik
          initialValues={{ paxData }}
          enableReinitialize={true}
          onSubmit={(values) => {
            let id = paxs?.book_id;
            let body = {
              ...values,
            };
            setIsLoading(true);
            setTimeout(() => {
              setPaxData(body);
              dispatch(asyncGenerateOtpcode({ booking_id, agent_acc_id }));
              setActive_Tab("otp");
              setMessageToggle("");
              setOtp("otp");
              setIsLoading(false);
            }, 3000);

            // dispatch(asyncUpdateFlightGroupPassenger({ id, body }));
            //   setActive_Tab("Details");
            // setPaxState(false);
          }}
          render={({ values, setFieldValue }) => {
            const isAnyTicketEmpty = values?.paxData?.some((pax) => !pax.tkt_no);
            return (
              <Form>
                <FieldArray
                  name="paxData"
                  render={(arrayHelpers) => (
                    <div className="d-flex  border-bottom flex-column gap-4  py-4">
                      {values.paxData && values?.paxData.length > 0
                        ? values.paxData.map(
                            (pax, index) =>
                              state === "Manual" && (
                                <div key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div style={{ width: 320, marginTop: 10 }}>
                                      <p className="h6  ps-2  ">
                                        {pax?.first_name}
                                      </p>
                                    </div>
                                    <div style={{ width: 320 }}>
                                      <TextField
                                        variant="standard"
                                        name="tkt_no"
                                        className="w-80"
                                        value={values?.paxData[index]?.tkt_no}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `paxData.${index}.tkt_no`,
                                            e.target.value.toUpperCase()
                                          );
                                          debouncedHandleChange(e, index);
                                          UniqueInputValues(
                                            values.paxData,
                                            index,
                                            e
                                          ).then((unique) => {
                                            setCheckUniqueInputValue(
                                              (prevState) => ({
                                                ...prevState,
                                                ["unique_" + index]: unique,
                                              })
                                            );
                                          });
                                        }}
                                      />
                                      {pnrError["open_" + index] ||
                                      checkUniqueInputValue[
                                        "unique_" + index
                                      ] ? (
                                        <p
                                          className="position-absolute text-danger  "
                                          style={{ marginLeft: -63 }}
                                        >
                                          Ticket number must be unique.
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )
                          )
                        : null}

                      <div>
                        <p
                          className={`h6  ps-4  ${
                            ticket?.name === active_tab
                              ? "booking-tab-link "
                              : "bookings_details_links "
                          }`}
                        >
                          {ticket?.name}
                        </p>
                        <div className="d-flex justify-content-around">
                          <button
                            className="px-3 py-2 btn btn-danger "
                            onClick={() => setMessageToggle("")}
                          >
                            Cancel
                          </button>
                          <button
                            className="px-4 py-2 selectbtn_style "
                            type="submit"
                            disabled={
                              loading ||
                              isAnyPnrErrorTrue ||
                              (isAnyTicketEmpty && state === "Manual")
                            }
                            // onClick={() => {
                            //   dispatch(asyncGenerateOtpcode());
                            //   setMessageToggle("");
                            //   setOtp("otp");
                            // }}
                          >
                            {isLoading ? (
                              <BtnLoader cancelBooking={true} />
                            ) : (
                              "Issue"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

export default PaxTicketIssue;
