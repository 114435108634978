import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AIRBLUE from "../../assets/images/airlineLogo/PA.png";
import AIRSIAL from "../../assets/images/airlineLogo/PF.png";
import SERENE from "../../assets/images/airlineLogo/ER.png";
import HITIT from "../../assets/images/airlineLogo/PK.png";
import TRAVELPORT from "../../assets/images/airlineLogo/TR.png";
import SABRE from "../../assets/images/airlineLogo/SB.png";
import FLYDUBAI from "../../assets/images/airlineLogo/FZ.png";
import OMANNDC from "../../assets/images/airlineLogo/WY.png";
import EMIRATES from "../../assets/images/airlineLogo/EK.png";
import FLYJINNAH from "../../assets/images/airlineLogo/9P.png";
import AMADEUS from "../../assets/images/airlineLogo/amadeusRounded.png";
import {
  ClickAwayListener,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAllGDS } from "../../redux/features/setupFeature/ApiCredential/ApiCredentials";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { asyncSearchAgencyData } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import {
  asyncGetDefaultAirportDestination,
  asyncGetDefaultOriginAirport,
  asyncGetDestinationAirportsName,
  asyncGetOriginAirportsName,
} from "../../redux/features/home/homeSlice";
import { MdClose, MdFlightLand, MdFlightTakeoff } from "react-icons/md";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  setNewArrivalDate,
  setNewDepDate,
  setSearchValues,
  setselectedFlight,
} from "../../redux/features/home/FlightSearchSlice";
import {
  isAirblueSectors,
  isDomesticSectors,
  isFlyjinnahSectors,
  isSereneSectors,
  isSialSectors,
  isUmrahFare,
} from "../../Utils/FlightSector";
import * as yup from "yup";
import { debounce } from "lodash";
import Timer from "./checkout/Timer";
import { BiSearch } from "react-icons/bi";

const FlightsTab = ({ searchData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let inputRef = React.useRef();

  // use selectors redux
  const { GDSData, loading } = useSelector((state) => state?.apiCredentials);
  const { searchAgency } = useSelector((state) => state.newAgency);
  const { originAirportList, destinatioAirportList } = useSelector(
    (state) => state.home
  );

  const user =
    localStorage.getItem("loginUser") &&
    JSON.parse(localStorage.getItem("loginUser"));
  const [autoOpen, setautoOpen] = useState("");
  const [AirportDrp, setAirportDrp] = useState("");
  const [travelerDropDown, setTravelerDropDown] = useState("");
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [multi_dest_drp, setMulti_dest_drp] = useState("");

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date)
  const [sereneMessage, setSereneMessage] = useState("")

  //gds images array
  const images = {
    AIRBLUE,
    AIRSIAL,
    SERENE,
    HITIT,
    TRAVELPORT,
    SABRE,
    FLYDUBAI,
    FLYJINNAH,
    OMANNDC,
    EMIRATES,
    AMADEUS,
  };

  ///// USEEFFECT  //////////
  useEffect(() => {
    dispatch(asyncGetAllGDS());
    dispatch(asyncGetDefaultOriginAirport());
    dispatch(asyncGetDefaultAirportDestination());
  }, [dispatch]);

  ////////swap origin and destination//////////
  const handleSwap = (values, setFieldValue) => {
    const tempFromWhere = values.from_where;
    setFieldValue("from_where", values.to_where);
    setFieldValue("to_where", tempFromWhere);
    setAirportDrp("");
  };

  // child age array
  const childrenAgeOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  //////////////// initial value  //////////////////
  const initialValues = {
    all_select: searchData ? searchData?.all_select : true,
    adult: searchData ? searchData?.adultsCount : 1,
    children: searchData ? searchData?.childCount : 0,
    infant: searchData ? searchData?.infantNo : 0,
    trip: searchData ? searchData?.trip_type : "ONE WAY",
    agent: searchData ? searchData?.agent : "",
    agent_name: searchData ? searchData?.agent?.agent_name : "",
    from_where: searchData ? searchData?.locationDep : "",
    to_where: searchData ? searchData?.locationArrival : "",
    startDate: searchData
      ? moment(searchData?.departingOn, "DD-MM-YYYY").toDate()
      : startDate,
    endDate: searchData?.ReturningOn
      ? moment(searchData?.ReturningOn, "DD-MM-YYYY").toDate()
      : endDate,
    ticket_class: searchData ? searchData?.ticket_class : "Economy",
    children_ages: searchData ? searchData?.children_ages : ["5"],
    infant_ages: searchData ? searchData?.infant_ages : ["1"],

    gds: GDSData?.reduce((acc, it) => {
      if (it.api_call === 1 && it.gds_name !== "Fly Dubai") {
        acc[it.gds_name] = {
          id: it.id,
          value: searchData?.gds?.includes(it.gds_name) || true,
          api_call: it.api_call,
          gds_order: it.gds_order,
          gds_name: it.gds_name,
        };
      }
      return acc;
    }, {}),

    selected_gds: searchData ? searchData?.gds?.length === 0 ? searchData?.gds[0] : "all" : "all",

    umrah_fare: false,
    multi_des_flights: searchData
      ? searchData?.multi_des_from.map((from, index) => ({
        from_where: from,
        to_where: searchData?.multi_des_to[index] || "",
        startDate: moment(
          searchData?.multi_des_from_date[index],
          "DD-MM-YYYY"
        ).toDate(),
        ticket_class: searchData?.multi_des_ticket_class[index] || "Economy",
      }))
      : [
        {
          from_where: "",
          to_where: "",
          startDate: new Date(),
          ticket_class: "Economy",
        },
      ],
  };
  // split origin and destination
  const getOrigDesCode = (val) => {
    return val.split("-")[0];
  };

  // functionality to hide gds base on sectors
  const isDomesticSector = (gds, orig, des) => {
    if (des !== "") {
      if (
        gds === "Air Blue" &&
        isAirblueSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (
        gds === "Air sial" &&
        isSialSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (
        gds === "Serene" &&
        isSereneSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (
        gds === "Serene-Api" &&
        isSereneSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (
        (gds === "Sabre" ||
          gds === "Travel Port" ||
          gds === "Fly Dubai" ||
          gds === "Amadeus") &&
        !isDomesticSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (
        gds === "Fly Jinnah"
        // isFlyjinnahSectors(getOrigDesCode(orig), getOrigDesCode(des))
      )
        return true;
      else if (gds === "HITIT") return true;
    } else return true;
  };

  /// isMultiDestination
  const isMultiDestination = (gds, trip) => {
    if (trip === "MULTI DESTINATION") {
      if (gds === "Air Blue") return true;
      else if (gds === "HITIT") return true;
      else if (gds === "Travel Port") return true;
      else if (gds === "Sabre") return true;
      else if (gds === "Amadeus") return true;
      else return false;
    } else return true;
  };
  //validation schema
  const validationSchema = yup.object().shape({
    from_where: yup.string().required("From where is required"),
    to_where: yup.string().required("To where is required"),
    // gds: yup
    //   .object()
    //   .test("at-least-one-gds", "Select at least one GDS", (gdsObject) => {
    //     const gdsValues = Object.values(gdsObject);
    //     return gdsValues.some((gds) => gds.value === true);
    //   }),
    endDate: yup
      .date()
      .nullable()
      .required("End date is required")
      .typeError("End date is required"),
  });

  ////// if GDS DATA NOT LOADED WILL SHOW THIS
  if (loading) {
    return <p>Loading data...</p>;
  }

  const handleOriginAvailibility = debounce((e, index) => {
    dispatch(asyncGetOriginAirportsName(e.target.value));
  }, 500);
  const handleDesAvailibility = debounce((e, index) => {
    dispatch(asyncGetDestinationAirportsName(e.target.value));
  }, 500);

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        let tm = Date.now();
        localStorage.setItem("timer", JSON.stringify(tm));
        let data = {
          departingOn: moment(values.startDate).format("DD-MM-YYYY"),
          locationDep: values.from_where,
          locationArrival: values.to_where,
          ReturningOn:
            values.endDate !== ""
              ? moment(values.endDate).format("DD-MM-YYYY")
              : "",
          multi_des_from: values?.multi_des_flights?.map(
            (multi_from) => multi_from?.from_where
          ),
          multi_des_to: values?.multi_des_flights?.map(
            (multi_to) => multi_to?.to_where
          ),
          multi_des_from_date: values?.multi_des_flights?.map((multi_date) =>
            moment(multi_date?.startDate).format("DD-MM-YYYY")
          ),
          multi_des_ticket_class: values?.multi_des_flights?.map(
            (multi_class) => multi_class?.ticket_class
          ),
          adultsCount: values.adult,
          adult_ages: ["30"],
          childCount: values.children,
          children_ages: values.children_ages,
          infantNo: values.infant,
          infant_ages: [],
          trip_type: values.trip,
          all_select: values.all_select,
          gds: Object.entries(values.gds)
            .map(
              ([gdsName]) =>
                isDomesticSector(gdsName, values.from_where, values.to_where) &&
                gdsName
            )
            .filter((key) => values.gds[key]?.value === true),
          ticket_class: values.ticket_class,
          agent: user?.agent_id === 1 ? values.agent : user?.agents_comp,
          umrah_fare: values.umrah_fare,
        };
        if (data.gds?.includes("Serene")) {
          data = {
            ...data,
            gds: [...data.gds, "Serene-Api"]
          }
        }
        // set values to local storage
        localStorage.setItem("flightSearchData", JSON.stringify(data));

        // call reducers function
        dispatch(setSearchValues(data));
        dispatch(setNewDepDate(null));
        dispatch(setNewArrivalDate(null));
        dispatch(setselectedFlight(null));

        navigate("/search-results");
      }}
    >
      {({ values, setFieldValue, handleChange }) => {
        return (
          <Form>
            <div className="pb-4">
              <div className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex flex-wrap align-items-center">
                  <div>
                    <label className="form-control">
                      <input
                        type="radio"
                        id="oneway"
                        value="ONE WAY"
                        name="trip"
                        checked={values.trip === "ONE WAY"}
                        onChange={handleChange}
                      />
                      <span className="h6">OW Trip</span>
                    </label>
                  </div>
                  <div className="mx-5">
                    <label className="form-control">
                      <input
                        type="radio"
                        id="round_trip"
                        value="ROUND TRIP"
                        name="trip"
                        checked={values.trip === "ROUND TRIP"}
                        onChange={handleChange}
                      />
                      <span className="h6">RT Trip</span>
                    </label>
                  </div>

                  <div>
                    <label className="form-control">
                      <input
                        type="radio"
                        id="multi_destination"
                        value="MULTI DESTINATION"
                        name="trip"
                        checked={values.trip === "MULTI DESTINATION"}
                        onChange={handleChange}
                      />
                      <span className="h6">MT Trip</span>
                    </label>
                  </div>

                  <div className="ms-5">
                    <FormControl
                      fullWidth
                      variant="standard"
                      className="py-2"
                      style={{ width: 300 }}
                    >
                      <InputLabel id="gds-dropdown-label">Preferred Airline/GDS</InputLabel>
                      <Select
                        labelId="gds-dropdown-label"
                        id="gds-dropdown"
                        name="gds-dropdown"
                        style={{ whiteSpace: "nowrap", color: "#3a3939" }}
                        value={values.selected_gds || "all"}
                        onChange={(e) => {
                          const selectedValue = e.target.value;

                          if (selectedValue === "all") {
                            const updatedGds = Object.fromEntries(
                              Object.entries(values.gds).map(([name, value]) => {
                                return [name, { ...value, value: true }];
                              })
                            );
                            setFieldValue("gds", updatedGds);
                            setFieldValue("all_select", true);
                          } else {
                            const updatedGds = Object.fromEntries(
                              Object.entries(values.gds).map(([name, value]) => {
                                return [name, { ...value, value: name === selectedValue }];
                              })
                            );
                            setFieldValue("gds", updatedGds);
                            setFieldValue("all_select", false);
                          }
                          setFieldValue("selected_gds", selectedValue);
                        }}
                      >
                        <MenuItem value="all">
                          <span className="fs-5" style={{ color: "#3a3939" }}>Select All</span>
                        </MenuItem>
                        {Object.entries(values.gds)
                          .sort(([, a], [, b]) => a.gds_order - b.gds_order)
                          .filter(([gdsName]) =>
                            isDomesticSector(gdsName, values.from_where, values.to_where) &&
                            isMultiDestination(gdsName, values.trip)
                          )
                          .map(([gdsName]) => {
                            const imageGds = gdsName.replace(" ", "").toUpperCase();
                            const customLabels = {
                              "Fly Jinnah": "FlyJinnah / Air Arabia",
                              Amadeus: "IATA/Amadeus",
                              Sabre: "IATA/Sabre",
                              HITIT: "PIA",
                              "Air sial": "Air Sial"
                            };
                            const displayName = customLabels[gdsName] || gdsName;
                            return (
                              <MenuItem key={gdsName} value={gdsName} className="pt-3">
                                <img
                                  src={images[imageGds]}
                                  alt={gdsName}
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: "10px",
                                  }}
                                />
                                <span className="fs-5" style={{ color: "#3a3939" }}>
                                  {displayName}
                                </span>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <h6 className="text-danger">{sereneMessage}</h6>
                  </div>
                </div>

                {/* /////// agent company filter  ///////////// */}
                {user?.agent_id !== 1 ? null : (
                  <div className="col-2 relative h-4rem">
                    <TextField
                      variant="standard"
                      label="Select Agent"
                      placeholder="Enter Agent Name"
                      autoComplete="off"
                      className="w-100"
                      name="agent_name"
                      value={values.agent_name}
                      onClick={() => setagentDRopDwn(!agentDRopDwn)}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("agent_name", value);
                        setFieldValue("agent", "");
                        dispatch(asyncSearchAgencyData(e.target.value));
                      }}
                      onFocus={(e) => e.target.select()}
                      autoFocus={agentDRopDwn}
                    />

                    {agentDRopDwn ? (
                      <ClickAwayListener
                        onClickAway={() => setagentDRopDwn(false)}
                      >
                        <div className="agent_dropdown">
                          {searchAgency?.length > 0 ? (
                            searchAgency?.map((item) => {
                              return (
                                <div
                                  className="p-3"
                                  onClick={(e) => {
                                    setFieldValue("agent_name", item.agent_name);
                                    setFieldValue("agent", item);
                                    setagentDRopDwn(false);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  key={item?.agent_id}
                                >
                                  {item.agent_name}
                                </div>
                              );
                            })
                          ) : (
                            <div className="p-3">No record available.</div>
                          )}
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="my-4 mt-5">
                <div className="row align-items-end">
                  <div className="col-6 col-sm-6">
                    <ClickAwayListener onClickAway={() => setAirportDrp("")}>
                      <div className="d-flex align-items-end">
                        {/*  FROM WHERE /////////////////////////*/}
                        <div className="autocomplete-wrapper relative">
                          <ErrorMessage
                            component={"div"}
                            className="text-danger"
                            name="from_where"
                          />
                          <FormControl
                            variant="standard"
                            className="w-100"
                            onClick={() => {
                              setAirportDrp("from_where");
                            }}
                          >
                            <InputLabel htmlFor="text-from_where">
                              From Where
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              id="text-from_where"
                              value={values.from_where}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("from_where", value);
                                value === ""
                                  ? dispatch(asyncGetDefaultOriginAirport())
                                  : handleOriginAvailibility(e);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.from_where !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue("from_where", "");
                                        dispatch(asyncGetDefaultOriginAirport());
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {AirportDrp === "from_where" ? (
                            <div className="airport_search_drp">
                              {originAirportList?.length > 0 ? (
                                originAirportList?.map((item, index) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className="airport_search_drp_item"
                                      onClick={() => {
                                        let value = `${item.code}-${item.airport_name}-${item.city}`;
                                        setFieldValue("from_where", value);
                                        setAirportDrp("to_where");
                                        inputRef.current.focus();
                                      }}
                                    >
                                      <MdFlightTakeoff className="me-3 fs-3 color_primary" />
                                      <div className="fs-5">
                                        {item.code} - {item.airport_name} -{" "}
                                        {item.city}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="airport_search_drp_item">
                                  No Record Found.
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>

                        {values?.trip !== "MULTI DESTINATION" ? (
                          <SwapHorizontalCircleIcon
                            className="swap_icon"
                            onClick={() => handleSwap(values, setFieldValue)}
                          />
                        ) : (
                          <span className="mx-2"></span>
                        )}

                        {/* /////////// TO WHERE  //////////// */}

                        <div className="autocomplete-wrapper relative">
                          <ErrorMessage
                            component={"div"}
                            className="text-danger"
                            name="to_where"
                          />
                          <FormControl
                            variant="standard"
                            className="w-100"
                            onClick={() => {
                              setAirportDrp("to_where");
                            }}
                          >
                            <InputLabel htmlFor="text-to_where">
                              To Where
                            </InputLabel>
                            <Input
                              inputRef={inputRef}
                              autoComplete="off"
                              id="text-to_where"
                              value={values.to_where}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue("to_where", value);
                                value === ""
                                  ? dispatch(asyncGetDefaultAirportDestination())
                                  : handleDesAvailibility(e);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.to_where !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue("to_where", "");
                                        dispatch(
                                          asyncGetDefaultAirportDestination()
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {AirportDrp === "to_where" ? (
                            <div className="airport_search_drp">
                              {destinatioAirportList?.length > 0 ? (
                                destinatioAirportList?.map((item, index) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className="airport_search_drp_item"
                                      onClick={() => {
                                        let value = `${item?.code}-${item?.airport_name}-${item?.city}`;
                                        // let isUmrahSector = isUmrahFare(
                                        //   values.from_where,
                                        //   value
                                        // );
                                        setFieldValue("to_where", value);
                                        setFieldValue(
                                          "multi_des_flights.0.from_where",
                                          value
                                        );
                                        setAirportDrp("");
                                        setautoOpen("date");
                                        // if (
                                        //   isUmrahSector &&
                                        //   (values.trip === "ROUND TRIP" ||
                                        //     values.trip === "MULTI DESTINATION")
                                        // ) {
                                        //   const updatedGds = Object.keys(
                                        //     values.gds
                                        //   ).reduce((acc, gdsName) => {
                                        //     acc[gdsName] = {
                                        //       ...values.gds[gdsName],
                                        //       value: gdsName === "HITIT",
                                        //     };
                                        //     return acc;
                                        //   }, {});
                                        //   setFieldValue("umrah_fare", true);
                                        //   setFieldValue("gds", updatedGds);
                                        // } else {
                                        //   setFieldValue("umrah_fare", false);
                                        // }

                                        dispatch(
                                          asyncGetDefaultAirportDestination()
                                        );
                                      }}
                                    >
                                      <MdFlightLand className="me-3 fs-3 color_primary" />
                                      <div className="fs-5">
                                        {item?.code} - {item?.airport_name} -{" "}
                                        {item?.city}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="airport_search_drp_item">
                                  No Record Found.
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>

                  {/* ///////////// round trip date picker ////////////// */}
                  {values.trip === "ROUND TRIP" ? (
                    <div className="col-6 col-md-3 mt-5 mt-md-0">
                      <ErrorMessage
                        component={"div"}
                        className="text-danger mb-3"
                        name="endDate"
                      />
                      <ReactDatePicker
                        className="date_picker"
                        selected={values.endDate}
                        onChange={(dates) => {
                          let [start, end] = dates || [];
                          setstartDate(start);
                          setFieldValue("startDate", start);
                          setEndDate(end);
                          setFieldValue("endDate", end);
                          if (start && end) {
                            setautoOpen("");
                          }
                        }}
                        selectsRange
                        startDate={values.startDate}
                        endDate={values.endDate}
                        minDate={new Date()}
                        monthsShown={2}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Start date, End date"
                        open={autoOpen === "date"}
                        onFocus={() => setautoOpen("date")}
                        // onBlur={() => setautoOpen("")}
                        onClickOutside={() => setautoOpen("")}
                      />

                    </div>
                  ) : (
                    /* /// //////////// one way date picker multi distination ///////////// */
                    <div className="col-6 col-md-2 mt-4 mt-md-0">
                      <ReactDatePicker
                        placeholderText="Departure Date"
                        monthsShown={2}
                        selected={values.startDate}
                        dateFormat="MMMM d, yyyy"
                        onChange={(date) => {
                          setFieldValue("startDate", date);
                          setautoOpen("");
                          setFieldValue("multi_des_flights.0.startDate", date);
                        }}
                        minDate={new Date()}
                        className="date_picker"
                        open={autoOpen === "date"}
                        onFocus={() => {
                          setautoOpen("date");
                        }}
                        onBlur={() => {
                          setautoOpen("");
                        }}
                        onClickOutside={() => setautoOpen("")}
                        onSelect={() => setautoOpen("")}
                      />
                    </div>
                  )}
                  {/* //////// travelor dropdown /////// */}
                  <div className="col-6 col-md-3 mt-4 mt-md-0 relative d-flex justify-content-between align-items-end">
                    <ClickAwayListener
                      onClickAway={() => setTravelerDropDown("")}
                    >
                      <div className="col-6">
                        <TextField
                          variant="standard"
                          autoComplete="off"
                          label={`${values.adult + values.children + values.infant
                            } Travelers, ${values.ticket_class}`}
                          className="w-100"
                          onClick={(e) => {
                            setTravelerDropDown(`travel`);
                          }}
                          inputProps={{
                            readOnly: true,
                          }}
                        />

                        {travelerDropDown === `travel` && (
                          <div className="travelar_dropdown">
                            {/* adult */}
                            <div className="d-flex justify-content-between items-end">
                              <div className="pt-1">
                                <h6>ADULT (Above 12)</h6>
                              </div>
                              <div>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.adult < 2}
                                  onClick={(e) => {
                                    setFieldValue("adult", values.adult - 1);
                                  }}
                                >
                                  -
                                </button>
                                <span className="mx-3">{values.adult}</span>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.adult + values.children > 6}
                                  onClick={(e) => {
                                    setFieldValue("adult", values.adult + 1);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            {/* child */}
                            <div className="d-flex justify-content-between items-end ">
                              <div className="pt-1">
                                <h6>CHILD (2-12)</h6>
                              </div>
                              <div>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.children < 1}
                                  onClick={(e) => {
                                    setFieldValue(
                                      "children",
                                      values.children - 1
                                    );
                                  }}
                                >
                                  -
                                </button>
                                <span className="mx-3">{values.children}</span>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.adult + values.children > 6}
                                  onClick={(e) => {
                                    setFieldValue(
                                      "children",
                                      values.children + 1
                                    );
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>

                            <hr />
                            {/* infant */}
                            <div className="d-flex justify-content-between items-end ">
                              <div className="pt-1">
                                <h6>INFANT (Under 2)</h6>
                              </div>
                              <div>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.infant < 1}
                                  onClick={(e) => {
                                    setFieldValue("infant", values.infant - 1);
                                  }}
                                >
                                  -
                                </button>
                                <span className="mx-3">{values.infant}</span>
                                <button
                                  className="button_style py-2 px-3"
                                  type="button"
                                  disabled={values.infant >= values.adult}
                                  onClick={(e) => {
                                    setFieldValue("infant", values.infant + 1);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            {/* if values.children is > 0 i want show the below dropdow but if the number is 1 i want to show 1 dropdown and as number increase i want to increase number of dropdown */}
                            {values.children > 0 && (
                              <>
                                <hr />
                                <div className="">
                                  <h5>Select Children Ages (Years)</h5>
                                  <div className="d-flex flex-wrap gap-3 ">
                                    {values.children > 0 &&
                                      Array.from({
                                        length: values.children,
                                      }).map((_, index) => (
                                        <div key={index} className="my-3">
                                          <select
                                            id={`childerenAges${index}`}
                                            value={values.children_ages[index]}
                                            onChange={(e) => {
                                              const newAges = [
                                                ...values.children_ages,
                                              ];
                                              newAges[index] = e.target.value;
                                              setFieldValue(
                                                "children_ages",
                                                newAges
                                              );
                                            }}
                                            style={{
                                              width: "100px",
                                              backgroundColor: "white",
                                              border: "none",
                                              borderBottom: "1px solid black",
                                              outline: "none",
                                            }}
                                          >
                                            {childrenAgeOptions.map((age) => (
                                              <option key={age} value={age}>
                                                {age}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </>
                            )}
                            <hr />
                            <div className="row mt-4">
                              <div className="col-6">
                                <button
                                  type="button"
                                  className={`w-100 ${values.ticket_class === "Economy"
                                    ? "button_style"
                                    : "ticket_class_button"
                                    }`}
                                  onClick={(e) => {
                                    setFieldValue("ticket_class", "Economy");
                                    setTravelerDropDown("");
                                  }}
                                >
                                  Economy
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  type="button"
                                  className={`w-100 ${values.ticket_class === "Premium"
                                    ? "button_style"
                                    : "ticket_class_button"
                                    }`}
                                  onClick={(e) => {
                                    setFieldValue("ticket_class", "Premium");
                                    setTravelerDropDown("");
                                  }}
                                >
                                  Premium
                                </button>
                              </div>
                              <div className="col-6 mt-3">
                                <button
                                  type="button"
                                  className={`w-100 ${values.ticket_class === "Bussiness"
                                    ? "button_style"
                                    : "ticket_class_button"
                                    }`}
                                  onClick={(e) => {
                                    setFieldValue("ticket_class", "Bussiness");
                                    setTravelerDropDown("");
                                  }}
                                >
                                  Business
                                </button>
                              </div>
                              <div className="col-6 mt-3">
                                <button
                                  type="button"
                                  className={`w-100 ${values.ticket_class === "First"
                                    ? "button_style"
                                    : "ticket_class_button"
                                    }`}
                                  onClick={(e) => {
                                    setFieldValue("ticket_class", "First");
                                    setTravelerDropDown("");
                                  }}
                                >
                                  First
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ClickAwayListener>
                    {/* /////////////////// search button ///////////// */}
                    <div className="col-5">
                      <button className="button_style w-100" type="submit">
                        <span>
                          <BiSearch size={22} />
                        </span>
                        <span className="ps-1">Search</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/********************* Multiple destination    ************* */}
              {values?.trip === "MULTI DESTINATION" && (
                <FieldArray
                  name="multi_des_flights"
                  render={(arrayHelpers) => (
                    <ClickAwayListener onClickAway={() => setMulti_dest_drp("")}>
                      <div>
                        {values?.multi_des_flights?.map((flight, index) => {
                          return (
                            <div className="row mt-5 align-items-end" key={index}>
                              {/* /////////////////////// from where  /////////////////// */}
                              <div className="col-6 col-md-3 relative">
                                <FormControl
                                  variant="standard"
                                  className="w-100"
                                  onClick={() => {
                                    setMulti_dest_drp(`from_where_${index}`);
                                  }}
                                >
                                  <InputLabel htmlFor={`from_where_${index}`}>
                                    From Where
                                  </InputLabel>
                                  <Input
                                    autoComplete="off"
                                    id={`multi_des_flights.${index}.from_where`}
                                    value={
                                      values.multi_des_flights[index].from_where
                                    }
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setFieldValue(
                                        `multi_des_flights.${index}.from_where`,
                                        value
                                      );
                                      value === ""
                                        ? dispatch(asyncGetDefaultOriginAirport())
                                        : dispatch(
                                          asyncGetOriginAirportsName(value)
                                        );
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {flight.from_where !== "" && (
                                          <IconButton
                                            onClick={() => {
                                              setFieldValue(
                                                `multi_des_flights.${index}.from_where`,
                                                ""
                                              );
                                              dispatch(
                                                asyncGetDefaultOriginAirport()
                                              );
                                            }}
                                          >
                                            <MdClose />
                                          </IconButton>
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {multi_dest_drp === `from_where_${index}` ? (
                                  <div className="airport_search_drp">
                                    {originAirportList?.length > 0 ? (
                                      originAirportList?.map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className="airport_search_drp_item"
                                            onClick={() => {
                                              let value = `${item.code}-${item.airport_name}-${item.city}`;
                                              setFieldValue(
                                                `multi_des_flights.${index}.from_where`,
                                                value
                                              );
                                              setMulti_dest_drp(
                                                `to_where_${index}`
                                              );
                                            }}
                                          >
                                            <MdFlightTakeoff className="me-3 fs-3 color_primary" />
                                            <div className="fs-5">
                                              {item.code} - {item.airport_name} -{" "}
                                              {item.city}
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="airport_search_drp_item">
                                        No Record Found.
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              {/* /////////////////// to_where  ///////////////// */}
                              <div className="col-6 col-md-3 relative">
                                <FormControl
                                  variant="standard"
                                  className="w-100"
                                  onClick={() => {
                                    setMulti_dest_drp(`to_where_${index}`);
                                  }}
                                >
                                  <InputLabel
                                    htmlFor={`multi_des_flights.${index}.to_where`}
                                  >
                                    To Where
                                  </InputLabel>
                                  <Input
                                    autoComplete="off"
                                    id={`multi_des_flights.${index}.to_where`}
                                    value={
                                      values.multi_des_flights[index].to_where
                                    }
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setFieldValue(
                                        `multi_des_flights.${index}.to_where`,
                                        value
                                      );
                                      value === ""
                                        ? dispatch(
                                          asyncGetDefaultAirportDestination()
                                        )
                                        : dispatch(
                                          asyncGetDestinationAirportsName(value)
                                        );
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {flight.to_where !== "" && (
                                          <IconButton
                                            onClick={() => {
                                              setFieldValue(
                                                `multi_des_flights.${index}.to_where`,
                                                ""
                                              );
                                              dispatch(
                                                asyncGetDefaultOriginAirport()
                                              );
                                            }}
                                          >
                                            <MdClose />
                                          </IconButton>
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {multi_dest_drp === `to_where_${index}` ? (
                                  <div className="airport_search_drp">
                                    {destinatioAirportList?.length > 0 ? (
                                      destinatioAirportList?.map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className="airport_search_drp_item"
                                            onClick={() => {
                                              let value = `${item?.code}-${item?.airport_name}-${item?.city}`;
                                              setFieldValue(
                                                `multi_des_flights.${index}.to_where`,
                                                value
                                              );
                                              values.multi_des_flights.length >
                                                index + 1 &&
                                                setFieldValue(
                                                  `multi_des_flights.${index + 1
                                                  }.from_where`,
                                                  value
                                                );
                                              setMulti_dest_drp(`date${index}`);
                                              dispatch(
                                                asyncGetDefaultAirportDestination()
                                              );
                                            }}
                                          >
                                            <MdFlightLand className="me-3 fs-3 color_primary" />
                                            <div className="fs-5">
                                              {item?.code} - {item?.airport_name}{" "}
                                              - {item?.city}
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="airport_search_drp_item">
                                        No Record Found.
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>

                              <div className="col-6 col-md-2 mt-4 mt-md-0">
                                <ReactDatePicker
                                  open={multi_dest_drp === `date${index}`}
                                  onFocus={() => {
                                    setMulti_dest_drp(`date${index}`);
                                  }}
                                  onBlur={() => {
                                    setMulti_dest_drp(``);
                                  }}
                                  placeholderText="Departure Date"
                                  monthsShown={2}
                                  onClickOutside={() => setMulti_dest_drp(``)}
                                  selected={
                                    index === 0
                                      ? flight.startDate
                                      : flight.startDate >
                                        values.multi_des_flights[index - 1]
                                          ?.startDate
                                        ? flight.startDate
                                        : values.multi_des_flights[index - 1]
                                          ?.startDate
                                  }
                                  value={flight.startDate}
                                  dateFormat="MMMM d, yyyy"
                                  onChange={(date) => {
                                    arrayHelpers.replace(index, {
                                      ...flight,
                                      startDate: date,
                                    });
                                    setMulti_dest_drp(``);
                                  }}
                                  minDate={
                                    index === 0
                                      ? values.startDate
                                      : values.multi_des_flights[index - 1]
                                        ?.startDate
                                  }
                                  className="date_picker"
                                />
                              </div>
                              <div className="col-6 col-md-3 mt-4 mt-md-0 relative d-flex justify-content-between align-items-end">
                                <div className="col-6">
                                  <TextField
                                    variant="standard"
                                    autoComplete="off"
                                    label={`${values.adult +
                                      values.children +
                                      values.infant
                                      } Travelers, ${values?.multi_des_flights[index]
                                        ?.ticket_class
                                      }`}
                                    className="w-100"
                                    onClick={(e) => {
                                      setMulti_dest_drp(`travel${index}`);
                                    }}
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                  // onBlur={(e) => setMulti_dest_drp("")}
                                  />

                                  {multi_dest_drp === `travel${index}` && (
                                    <div className="travelar_dropdown">
                                      {/* adult */}
                                      <div className="d-flex justify-content-between items-end">
                                        <div className="pt-1">
                                          <h6>ADULT (Above 12)</h6>
                                        </div>
                                        <div>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={values.adult < 2}
                                            onClick={(e) => {
                                              setFieldValue(
                                                "adult",
                                                values.adult - 1
                                              );
                                            }}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {values.adult}
                                          </span>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={
                                              values.adult + values.children > 6
                                            }
                                            onClick={(e) => {
                                              setFieldValue(
                                                "adult",
                                                values.adult + 1
                                              );
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                      <hr />
                                      {/* child */}
                                      <div className="d-flex justify-content-between items-end ">
                                        <div className="pt-1">
                                          <h6>CHILD (2-12)</h6>
                                        </div>
                                        <div>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={values.children < 1}
                                            onClick={(e) => {
                                              setFieldValue(
                                                "children",
                                                values.children - 1
                                              );
                                            }}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {values.children}
                                          </span>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={
                                              values.adult + values.children > 6
                                            }
                                            onClick={(e) => {
                                              setFieldValue(
                                                "children",
                                                values.children + 1
                                              );
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>

                                      <hr />
                                      {/* infant */}
                                      <div className="d-flex justify-content-between items-end ">
                                        <div className="pt-1">
                                          <h6>INFANT (Under 2)</h6>
                                        </div>
                                        <div>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={values.infant < 1}
                                            onClick={(e) => {
                                              setFieldValue(
                                                "infant",
                                                values.infant - 1
                                              );
                                            }}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {values.infant}
                                          </span>
                                          <button
                                            className="button_style py-2 px-3"
                                            type="button"
                                            disabled={
                                              values.infant >= values.adult
                                            }
                                            onClick={(e) => {
                                              setFieldValue(
                                                "infant",
                                                values.infant + 1
                                              );
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                      {/* if values.children is > 0 i want show the below dropdow but if the number is 1 i want to show 1 dropdown and as number increase i want to increase number of dropdown */}
                                      {values.children > 0 && (
                                        <>
                                          <hr />
                                          <div className="">
                                            <h5>Select Children Ages (Years)</h5>
                                            <div className="d-flex flex-wrap gap-3 ">
                                              {values.children > 0 &&
                                                Array.from({
                                                  length: values.children,
                                                }).map((_, index) => (
                                                  <div
                                                    key={index}
                                                    className="my-3"
                                                  >
                                                    <select
                                                      id={`childerenAges${index}`}
                                                      value={
                                                        values.children_ages[
                                                        index
                                                        ]
                                                      }
                                                      onChange={(e) => {
                                                        const newAges = [
                                                          ...values.children_ages,
                                                        ];
                                                        newAges[index] =
                                                          e.target.value;
                                                        setFieldValue(
                                                          "children_ages",
                                                          newAges
                                                        );
                                                      }}
                                                      style={{
                                                        width: "100px",
                                                        backgroundColor: "white",
                                                        border: "none",
                                                        borderBottom:
                                                          "1px solid black",
                                                        outline: "none",
                                                      }}
                                                    >
                                                      {childrenAgeOptions.map(
                                                        (age) => (
                                                          <option
                                                            key={age}
                                                            value={age}
                                                          >
                                                            {age}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <hr />
                                      <div className="row mt-4">
                                        <div className="col-6">
                                          <button
                                            type="button"
                                            className={`w-100 ${values?.multi_des_flights[index]
                                              ?.ticket_class === "Economy"
                                              ? "button_style"
                                              : "ticket_class_button"
                                              }`}
                                            onClick={(e) => {
                                              setFieldValue(
                                                `multi_des_flights.${index}.ticket_class`,
                                                "Economy"
                                              );
                                              setMulti_dest_drp("");
                                            }}
                                          >
                                            Economy
                                          </button>
                                        </div>
                                        <div className="col-6">
                                          <button
                                            type="button"
                                            className={`w-100 ${values?.multi_des_flights[index]
                                              ?.ticket_class === "Premium"
                                              ? "button_style"
                                              : "ticket_class_button"
                                              }`}
                                            onClick={() => {
                                              setFieldValue(
                                                `multi_des_flights[${index}].ticket_class`,
                                                "Premium"
                                              );
                                              setMulti_dest_drp("");
                                            }}
                                          >
                                            Premium
                                          </button>
                                        </div>
                                        <div className="col-6 mt-3">
                                          <button
                                            type="button"
                                            className={`w-100 ${values?.multi_des_flights[index]
                                              ?.ticket_class === "Bussiness"
                                              ? "button_style"
                                              : "ticket_class_button"
                                              }`}
                                            onClick={(e) => {
                                              setFieldValue(
                                                `multi_des_flights[${index}].ticket_class`,
                                                "Bussiness"
                                              );
                                              setMulti_dest_drp("");
                                            }}
                                          >
                                            Business
                                          </button>
                                        </div>
                                        <div className="col-6 mt-3">
                                          <button
                                            type="button"
                                            className={`w-100 ${values?.multi_des_flights[index]
                                              ?.ticket_class === "First"
                                              ? "button_style"
                                              : "ticket_class_button"
                                              }`}
                                            onClick={(e) => {
                                              setFieldValue(
                                                `multi_des_flights[${index}].ticket_class`,
                                                "First"
                                              );
                                              setMulti_dest_drp("");
                                            }}
                                          >
                                            First
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-5 mt-4 mt-md-0">
                                  {index >= 1 && (
                                    <button
                                      className="button_style bg-danger w-100"
                                      onClick={() => arrayHelpers.remove(index)}
                                      type="button"
                                    >
                                      - Flight
                                    </button>
                                  )}
                                  {index === 0 &&
                                    values.multi_des_flights.length < 7 && (
                                      <button
                                        className="button_style w-100"
                                        onClick={() => {
                                          let val =
                                            values.multi_des_flights[
                                            values.multi_des_flights.length - 1
                                            ];
                                          setMulti_dest_drp(
                                            `to_where_${values.multi_des_flights?.length}`
                                          );
                                          arrayHelpers.push({
                                            from_where: val && val?.to_where,
                                            to_where: "",
                                            startDate: val && val?.startDate,
                                            ticket_class:
                                              val && val?.ticket_class,
                                          });
                                        }}
                                        type="button"
                                      >
                                        + Flight
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </ClickAwayListener>
                  )}
                />
              )}

              {/* ////////////////////////// select All gds and pk umrah  //////////////////// */}
              {/* <div className="row py-5">
              <div className="mt-5 col-3 ">
                <FormControl
                  fullWidth
                  variant="standard"
                  className="py-2"
                >
                  <InputLabel id="gds-dropdown-label">Preferred Airline/GDS</InputLabel>
                  <Select
                    labelId="gds-dropdown-label"
                    id="gds-dropdown"
                    name="gds-dropdown"
                    value={values.selected_gds || "all"}
                    onChange={(e) => {
                      const selectedValue = e.target.value;

                      if (selectedValue === "all") {
                        const updatedGds = Object.fromEntries(
                          Object.entries(values.gds).map(([name, value]) => {
                            return [name, { ...value, value: true }];
                          })
                        );
                        setFieldValue("gds", updatedGds);
                        setFieldValue("all_select", true);
                      } else {
                        const updatedGds = Object.fromEntries(
                          Object.entries(values.gds).map(([name, value]) => {
                            return [name, { ...value, value: name === selectedValue }];
                          })
                        );
                        setFieldValue("gds", updatedGds);
                        setFieldValue("all_select", false);
                      }
                      setFieldValue("selected_gds", selectedValue);
                    }}
                  >
                    <MenuItem value="all">
                      <span>Select All</span>
                    </MenuItem>
                    {Object.entries(values.gds)
                      .sort(([, a], [, b]) => a.gds_order - b.gds_order)
                      .filter(([gdsName]) =>
                        isDomesticSector(gdsName, values.from_where, values.to_where) &&
                        isMultiDestination(gdsName, values.trip)
                      )
                      .map(([gdsName]) => {
                        const imageGds = gdsName.replace(" ", "").toUpperCase();
                        const customLabels = {
                          "Fly Jinnah": "FlyJinnah / Air Arabia",
                          Amadeus: "IATA/Amadeus",
                          Sabre: "IATA/Sabre",
                          HITIT: "PIA",
                          "Air sial": "Air Sial"
                        };
                        const displayName = customLabels[gdsName] || gdsName;
                        return (
                          <MenuItem key={gdsName} value={gdsName}>
                            <img
                              src={images[imageGds]}
                              alt={gdsName}
                              style={{
                                width: 25,
                                height: 25,
                                marginRight: "10px",
                              }}
                            />
                            {displayName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <h6 className="text-danger">{sereneMessage}</h6>
              </div>
            </div> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FlightsTab;


{/* <div className="pt-0">
  <div className="d-flex align-items-center py-3">
    <div className="checkbox-rect">
      <input
        type="checkbox"
        id="all_select"
        name="all_select"
        value={values.all_select}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const updatedGds = Object.fromEntries(
            Object.entries(values.gds).map(([name, value]) => {
              return [name, { ...value, value: isChecked }];
            })
          );
          setFieldValue("gds", updatedGds);
          setFieldValue("all_select", isChecked);
        }}
        checked={values.all_select}
      />
      <label htmlFor="all_select">Select All GDS</label>
    </div>
    <div>
      <ErrorMessage
        component={"div"}
        className="text-danger mx-3"
        name="gds"
      />
    </div>
  </div>
  <div className="d-flex align-items-center py-4 pt-5 flex-wrap">
    {Object.entries(values.gds)
      .sort(([, a], [, b]) => a.gds_order - b.gds_order)
      .map(([gdsName, gdsValue], index) => {
        let imageGds = gdsName.replace(" ", "").toUpperCase();
        return (
          <div className="d-flex flex-column">
            {isDomesticSector(
              gdsName,
              values.from_where,
              values.to_where
            ) && isMultiDestination(gdsName, values.trip) ? (
              <div
                className="d-flex checkbox-rect me-5 my-3"
                key={index}
              >
                <input
                  type="checkbox"
                  id={`gds[${index}].gds_name`}
                  name={`gds[${index}].gds_name`}
                  checked={gdsValue.value}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    let gdsObj = {
                      ...values.gds,
                      [gdsName]: {
                        ...gdsValue,
                        value: isChecked,
                      },
                    }
                    // Show Serene-specific message when selected
                    if (gdsName === "Serene" && isChecked) {
                      setSereneMessage(
                        "kindly don’t select multi PAX while booking due to changing of airline system."
                      );
                    } else if (gdsName === "Serene" && !isChecked) {
                      setSereneMessage("");
                    }
                    setFieldValue("gds", gdsObj);
                    if (!isChecked && values.all_select) {
                      setFieldValue("all_select", false);
                    }
                  }}
                />
                <label htmlFor={`gds[${index}].gds_name`}>
                  <img
                    src={images[imageGds]}
                    className="flight-icons"
                    alt="gds"
                    style={{
                      marginTop: "-1rem",
                    }}
                  />
                </label>
              </div>
            ) : (
              <></>
            )}

            Umra fare
            {isUmrahFare(values.from_where, values.to_where) &&
              gdsName === "HITIT" && (
                <div className="me-5 checkbox-rect">
                  <input
                    type="checkbox"
                    id="umrah_fare"
                    name="umrah_fare"
                    disabled={values.all_select}
                    checked={values.umrah_fare}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        const updatedGds = Object.keys(
                          values.gds
                        ).reduce((acc, gdsName) => {
                          acc[gdsName] = {
                            ...values.gds[gdsName],
                            value: gdsName === "HITIT",
                          };
                          return acc;
                        }, {});
                        setFieldValue("gds", updatedGds);
                      } else {
                        const updatedGds = Object.keys(
                          values.gds
                        ).reduce((acc, gdsName) => {
                          acc[gdsName] = {
                            ...values.gds[gdsName],
                            value: false,
                          };
                          return acc;
                        }, {});
                        setFieldValue("gds", updatedGds);
                      }

                      setFieldValue("umrah_fare", isChecked);
                    }}
                  />

                  <label htmlFor="umrah_fare">Umrah Fare</label>
                </div>
              )}
          </div>
        );
      })}
  </div>
  <h6 className="text-danger">{sereneMessage}</h6>
</div> */}
