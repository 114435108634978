import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, NODEURL } from "../ApiConfig";

const initialState = {
  flightrequestsData: [],
  flightgetData: [],
  messages: [], // Add this
  sendmessages:[],
  Count: 0, // Add this
  loading: false,
  error: null,
};

export const asyncCreateFlightRequestsData = createAsyncThunk(
  "asyncCreateFlightRequestsData/post",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/flight-request`, body);
      // return await response?.data?.data;
      
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const asyncGetFlightRequestsData = createAsyncThunk(
  "asyncGetFlightRequestsData/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/flight-request?agent_acc_id=${id}`
      );
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const updateFlightSingleRequest = createAsyncThunk(
  "updateFlightSingleRequest/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/flight-request-single/${id}`,
        body
      );
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const fetchMessagesForAgent = createAsyncThunk(
  "messages/fetchForAgent",
  async ({ id}, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/chats/booking/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ bookingId, userId, agentId, messageText, agentName }, { rejectWithValue }) => {
    const payload = {
      book_id: bookingId,
      user_id: userId,
      agent_id: agentId,
      message_text: messageText,
      ad_ag_name: agentName,
      status: 'unread',
    };
    try {
      const response = await NODEURL.post(`/api/v1/b2b/chats`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchMessagesCount = createAsyncThunk(
  "count/fetchForAgent",
  async ({ c_id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/chats/count/${c_id}`);
      return response.data.count;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const flightrequest = createSlice({
  name: "flightrequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      asyncCreateFlightRequestsData.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncCreateFlightRequestsData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.flightrequestsData = payload;
      }
    );
    builder.addCase(
      asyncCreateFlightRequestsData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // get all data
    builder.addCase(
      asyncGetFlightRequestsData.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetFlightRequestsData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.flightgetData = payload;
      }
    );
    builder.addCase(
      asyncGetFlightRequestsData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
     // Messages Data
     builder.addCase(fetchMessagesForAgent.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMessagesForAgent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.messages = payload;
    });
    builder.addCase(fetchMessagesForAgent.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

     // count Data
     builder.addCase(fetchMessagesCount.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMessagesCount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.Count = payload;
    });
    builder.addCase(fetchMessagesCount.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Send Message
    builder.addCase(sendMessage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendMessage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.sendmessages = payload;
    });
    builder.addCase(sendMessage.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export default flightrequest.reducer;
