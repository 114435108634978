import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../../../Styles/manualInvoices.css";
import Flight from "./Flight/Flight";
import ServiceType from "./ServiceType";
import Insurance from "./Insurance/Insurance";
import Visa from "./Visa/Visa";
import Hotel from "./Hotel/Hotel";
import Transfer from "./Transfer/Transfer";
import Activity from "./Activity/Activity";
import GeneralInvoice from "./GeneralInvoice/GeneralInvoice";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetCurrencyData } from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { asyncGetAgentGroup } from "../../../../redux/features/setupRestPages/agentsSlice";
import { asyncGetVisaType } from "../../../../redux/features/visa/VisaTypeSlice";
import { getAsyncBookingDetails } from "../../../../redux/features/booking/bookingDetailsSlice";
import { useLocation } from "react-router-dom";
import {
  asyncGetNewAgencyReg,
  asyncSearchAgencyData,
} from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetNewSupplier } from "../../../../redux/features/supplier/AddNewSupplier";

function ManualInvoice({ postVoucher, VoucherBooking }) {
  const dispatch = useDispatch();
  // const { VoucherBooking } = useSelector((state) => state?.booking);
  const { searchAgency, newAgencyRegData } = useSelector(
    (state) => state.newAgency
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  const location = useLocation();
  const tkt_voucher_no = location?.state?.tkt_voucher_no;

  const flight_type =
    VoucherBooking?.book_type === "flight-group"
      ? "Flight"
      : VoucherBooking?.book_type?.charAt(0).toUpperCase() +
        VoucherBooking?.book_type.slice(1);

  const [InitValues, setInitValues] = useState({
    service_type: flight_type,
    service_id: 2,
    supplier: VoucherBooking?.supplier_acc_id,
    visa_country:
      VoucherBooking?.VisaBookingModels?.at(0)?.VisaCountryModel?.id,
    agent:
      newAgencyRegData
        ?.filter((agnt) => agnt?.acc_id === VoucherBooking?.agent_acc_id)
        ?.at(0) || "",
    agent_name:
      VoucherBooking?.acc_agents?.agents_comp?.at(0)?.agent_name || "",
    d_No_pnr: VoucherBooking?.FlightBookingModels?.at(0)?.pnr || "",
    inovice_date: VoucherBooking?.id
      ? location?.state?.postVoucher
        ? new Date(VoucherBooking?.voucherData?.approved_date) || new Date()
        : new Date(
            VoucherBooking?.voucherData?.saved_date?.split("/")?.at(0)
          ) || new Date()
      : new Date(),

    remarks: VoucherBooking?.remarks || "",
  });
 
  useEffect(() => {
    if (VoucherBooking?.id) {
      setInitValues({
        service_type: flight_type,
        service_id: 2,
        supplier: VoucherBooking?.supplier_acc_id,
        visa_country:
          VoucherBooking?.VisaBookingModels?.at(0)?.VisaCountryModel?.id,
        agent:
          newAgencyRegData
            ?.filter((agnt) => agnt?.acc_id === VoucherBooking?.agent_acc_id)
            ?.at(0) || "",
        agent_name:
          VoucherBooking?.acc_agents?.agents_comp?.at(0)?.agent_name || "",
        d_No_pnr: VoucherBooking?.FlightBookingModels?.at(0)?.pnr || "",
        inovice_date: VoucherBooking?.id
          ? location?.state?.postVoucher
            ? new Date(VoucherBooking?.voucherData?.approved_date) || new Date()
            : new Date(
                VoucherBooking?.voucherData?.saved_date?.split("/")?.at(0)
              ) || new Date()
          : new Date(),

        remarks: VoucherBooking?.remarks || "",
      });
    } else {
      setInitValues({
        service_type: "Flight",
        service_id: 2,
        supplier: "",
        visa_country: "",
        visa_country: "",
        agent: "",
        agent_name: "",
        d_No_pnr: "",
        inovice_date: new Date(),

        remarks: "",
      });
    }
  }, [VoucherBooking?.id, newAgencyRegData?.length]);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  // bookingDetails;
  const user = JSON.parse(localStorage.getItem("loginUser"));
  // Check if the login user has permission to manual voucher entry
  const manualInvoicePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/manual-invoice-generate"
      )
    : true;
  //uselayout effect
  const [pnrEror, setPnrError] = useState();

  useEffect(() => {
    let id = Number(location?.state?.voucherId);
    let voucher_id = location?.state?.voucher_id;
    if (id) {
      // dispatch(
      //   getAsyncBookingDetails({
      //     id,
      //     tkt_voucher_no,
      //     voucher_id,
      //     type: VoucherBooking?.book_type,
      //   })
      // );
    }
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetVisaType());
  }, [dispatch]);
  const serviceTypeComponents = {
    Flight: (
      <Flight
        initValues={InitValues}
        permission={manualInvoicePermission}
        location={location}
        pnrEror={pnrEror}
        setPnrError={setPnrError}
        VoucherBooking={VoucherBooking}
      />
    ),
    Insurance: (
      <Insurance initValues={InitValues} permission={manualInvoicePermission} />
    ),
    Visa: (
      <Visa
        initValues={InitValues}
        permission={manualInvoicePermission}
        VoucherBooking={VoucherBooking}
      />
    ),
    Hotel: (
      <Hotel initValues={InitValues} permission={manualInvoicePermission} />
    ),
    Transfer: (
      <Transfer initValues={InitValues} permission={manualInvoicePermission} />
    ),
    Activity: (
      <Activity initValues={InitValues} permission={manualInvoicePermission} />
    ),
    // "General Invoice": (
    //   <GeneralInvoice
    //     initValues={InitValues}
    //     permission={manualInvoicePermission}
    //   />
    // ),
  };
  return (
    <div className="finance-container">
      <div className="manual_invoices width_95percent">
        <div className="h4 otawix-heading box_title">Manual Invoice</div>
        {/* VoucherBooking?.id && newAgencyRegData?.length && newSupplier?.length &&  */}
        <div className="px-2 px-md-4">
          {
            <ServiceType
              values={InitValues}
              setInitValues={setInitValues}
              pnrEror={pnrEror}
              setPnrError={setPnrError}
              VoucherBooking={VoucherBooking}
            />
          }
          {serviceTypeComponents[InitValues.service_type] || null}
        </div>
      </div>
    </div>
  );
}

export default ManualInvoice;
