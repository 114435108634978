const domestic_sectors = [
  "AAW",
  "ATG",
  "BDN",
  "WGB",
  "BHV",
  "BNP",
  "CWP",
  "CHB",
  "CJL",
  "DDU",
  "DBA",
  "DEA",
  "DSK",
  "LYP",
  "GIL",
  "GRT",
  "GWD",
  "HDD",
  "ISB",
  "JAG",
  "JIW",
  "KCF",
  "KBH",
  "KHI",
  "KDD",
  "OHT",
  "LHE",
  "LRG",
  "XJM",
  "HRA",
  "MWD",
  "MPD",
  "MJD",
  "MUX",
  "MFG",
  "WNS",
  "NHS",
  "ORW",
  "PJG",
  "PAJ",
  "PSI",
  "PEW",
  "UET",
  "RYK",
  "RAZ",
  "SWN",
  "SDT",
  "BHW",
  "SGI",
  "RZS",
  "SYW",
  "SWV",
  "SKT",
  "SBQ",
  "KDU",
  "SUL",
  "SKZ",
  "TFT",
  "TLB",
  "TUK",
  "WAF",
  "PZH",
];

const jinnah_sectors = ["PEW", "KHI", "ISB", "LHE", "UET", "SHJ"];

export function isAirblueSectors(orig, des) {
  const sectors = [
    "AUH",
    "DXB",
    "ISB",
    "ISL",
    "JED",
    "KHI",
    "LHE",
    "MED",
    "MUX",
    "PEW",
    "RUH",
    "SHJ",
    "KDU",
    "ZRH",
  ];
  //   console.log(((sectors.indexOf(orig) !== -1 || sectors.indexOf(des) !== -1) && (domestic_sectors.indexOf(orig) !== -1 || domestic_sectors.indexOf(des) !== -1)))
  return (
    (sectors.indexOf(orig) !== -1 && domestic_sectors.indexOf(des) !== -1) ||
    (domestic_sectors.indexOf(orig) !== -1 && sectors.indexOf(des) !== -1)
  );
}

export function isSereneSectors(orig, des) {
  const sectors = [
    "KHI",
    "DXB",
    "ISB",
    "LHE",
    "PEW",
    "SHJ",
    "UET",
    "JED",
    "MED",
    "PEK",
    "BJS",
    "PKX"
  ];
  return (
    (sectors.indexOf(orig) !== -1 && domestic_sectors.indexOf(des) !== -1) ||
    (domestic_sectors.indexOf(orig) !== -1 && sectors.indexOf(des) !== -1)
  );
}

export function isSialSectors(orig, des) {
  const sectors = [
    "KHI",
    "ISB",
    "LHE",
    "PEW",
    "KHI",
    "DMM",
    "SKT",
    "UET",
    "SKZ",
    "JED",
    "MCT",
    "MUX",
  ];
  return (
    (sectors.indexOf(orig) !== -1 && domestic_sectors.indexOf(des) !== -1) ||
    (domestic_sectors.indexOf(orig) !== -1 && sectors.indexOf(des) !== -1)
  );
}

export function isHititSectors(orig, des) {
  const sectors = [
    "KBL",
    "DAC",
    "YYZ",
    "PEK",
    "CPH",
    "CDG",
    "NJF",
    "DEL",
    "BOM",
    "MSP",
    "NRT",
    "KUL",
    "OSL",
    "MCT",
    "DOH",
    "DMM",
    "JED",
    "MED",
    "RUH",
    "BCN",
    "BKK",
    "AUH",
    "DXB",
    "SHJ",
    "BHX",
    "LHR",
    "MAN",
  ];
  return (
    (sectors.indexOf(orig) !== -1 && domestic_sectors.indexOf(des) !== -1) ||
    (domestic_sectors.indexOf(orig) !== -1 && sectors.indexOf(des) !== -1)
  );
}

export function isUmrahFare(orig, des) {
  const umrahSectors = [
    "JED",
    "MED",
    "DMM",
    "RUH",
    "ELQ",
    "TIF",
    "AHB",
    "ULH",
    "RAE",
    "BHH",
    "DWD",
    "HAS",
    "URY",
    "GIZ",
    "AJF",
    "EAM",
    "AQI",
    "RUH",
    "SHW",
    "TUU",
    "TIF",
    "WAE",
    "EJH",
    "YNB",
  ];
  return (
    domestic_sectors?.includes(orig?.split("-")[0]) &&
    umrahSectors?.includes(des?.split("-")[0])
  );
}

export function isDomesticSectors(orig, des) {
  return (
    domestic_sectors?.indexOf(orig) !== -1 &&
    domestic_sectors?.indexOf(des) !== -1
  );
}

export function isInternationalSectors(orig, des) {
  return (
    domestic_sectors?.indexOf(orig) === -1 &&
    domestic_sectors?.indexOf(des) === -1
  );
}

export function isFlyjinnahSectors(orig, des) {
  // return jinnah_sectors?.includes(orig) && jinnah_sectors?.includes(des);
  return true;
}

export function getSectorsName(orig, des) {
  let dep = orig?.split("-")[0];
  let arr = des?.split("-")[0];

  if (
    domestic_sectors?.indexOf(dep) !== -1 &&
    domestic_sectors?.indexOf(arr) !== -1
  ) {
    return "dom";
  } else if (
    domestic_sectors?.indexOf(dep) !== -1 &&
    domestic_sectors?.indexOf(arr) === -1
  ) {
    return "siti";
  } else {
    return "soto";
  }
}

export function getFlightType(orig, des) {
  let dep = orig?.split("-")[0];
  let arr =
    des
      ?.filter(
        (de) => !domestic_sectors.includes(de?.destination?.split("-")?.at(0))
      )
      ?.at(0)
      ?.destination?.split("-")
      ?.at(0) ||
    des
      ?.filter((de) =>
        domestic_sectors.includes(de?.destination?.split("-")?.at(0))
      )
      ?.at(0)
      ?.destination?.split("-")
      ?.at(0);

  if (
    domestic_sectors?.indexOf(dep) !== -1 &&
    domestic_sectors?.indexOf(arr) !== -1
  ) {
    return "dom";
  } else if (
    domestic_sectors?.indexOf(dep) !== -1 &&
    domestic_sectors?.indexOf(arr) === -1
  ) {
    return "siti";
  } else {
    return "soto";
  }
}

// export function getFlightType(orig, des) {
//   let dep = orig.split("-")[0];
//   console.log(
//     "des",
//     des?.filter(
//       (de) =>
//         !domestic_sectors.includes(de?.destination?.split("-")?.at(0))
//     )
//   );
//   if (domestic_sectors.indexOf(dep) === -1) {
//     return "soto";
//   } else if (
//     domestic_sectors.indexOf(dep) !== -1 &&
//     des?.filter(
//       (de) =>
//         domestic_sectors.indexOf(de?.destination?.split("-")?.at(0)) !== -1
//     )
//   ) {
//     return "dom";
//   } else {
//     console.log("siti");
//   }
// }
