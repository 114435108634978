import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefundExchange from '../../../Common/SabreRefundExchange';

const SabrePenaltyAccordion = ({ selectedBrandFare, CurrencyCode }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const paxTypeLabels = {
        ADT: "Adult",
        CNN: "Child",
        INF: "Infant",
    };

    return (
        <div>
            {selectedBrandFare?.fare?.passengerInfoList?.map((passenger, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0, fontSize: "18px", fontWeight: "bold", padding: "4px" }}>
                            {`${paxTypeLabels[passenger.passengerInfo.passengerType]} Penalties`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RefundExchange
                            penalties={passenger.passengerInfo.penaltiesInfo.penalties}
                            CurrencyCode={CurrencyCode}
                            passengerType={paxTypeLabels[passenger.passengerInfo.passengerType]}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default SabrePenaltyAccordion;