import React from 'react'

const AirblueMultiFares = ({boundToggle}) => {
  return (
    <div >
        <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <div
          className={`${
            boundToggle === "outBound"
              ? "button_acitve active-box"
              : "button_style"
          } rounded-md p-2 w-50 cursorpointer me-2`}
        //   onClick={() => {
        //     setBoundToggle("outBound");
        //     if (!selectedFlight) {
        //       GetFlyJinnahFaresDetails("", TransactionIdentifier);
        //     }
        //   }}
        >
          <div className="d-flex gap-4">
            <div className="font-medium">
              {/* {`${
                flyjinnahItem?.itenaries?.[0]?.flightSegments?.[0]?.origin
                  ?.airportCode
              } -
              ${
                flyjinnahItem?.itenaries?.[0]?.flightSegments[
                  flyjinnahItem?.itenaries?.[0]?.flightSegments?.length - 1
                ]?.destination?.airportCode
              }`} */}loc1
            </div>
            <div className="font-medium">
            {/* {outformattedDate} */}date
            </div>
          </div>
        </div>

        <div
          className={`${
            boundToggle === "inBound"
              ? "button_acitve active-box text-white"
              : "button_style"
          } rounded-md p-2 w-50 cursorpointer ms-2`}
        //   onClick={() => {
        //     setBoundToggle("inBound");
        //     if (!selectedFlight) {
        //       GetFlyJinnahFaresDetails("", TransactionIdentifier);
        //     }
        //   }}
        >
          <div className="d-flex gap-4">
            <div className="font-medium">
              {/* {`${
                flyjinnahItem?.itenaries?.[1]?.flightSegments?.[0]?.origin
                  ?.airportCode
              } -
              ${
                flyjinnahItem?.itenaries?.[1]?.flightSegments?.[
                  flyjinnahItem?.itenaries?.[1]?.flightSegments?.length - 1
                ]?.destination?.airportCode
              }`} */}
              loc2
            </div>
            <div className="font-medium">
            {/* {informattedDate} */}date2
            </div>
          </div>
        </div>
      </div>
      <div>



        <h1>Fares</h1>



      </div>
    </div>
  )
}

export default AirblueMultiFares