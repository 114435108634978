import React, { useEffect, useState } from "react";
import VisaDetails from "../BookingDetails/VisaComponent/VisaDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAsyncBookingDetails } from "../../redux/features/booking/bookingDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import VisaContact from "../BookingDetails/VisaComponent/VisaContact";
import VisaFare from "../BookingDetails/VisaComponent/VisaFare";
import { asyncGetAgencyPermissions } from "../../redux/features/agency/agencyPermissionsSlice";
import VisaInvoice from "../BookingDetails/VisaComponent/VisaInvoice";
import VisaAttachment from "../BookingDetails/VisaComponent/VisaAttachment";
import ViewVisa from "../BookingDetails/VisaComponent/ViewVisa";

const VisaBookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const booking = useSelector((state) => state?.booking);
  const [active_tab, setActive_Tab] = useState("Details");
  const { searchAgency, newAgencyRegData } = useSelector(
    (state) => state.newAgency
  );
  const { bankDetails } = useSelector((state) => state?.bank_curr_roleSlice);
  const { supplierRulesData } = useSelector(
    (state) => state.price_airlines_suppRoleSlice
  );
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const voidTicketPermission = ![1, 6, 7].includes(user?.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/void_ticket"
      )
    : true;

  const FarePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id && it?.page_level.permission_url === "/fare"
  );
  const { booking_id, type } = useParams();
  const types = { type };

  const bookingData = booking?.booking?.id ? booking?.booking : null;
  const id = location?.state?.id
    ? location?.state?.id
    : location?.state?.upDateflightGroupId || Number(booking_id);
  useEffect(() => {
    dispatch(asyncGetAgencyPermissions());
  }, []);

  useEffect(() => {
    let type = location?.state?.type || types?.type;
    setTimeout(() => {
      dispatch(getAsyncBookingDetails({ id, type }));
    }, 2000);
  }, []);

  const array = [
    {
      title: "Details",
    },
    {
      title: "Contact",
    },
    {
      title: "Rate",
    },

    {
      title: "Invoice",
    },

    {
      title: "Attach Visa",
    },
    {
      title: "View Visa",
    },

    {
      title: "Back",
    },
  ];
  const obj = {
    Details: <VisaDetails bookingData={bookingData} />,
    "Attach Visa": <VisaDetails bookingData={bookingData} />,
    "View Visa": <VisaDetails bookingData={bookingData} />,
    "": <VisaDetails bookingData={bookingData} />,
    Contact: (
      <VisaContact
        user={bookingData?.booked_by}
        agencyData={newAgencyRegData?.find(
          (ag) => ag?.acc_id === bookingData?.agent_acc_id
        )}
      />
    ),
    Rate: (
      <VisaFare
        bookingData={bookingData}
        FarePermission={FarePermission}
        user={user}
      />
    ),
    Invoice: (
      <VisaInvoice
        bookingData={bookingData}
        user={user}
        bankDetails={bankDetails}
        supplierRulesData={supplierRulesData}
      />
    ),
  };
  return (
    <div className="">
      <div className="row d-flex m-5">
        {/* left side //////drop Down */}
        <div className="col-sm-2 rounded ">
          <div className="me-1 bg-white p-3 rounded-3 sticky-sidebar">
            {array?.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  className={`h5 booking-tab ${
                    item?.title === active_tab
                      ? "booking-tab-link "
                      : "bookings_details_links"
                  }`}
                  onClick={() => {
                    setActive_Tab((prevTab) =>
                      prevTab === "Attach Visa" || prevTab === "View Visa"
                        ? prevTab === item?.title
                          ? ""
                          : item?.title
                        : item?.title === active_tab
                        ? ""
                        : item?.title
                    );
                    if (item?.title === "Back") navigate("/booking");
                  }}
                >
                  {item?.title}
                </div>
                {/* //////////////////Attach Visa//////////// */}
                {active_tab === "Attach Visa" &&
                  item?.title === "Attach Visa" && (
                    <div>
                      <VisaAttachment
                        bookingData={bookingData}
                        setActive_Tab={setActive_Tab}
                      />
                    </div>
                  )}
                {/* //////////////////View Visa//////////// */}

                {active_tab === "View Visa" && item?.title === "View Visa" && (
                  <div>{<ViewVisa id={id} type={type} />}</div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* right side  ///////Data*/}
        <div className="col-sm-10 bg-white rounded-3 mb-5">
          {obj[active_tab]}
        </div>
      </div>
    </div>
  );
};

export default VisaBookingDetails;
