import LandingPage from "./pages/LandingPage";
import { Routes, Route } from "react-router-dom";
import Home from "../src/pages/Home";
import Booking from "./pages/Booking";
import BookingDetails from "./pages/BookingDetails";
import Collection from "./pages/Collection";
import Request from "./pages/Request";
import FlightRequest from "./pages/FlightRequest";
import FlightRequestView from "./pages/FlightRequestView";
import Umrah from "./pages/Umrah";
import Groups from "./pages/Groups";
import UmrahDetails from "./pages/UmrahDetails";
import ManualInvoice from "./pages/ManualInvoice";
import AgentAccountStatement from "./pages/AgentAccountStatement";
import DailySaleReport from "./pages/DailySaleReport";
import AgentList from "./pages/AgentList";
import SupplierAccountStatement from "./pages/SupplierAccountStatement";
import SaleReport from "./pages/SaleReport";
import RefundReport from "./pages/RefundReport";
import TransactionalReport from "./pages/TransactionalReport";
import VisaReport from "./pages/VisaReport";
import GroupFlightManifest from "./pages/GroupFlightManifest";
import AirlineReport from "./pages/AirlineReport";
import UmrahMnifestView from "./pages/UmrahManifestView";
import MakePayment from "./pages/MakePayment";
import CorporatePayment from "./pages/CorporatePayment";
import Reciept from "./pages/Reciept";
import AdminUnpaidVoucher from "./pages/AdminUnpaidVoucher";
import AdminPaidVoucher from "./pages/AdminPaidVoucher";
import FlashNews from "./pages/FlashNews";
import Circular from "./pages/Circular";
import Promotions from "./pages/Promotions";
import FlightGroups from "./pages/FlightGroups";
import Countries from "./pages/Countries";
import Supplier from "./pages/Supplier";
import AgentGroups from "./pages/AgentGroups";
import BankDetails from "./pages/BankDetails";
import Currency from "./pages/Currency";
import Airlines from "./pages/Airlines";
import ResolvedRequests from "./pages/ResolvedRequests";
import SupplierRules from "./pages/SupplierRules";
import Agents from "./pages/Agents";
import Pricing from "./pages/Pricing";
import ApiPassword from "./pages/ApiPassword";
import VisaRate from "./pages/VisaRates";
import VisaTerms from "./pages/VisaTerms";

import Hotel from "./pages/Hotel";
import UmrahTransfer from "./pages/UmrahTransfer";
import UmrahVisaSupplier from "./pages/UmrahVisaSupplier";
import UmrahTransferSupplier from "./pages/UmrahTransferSupplier";
import UmrahHotelRates from "./pages/UmrahHotelRates";

import AgencyRegistration from "./pages/AgencyRegistration";

import SaveVoucher from "./components/Finance/Vouchers/AllVoucher/SaveVoucher";
import VoucherEntry from "./components/Finance/Vouchers/VoucherEntry";
import ManualCredit from "./components/Finance/Vouchers/ManualCredit";
import InvoiceLogs from "./components/Finance/Vouchers/InvoiceLogs";

import ChartOfAccount from "./components/Finance/Accounts/ChartOfAccount";
// import GournalLedger from "./components/Finance/Accounts/JournelLedger";
import TrialBalance from "./components/Finance/Accounts/TrialBalance";
import BalanceSheet from "./components/Finance/Accounts/BalanceSheet";
import ProfitLose from "./components/Finance/Accounts/ProfitLose";
import AsseteStatement from "./components/Finance/Accounts/AssestStatement";
import ExpanceStatement from "./components/Finance/Accounts/ExpenseStatement";
import BankCash from "./components/Finance/Accounts/BankBook/BankCash";
import UmrahRoomType from "./pages/UmrahRoomType";
import AgentGroupSubPage from "./components/Setup/AgentGroupSubPage";

import AgencyData from "./pages/FinanceAgency";
import AgencyDocument from "./pages/Document";
import AgencyUsers from "./pages/Users";
import AgencyUserLevel from "./pages/UserLevel";
import AgentPaymentPage from "./pages/AgentPaymentPage";
import Bannerpage from "./pages/Bannerpage";
import AboutUsPage from "./pages/AboutUsPage";
import OurServicesPage from "./pages/OurServicesPage";
import OurPartnerPage from "./pages/OurPartnerPage";
import FooterGalleryPage from "./pages/FooterGalleryPage";
import Protect from "./components/ProtectRoute";
import { useEffect } from "react";
import { asyncGetUserToken } from "./redux/features/userLogin/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import EditAgents from "./components/Setup/AgentsComponents/EditAgents";
import ChequeBookPage from "./pages/CheckBookPage";
import ChequeBookHistory from "./pages/ChequeBookHistoryPage";
import Airblue from "./components/Setup/ApiPassword/Airblue";
import AirSial from "./components/Setup/ApiPassword/AirSial";
import SereneAir from "./components/Setup/ApiPassword/SereneAir";
import FlyDubai from "./components/Setup/ApiPassword/FlyDubai";
import Hitit from "./components/Setup/ApiPassword/Hitit";
import Sabre from "./components/Setup/ApiPassword/Sabre";
import TravelPorts from "./components/Setup/ApiPassword/TravelPort";
import FlyJinanh from "./components/Setup/ApiPassword/FlyJinnah";
import AirArabia from "./components/Setup/ApiPassword/AirArabia";
import OmanNdc from "./components/Setup/ApiPassword/WY";
import EmiratesNdc from "./components/Setup/ApiPassword/Emirates";
import CircularPdf from "./components/Home/CircularPdf";
import SearchResultPage from "./pages/SearchResult";
import FlightGroupDetails from "./components/Setup/Promotions/FlightGroupDetails";
import Checkout from "./pages/Checkout";
import SupplierList from "./pages/SupplierList";
import FlightGroup from "./pages/FlightGroup";
import Layout from "./Layout";
import VisaType from "./pages/VisaType";
import PostVoucher from "./components/Finance/Vouchers/AllVoucher/PostVoucher";
import OpeningBalance from "./components/Finance/Accounts/OpeningBalance";
import Amadeus from "./components/Setup/ApiPassword/Amadeus";
// import JournalLedgerPdf from "./components/Finance/Accounts/JournelLedger/JournalLedgerPdf";
import ManuallcreditNew from "./pages/ManuallcreditNew";
import JournalLedger from "./components/Finance/Accounts/Statements";
import AgentPdf from "./pages/AgentPdf";
import BalanceParent from "./components/Finance/Accounts/BalanceSheet/BalanceParent";
import JournalLedgerTable from "./components/Finance/Accounts/BalanceSheet/journalLedger";
import Chat from "./components/BookingDetails/Chat";


const AppNavigation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetUserToken());
  }, [dispatch]);
  return (
    <div className="">
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/home"
            element={
              // <Protect>
              <Home />
              //  </Protect>
            }
          />
          <Route path="/search-results" element={<SearchResultPage />} />
          <Route path="/booking" element={<Booking />} />
          <Route
            path="/booking-details/:booking_id?/:type?"
            element={<BookingDetails />}
          />
          <Route path="/booking-details/:id/chat" element={<Chat />} />
          <Route
            path="/booking-details/:booking_id/:active_status"
            element={<BookingDetails />}
          />
          <Route path="/collection" element={<Collection />} />
          <Route path="/requests" element={<Request />} />
          <Route path="/flight-requests" element={<FlightRequest />} />
          <Route
            path="/flight-requests/:booking_id"
            element={<FlightRequestView />}
          />
          <Route path="/umrah-requests" element={<Umrah />} />
          <Route path="/flight-group-list" element={<Groups />} />
          <Route
            path="/umrah-requests/:booking_id"
            element={<UmrahDetails />}
          />
          <Route
            path="/manual-invoice/:id?/:tkt?/:vid?/:type?"
            element={<ManualInvoice />}
          />
          <Route
            path="/manual-credit-new/:id?/:tkt?/:vid?"
            element={<ManuallcreditNew />}
          />
          <Route
            path="/agent-account-statement"
            element={<AgentAccountStatement />}
          />
          <Route path="/daily-sales-Report" element={<DailySaleReport />} />
          <Route path="/agent-list" element={<AgentList />} />
          <Route
            path="/supplier-account-statement"
            element={<SupplierAccountStatement />}
          />
          <Route path="/supplier-list" element={<SupplierList />} />
          <Route path="/sales-report" element={<SaleReport />} />
          <Route path="/refund-report" element={<RefundReport />} />
          <Route path="/transaction-report" element={<TransactionalReport />} />
          <Route path="/visa-report" element={<VisaReport />} />
          <Route
            path="/group-flight-manifest"
            element={<GroupFlightManifest />}
          />
          <Route path="/airline-report" element={<AirlineReport />} />
          <Route path="/umrah-manifest-view" element={<UmrahMnifestView />} />
          <Route path="/make-payment" element={<MakePayment />} />
          <Route path="/corporate-payment" element={<CorporatePayment />} />
          <Route path="/agent-payment" element={<AgentPaymentPage />} />
          <Route path="/reciept" element={<Reciept />} />
          <Route
            path="/admin-unpaid-voucher"
            element={<AdminUnpaidVoucher />}
          />
          <Route path="/admin-paid-voucher" element={<AdminPaidVoucher />} />
          <Route path="/flash-news" element={<FlashNews />} />
          <Route path="/circular" element={<Circular />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/flight-groups" element={<FlightGroups />} />
          <Route path="/visa-countries" element={<Countries />} />
          <Route path="/visa-rate" element={<VisaRate />} />
          <Route path="/visa-type" element={<VisaType />} />
          <Route path="/visa-terms" element={<VisaTerms />} />
          <Route path="/umrah-hotels" element={<Hotel />} />
          <Route path="/umrah-tranfer" element={<UmrahTransfer />} />
          <Route path="/umrah-visa-supplier" element={<UmrahVisaSupplier />} />
          <Route
            path="/umrah-transfer-supplier"
            element={<UmrahTransferSupplier />}
          />
          <Route path="/room-type" element={<UmrahRoomType />} />
          <Route path="/hotel-rates" element={<UmrahHotelRates />} />
          <Route
            path="/new-agency-registration"
            element={<AgencyRegistration />}
          />
          <Route path="/check-out" element={<Checkout />} />
          <Route path="/supplier" element={<Supplier />} />
          <Route path="/agent-groups" element={<AgentGroups />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/bank-details" element={<BankDetails />} />
          <Route path="/currency" element={<Currency />} />
          <Route path="/save-voucher" element={<SaveVoucher />} />
          <Route path="/post-voucher" element={<PostVoucher />} />
          <Route path="/vouchers-entry/:id?" element={<VoucherEntry />} />
          <Route path="/manual-credit" element={<ManualCredit />} />
          <Route path="/invoice-logs" element={<InvoiceLogs />} />
          <Route path="/chart-of-account" element={<ChartOfAccount />} />
          <Route path="/journal-ledger" element={<JournalLedger />} />
          {/* <Route
            path="/Journal-Ledger/:accId?/:agencyName?"
            element={<JournalLedgerPdf />}
          /> */}
          <Route path="/trial-balance" element={<TrialBalance />} />
          <Route path="/balance-sheet" element={<BalanceSheet />} />
          <Route path="/accounts/:id" element={<BalanceParent />} />
          <Route path="/journal-Ledger/:id" element={<JournalLedgerTable />} />
          <Route path="/profit-and-lose" element={<ProfitLose />} />
          <Route path="/asset-statement" element={<AsseteStatement />} />
          <Route path="/expance-statement" element={<ExpanceStatement />} />
          <Route path="/opening-balance" element={<OpeningBalance />} />
          <Route path="/bank-cash" element={<BankCash />} />
          <Route path="/add-cheque-book" element={<ChequeBookPage />} />
          <Route path="/cheque-book-history" element={<ChequeBookHistory />} />
          {/* <Route path="/role" element={<Role />} /> */}
          <Route path="/api-airlines" element={<Airlines />} />
          <Route path="/supplier-rules" element={<SupplierRules />} />
          <Route path="/resolved-requests" element={<ResolvedRequests />} />
          <Route path="/api-pricing" element={<Pricing />} />
          <Route path="/api-credentials" element={<ApiPassword />} />
          <Route path="/agent-group-form" element={<AgentGroupSubPage />} />
          <Route path="/circular-pdf" element={<CircularPdf />} />
          <Route path="/flightgroupdetails" element={<FlightGroup />} />
          <Route
            path="/agency-data"
            element={
              <Protect>
                <AgencyData />
              </Protect>
            }
          />
          <Route path="/agency-documents" element={<AgencyDocument />} />
          {/* //////////////////////////////////// */}
          <Route path="/agency-users" element={<AgencyUsers />} />
          <Route path="/agency-user-level" element={<AgencyUserLevel />} />
          <Route path="/agency-banner" element={<Bannerpage />} />
          <Route path="/agency-aboutus" element={<AboutUsPage />} />
          <Route path="/agency-ourservies" element={<OurServicesPage />} />
          <Route path="/agency-ourpartner" element={<OurPartnerPage />} />
          <Route path="/agency-ourgallery" element={<FooterGalleryPage />} />
          <Route path="/edit-agency/:id" element={<EditAgents />} />
          {/* // api Credentials */}
          <Route path="/airblue-credentials" element={<Airblue />} />
          <Route path="/airsial-credentials" element={<AirSial />} />
          <Route path="/flydubai-credentials" element={<FlyDubai />} />
          <Route path="/serene-credentials" element={<SereneAir />} />
          <Route path="/hitit-credentials" element={<Hitit />} />
          <Route path="/amadeus-credentials" element={<Amadeus />} />
          <Route path="/sabre-credentials" element={<Sabre />} />
          <Route path="/travelport-credentials" element={<TravelPorts />} />
          <Route path="/emiratesndc-credentials" element={<EmiratesNdc />} />
          <Route path="/flyjinnah-credentials" element={<FlyJinanh />} />
          <Route path="/airarabia-credentials" element={<AirArabia />} />
          <Route path="/omanndc-credentials" element={<OmanNdc />} />
          <Route path="/agentPdf" element={<AgentPdf />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default AppNavigation;
