import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncRequestsGetData } from "../../redux/features/requests/requestsSlice";
import {
  asyncGetFlightRequestsData,
  asyncupdateFlightRequestData,
  fetchMessagesForAgent,
  updateFlightSingleRequest,
} from "../../redux/features/requests/flightrequest";
import { asyncGetAllUserData } from "../../redux/features/agency/usersSlice";
import { Hidden } from "@mui/material";

import moment from "moment";
import { getAsyncBookingDetails } from "../../redux/features/booking/bookingDetailsSlice";
import "./table.css";
import { asyncGetAllUserPermission } from "../../redux/features/agency/rolesPermissionSlice";

const Table = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { messages } = useSelector((state) => state?.flightrequest);
  // const { userPermission } = useSelector((state) => state?.roleAndPermissions);

  const user = JSON.parse(localStorage.getItem("loginUser"));

  const agentNamee = user?.agents_comp?.agent_name;
  const id = user?.agent_id;
  useEffect(() => {
    dispatch(fetchMessagesForAgent({ id }));
    dispatch(asyncGetAllUserPermission());
  }, []);

  const columns = [
    {
      name: "msg_date_time",
      label: "Date/Time",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th style={{ width: "25%", paddingLeft: "10px" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const date = new Date(value).toLocaleDateString();
          const time = moment(value).format("HH:MM");
          return <p className="custom-cell">{`${date} | ${time}`}</p>;
        },
      },
    },
    {
      name: "book_id",
      label: "Agent Name | User Name",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th style={{ width: "25%" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const ag_name = tableMeta?.tableData?.[rowIndex]?.ad_ag_name;
          // const agentName = `${agentNamee} | ${ag_name}`;
          const agentName = `${ag_name} | ${user.f_name} ${user.l_name}`;
          return <p style={{ color: "#457a7c" }}>{agentName}</p>;
        },
      },
    },
    {
      name: "book_id",
      label: "Booking Id",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th style={{ width: "25%" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ color: "#457a7c" }}>{value}</p>;
        },
      },
    },
    {
      name: "message_text",
      label: "Message",
      options: {
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => (
          <th style={{ width: "25%" }}>{columnMeta.label}</th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <p>{value}</p> : <p>No message</p>;
        },
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "time",
      direction: "desc",
    },
    rowsPerPage: 100,
    filter: false,
    selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    onRowClick: async (rowData) => {
      const id = rowData?.[2]?.props?.children;
      if (id) {
        try {
          dispatch(getAsyncBookingDetails({ id, type: "flight" }));
          navigate(`/booking-details/${id}/chat`);
        } catch (error) {
          console.error("Error navigating to chat page:", error);
        }
      } else {
        console.error("book_id not found in rowData");
      }
    },
    search: false,
  };

  return (
    <div className="py-1 px-2 mt-5">
      <MUIDataTable
        className="muidatatable"
        title={
          <span
            style={{
              paddingLeft: "1px",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Messages Table
          </span>
        }
        data={messages}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Table;
