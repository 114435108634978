import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";
import { fetchBookingUpdate } from "../booking/updateSlice";

const initialState = {
  agentPnr: null,
  isLoading: false,
  error: null,
};

export const asyncPostAgentPnr = createAsyncThunk(
  "asyncPostAgentPnr/post",
  async ({ agentPnr, navigate,manual }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/agentPnr", agentPnr);
     const updateRes= await dispatch(
        fetchBookingUpdate({ gds: agentPnr?.gds, id: response.data?.book_id, type: "import" })
      ); 
      if(!manual ){
        
        if (updateRes?.payload?.status === "error") {
          return;
        }
        setTimeout(() => {
          navigate(`/booking-details/${response.data?.book_id}`, {
            state: {
              type: "flight",
            },
          });
        }, 1500);
      }
      return  updateRes;
      // return await updateRes
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const agentPnr = createSlice({
  name: "agentPnr",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncPostAgentPnr.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAgentPnr.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.agentPnr = payload;
    });
    builder.addCase(asyncPostAgentPnr.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
  },
});

export default agentPnr.reducer;
