import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";
import { asyncEditAgentsData } from "../setupRestPages/agentsSlice";
import { asynGetAllAgencyUser } from "../setupFeature/NewRegistration/NewRegistrationSlice";

const initialState = {
  // agency data
  allUsersData: [],
  usersStateData: [],
  editUserData: null,
  AgencyUsers: [],

  loading: false,
  error: null,
};
// get allUserData
export const asyncGetAllUserData = createAsyncThunk(
  "asyncGetAllUserData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/agent-user");
      return response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// get all agency UserData these the users of a logged in agency admin
export const asyncGetAllAgencyUsers = createAsyncThunk(
  "asyncGetAllAgencyUsers/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/agency-users");
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
//get dropdown data
export const asyncGetUserData = createAsyncThunk(
  "asyncGetUserData/get",
  async (_, { rejectWithValue }) => {
    try {
      // const response = await NODEURL.get("/api/v1/b2b/user/adduser")
      // // console.log(response?.data?.data)
      // return response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// post data
export const asyncPostUserData = createAsyncThunk(
  "asyncPostUserData/post",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/user/agent-user", obj);
      dispatch(asynGetAllAgencyUser());
      return await response.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// edit data
export const asyncEditUserData = createAsyncThunk(
  "asyncEditUserData/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/user/single-user/${id}`);
      console.log(response.data);
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// update data
export const asyncUpdateUserData = createAsyncThunk(
  "asyncUpdateUserData/update",
  async ({ id, obj }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/user/agent-user/${id}`,
        obj
      );
      // dispatch(asyncGetAllUserData());
      dispatch(asyncEditAgentsData(id));
      return await response?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// edit data
export const asyncDeletUserData = createAsyncThunk(
  "asyncDeletUserData/delete",
  async (rowId, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/user/agent-user/${rowId}`
      );
      dispatch(asyncGetAllUserData());
      // return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    nullEditValue: (state) => {
      state.editUserData = null;
    },
  },
  extraReducers: (builder) => {
    // get all user data
    builder.addCase(asyncGetAllUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAllUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.allUsersData = payload;
    });
    builder.addCase(asyncGetAllUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get all agency user data
    builder.addCase(asyncGetAllAgencyUsers.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAllAgencyUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.AgencyUsers = payload;
    });
    builder.addCase(asyncGetAllAgencyUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get data
    builder.addCase(asyncGetUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.usersStateData = payload;
    });
    builder.addCase(asyncGetUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // post data
    builder.addCase(asyncPostUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit data
    builder.addCase(asyncEditUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editUserData = payload;
    });
    builder.addCase(asyncEditUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // update data
    builder.addCase(asyncUpdateUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // delete data
    builder.addCase(asyncDeletUserData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeletUserData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeletUserData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { nullEditValue } = usersSlice.actions;

export default usersSlice.reducer;
