import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";
import { NODEURL } from "../../../../../redux/features/ApiConfig";

const AirblueMSegments = ({ inde, details, flightSegment,timeDurations }) => {

    const [departureInfo, setDepartureInfo] = useState("");
    const [arrivalInfo, setArrivalInfo] = useState("");
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const arrivalDateTime = new Date(flightSegment?.$?.ArrivalDateTime);
  const departureDateTime = new Date(flightSegment?.$?.DepartureDateTime);
  const durationInMilliseconds = arrivalDateTime - departureDateTime;
  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;
  const flight =
    flightSegment?.AirItinerary?.OriginDestinationOptions
      ?.OriginDestinationOption?.FlightSegment;


      
      const getAirportInfo = async (airportCode) => {
        try {
          const { data } = await NODEURL.get(
            `/api/v1/b2b/airport-list/${airportCode}`
          );
          const { code, city, airport_name } = data?.data;
          return `${code} - ${city}`;
        } catch (error) {
          console.error("Error fetching airport info:", error);
          return "Unknown Airport";
        }
      };
    
      useEffect(() => {
        const fetchAirportDetails = async () => {
          if (flight?.DepartureAirport?.$?.LocationCode) {
            const depInfo = await getAirportInfo(flight.DepartureAirport.$?.LocationCode);
            setDepartureInfo(depInfo);
          }
          if (flight?.ArrivalAirport?.$?.LocationCode) {
            const arrInfo = await getAirportInfo(flight.ArrivalAirport.$?.LocationCode);
            setArrivalInfo(arrInfo);
          }
        };
    
        fetchAirportDetails();
      }, [flight]);
  console.log("flightSegment1", flight);
  return (
    <div
      className={`d-flex align-items-center py-4 ${
        details ? "" : "border-bottom"
      } `}
    >
      <div className="col-2">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={"/NEW_LOGO/PA.png"} alt="airblue" width={90} height={90} />
          <div className="mt-2">PA {flight?.$?.FlightNumber}</div>
        </div>
      </div>

      <div className="col-10">
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(
                  new Date(flight?.$?.DepartureDateTime?.split("T")[0])
                ).format("ddd DD-MMM-YY")}
              </h6>
              <h6 className="my-3">
                {flight?.$?.DepartureDateTime?.split("T")[1]?.slice(0, 5)}
              </h6>
              <h6>
                <h6>{departureInfo}</h6>
              </h6>
            </div>
          </div>
          <div>
            <h6 className="text-center">
              {timeDurations(flight?.$?.DepartureDateTime, flight?.$?.ArrivalDateTime)}
            </h6>

            <div className="border_rounded mb-2" />
            <h6 className="mx-3">Non-Stop</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(
                  new Date(flight?.$?.ArrivalDateTime?.split("T")[0])
                ).format("ddd DD-MMM-YY")}
              </h6>
              <h6 className="my-3">
                {flight?.$?.ArrivalDateTime?.split("T")[1]?.slice(0, 5)}
              </h6>
              <h6>
                <h6>{arrivalInfo}</h6>
                {/* {segment === "outbound"
                            ? `${locationArrival?.split("-")[0]} - ${locationArrival?.split("-")[2].toUpperCase()}`
                            : `${locDeparture?.split("-")[0]} - ${locDeparture?.split("-")[2].toUpperCase()}`} */}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirblueMSegments;
