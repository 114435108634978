import React, { useEffect, useState } from "react";

import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";

import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetNewSupplier } from "../../../redux/features/supplier/AddNewSupplier";
import BtnLoader from "../../AppForm/BtnLoader";
import { red } from "@mui/material/colors";
const AgentPNR = ({ values, setFieldValue, errors, touched }) => {
  const { GDSData, loading } = useSelector((state) => state?.apiCredentials);
  const { isLoading, agentPnr, error } = useSelector((state) => state.agentPnr);
  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { searchAgency } = useSelector((state) => state.newAgency);
  const { newSupplier } = useSelector((state) => state.supplier);
  const { data } = useSelector((state) => state.update);

  const [selectedSupplier, setSelectedSupplier] = useState(null);
  useEffect(() => {
    dispatch(asyncGetNewSupplier());
  }, []);
  const filerSupplier = newSupplier.filter((flight) => flight.cat_id === 2);
  console.log("data", data);
  return (
    <>
      <Box display={"flex"} gap={2} sx={{ marginTop: 11, marginBottom: 22 }}>
        <Box flex={1}>
          <TextField
            variant="standard"
            label="PNR"
            fullWidth
            name="pnr"
            value={values?.pnr}
            onChange={(e) => setFieldValue("pnr", e.target.value)}
          />
          {/* Show validation message for PNR */}
          {errors.pnr && touched.pnr && (
            <div className="text-danger mt-1">{errors.pnr}</div>
          )}
        </Box>
        {/* <Box flex={1}>
        <TextField
          variant="standard"
          label="Last Name"
          fullWidth
          name="l_name"
          value={values?.l_name}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue("l_name", value.toUpperCase());
          }}
        />
      </Box> */}

        <Box flex={1}>
          <FormControl variant="standard" className="w-100">
            <InputLabel id="demo-simple-select-standard-label">
              Select GDS
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name={"ispnr"}
              value={values?.gds}
              onChange={(e) => setFieldValue("gds", e.target.value)}
              label="Select GDS"
            >
              {GDSData.map((gds) => (
                <MenuItem
                  value={gds?.gds_name}
                  sx={{ m: 1, bgcolor: "#fff" }}
                  key={gds?.id}
                >
                  {gds?.gds_name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.gds && touched.gds && (
            <div className="text-danger mt-1">{errors.gds}</div>
          )}
        </Box>

        <Box flex={1}>
          <FormControl variant="standard" className="w-100">
            <InputLabel id="select-supplier-name-label">
              Select Supplier
            </InputLabel>
            <Select
              labelId="select-agent-name-label"
              id="select-supplier-name"
              name="supplier"
              value={values?.supplier?.supp_name || ""}
              onChange={(e) => {
                console.log();
                const selectedSupplier = filerSupplier?.find(
                  (supp) => supp.supp_name === e.target.value
                );
                setFieldValue("supplier", selectedSupplier);
              }}
              label="Select Supplier"
            >
              {filerSupplier?.map((supplier) => (
                <MenuItem value={supplier?.supp_name} key={supplier.id}>
                  {supplier.supp_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors?.supplier && touched?.supplier && (
            <div className="text-danger mt-1">
              {errors?.supplier?.supp_name}
            </div>
          )}
        </Box>

        <Box flex={1}>
          <FormControl
            variant="standard"
            className="w-100"
            onClick={() => setagentDRopDwn(!agentDRopDwn)}
          >
            <InputLabel htmlFor="supplier">Select Agent</InputLabel>
            <Input
              variant="standard"
              label="Select Agent"
              autoComplete="off"
              className="w-100"
              name="agent"
              value={values?.select_agent?.agent_name}
              onChange={(e) => {
                let value = e.target.value;
                setFieldValue("select_agent", value);
                dispatch(asyncSearchAgencyData(value));
              }}
              endAdornment={
                <InputAdornment position="end">
                  {values?.select_agent !== "" && (
                    <IconButton
                      onClick={() => {
                        setFieldValue("select_agent", "");
                      }}
                    >
                      <MdClose />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>

          {agentDRopDwn ? (
            <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
              <div className="agent_dropdown">
                {searchAgency?.length > 0 ? (
                  searchAgency?.map((item, index) => {
                    return (
                      <div
                        className="p-3"
                        onClick={(e) => {
                          setFieldValue("select_agent", item);
                          setagentDRopDwn(false);
                        }}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        {item.agent_name}
                      </div>
                    );
                  })
                ) : (
                  <div className="p-3">No record available.</div>
                )}
              </div>
            </ClickAwayListener>
          ) : null}
          {errors?.select_agent && touched?.select_agent && (
            <div className="text-danger mt-1">
              {errors?.select_agent?.agent_name}
            </div>
          )}
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <button className="setup-btn" type="submit">
            {isLoading ? <BtnLoader /> : "Retrieve"}
          </button>
          {data?.status === "error" && (
            <p className="text-danger mt-2" style={{ textAlign: "center" }}>
              {data?.message}
            </p>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AgentPNR;
