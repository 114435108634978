import moment from "moment";
import React, { useState } from "react";
import AirBlueLogo from "../../../../../assets/images/NEW_LOGO/PA.png";
import { HiEye } from "react-icons/hi";
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AirblueMSegments from "./AirblueMSegments";
import ConnectedFlightAirblue from "./ConnectedFlightAirblue";
import AirblueMultiFares from "./AirblueMultiFares";

const AirblueMulti = ({
  data,
  index,
  isExpanded,
  toggleDetail,
  selectedFare,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const [boundToggle, setBoundToggle] = useState("outBound");
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const seg = data;
  const sortedData = seg.reverse();
  console.log("Sorted Data:", sortedData);

  console.log("ddddd", sortedData);
  // data=data?.sort(
  //   (a, b) =>
  //     a?.$?.OriginDestinationRefNumber -
  //     b?.$?.OriginDestinationRefNumber
  // );
  const { selectedFlight } = useSelector((state) => state.FlightSearch);
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PA"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PA");

  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;
  const flightSegment =
    sortedData?.[0]?.AirItinerary?.OriginDestinationOptions
      ?.OriginDestinationOption?.FlightSegment;
  console.log("dataM", sortedData);
  console.log("searchValues", searchValues);

  /// calculate flight duration
  const arrivalDateTime = new Date(flightSegment?.$?.ArrivalDateTime);
  const departureDateTime = new Date(flightSegment?.$?.DepartureDateTime);
  const durationInMilliseconds = arrivalDateTime - departureDateTime;
  // Convert the duration to hours and minutes
  const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
  const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
  // Format hours and minutes with leading zeros
  const flightDuration = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  const timeDurations = (depTime, arrTime) => {
    const departureDateTime = new Date(depTime);
    const arrivalDateTime = new Date(arrTime);
    const durationInMilliseconds = arrivalDateTime - departureDateTime; // Calculate the correct duration

    // Convert the duration to hours and minutes
    const hours = Math.floor(durationInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds

    // Format hours and minutes with leading zeros if necessary
    const flightDuration = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return flightDuration;
  };

  return (
    <div
      className="search_engin_result_box flight_card_shadow bg-white"
      id={`airBlue${index}`}
    >
      <div
        className="shadow result_box_body cursorpointer"
        onClick={() => {
          toggleDetail(index);
          setTimeout(() => {
            !isExpanded && scrollToElement(`airBlue${index}`);
          }, 200);
        }}
      >
        <div
          className="shadow cursorpointer d-flex justify-content-center align-items-center"
          onClick={() => toggleDetail(index)}
        >
          <div className="col-9">
            {sortedData &&
              sortedData.map((item, inde) => (
                <AirblueMSegments
                  inde={inde}
                  flightSegment={item}
                  timeDurations={timeDurations}
                />
              ))}
          </div>
          <div className="col-3 left-shadow text-center align-self-stretch pt-5">
            <div
              className={`d-flex flex-column align-items-center justify-content-center result_box_body pt-4`}
            >
              <>
                <div className="d-flex align-items-center gap-3">
                  <div className="h4">
                    <h6 className=" text-danger">
                      discount
                      {/* {discountTotalFare(selectedFlight ? selectedFlight?.outbound : boundToggle === "outbound" ? outbound1stfare : returnFlightArray, "forCheckout")} */}
                    </h6>
                    {/* {CurrencyCode()}{" "} */}pri
                    {/* {
                        lowestTotalFare(selectedFlight ? selectedFlight?.outbound : boundToggle === "outbound" ? outbound1stfare : returnFlightArray, "forCheckout")
                      } */}
                  </div>
                  <img
                    src={"/NEW_LOGO/PA.png"}
                    alt=""
                    width={40}
                    height={40}
                    className="mb-2"
                  />
                </div>
                <button
                  onClick={(e) => {
                    toggleDetail(index);
                  }}
                  className="button_style w-75 mb-2 cursorpointer mt-2"
                  type="button"
                >
                  <div>
                    <MenuOpenIcon className="me-1" />
                    Flight Detail
                  </div>
                </button>
              </>
            </div>
          </div>
        </div>
      </div>

      {isExpanded ? (
        <>
          <div className="p-3">
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            <div>
              {selectedTab === "Flight Details" && (
                <div className="row rounded-2 search_engin_result_box bg-white p-4">
                  <div
                    key={index}
                    className={`${index === 0 ? "border-bottom pb-4" : "pt-4"}`}
                  >
                    {sortedData &&
                      sortedData.map((item, inde) => (
                        <AirblueMSegments
                          inde={inde}
                          flightSegment={item}
                          timeDurations={timeDurations}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>

            <div>{selectedTab === "Fare Options" && <div><AirblueMultiFares boundToggle={boundToggle}/></div>}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AirblueMulti;
