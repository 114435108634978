import React, { useEffect, useState } from "react";
import moment from "moment";
import { BsBagCheck, BsHandbag } from "react-icons/bs";
import { IoIosStarOutline } from "react-icons/io";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "../OneWaySearchResult/LightTooltip";
import {
  asyncSabreRevalidate,
  setExpandFlightDetails,
  setReturnFlight,
  setShowMoreOptions,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import SabreLogo from "../../../../assets/images/airlineLogo/SB.png";
import {
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
  MdFlight,
  MdRemoveRedEye,
  MdOutlineRestaurant,
} from "react-icons/md";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AirlineSeatLegroomNormalIcon from "@mui/icons-material/AirlineSeatLegroomNormal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  ExclusiveSetors,
  codeShareSabre,
} from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import { FaChevronCircleLeft, FaChevronCircleRight, FaRegStar, FaSuitcase, FaSuitcaseRolling } from "react-icons/fa";
import Slider from "react-slick/lib/slider";
import { pricingairline } from "../../../../Utils/airlinePricing";
import SabrePenaltyAccordion from "../ReturnSearchResult/SabrePenaltyAccordion";
import { CgMenuGridR } from "react-icons/cg";
import { Box } from "@mui/material";

/////////////// sabre card /////////////
function SabreCard({ data, index, isExpanded, toggleDetail, selectedFare, sabre, setShowOtherOption, showOtherOption, checkoutfare }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Fare options");
  const [selectedBrandFare, setSelectedBrandFare] = useState(data?.pricingInformation?.at(0));

  const [showError, setShowError] = useState(false);
  const { sabreBrandFeatures } = useSelector((state) => state.FlightSearch);

  // get state from redux state
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { selectedFlight } = useSelector(
    (state) => state.FlightSearch
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  const sabreSupplier = newSupplier?.find((it) => it?.supp_name === "IATA BSP");
  const { user } = useSelector((state) => state.user);
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  // flight segment
  const fligthSegment = data?.legs;

  const segmentLength = fligthSegment?.flatMap(({ schedules }) => schedules);

  // airline pricing
  const pricing = airlinePricing?.find((item) => {
    return (
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha &&
      item?.add_airline?.code_alpha === fligthSegment?.at(0)?.schedules?.at(0)?.carrier?.marketing
    );
  });

  const airline = addAirLinesData?.find((it) => {
    return it.code_alpha === fligthSegment?.at(0)?.schedules?.at(0)?.carrier?.marketing;
  });

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const calculatePassengerTotal = (fare, totalPax) => {
    const baseFare = CurrencyExchange(Number(Number(fare?.passengerTotalFare?.totalFare) - Number(fare?.passengerTotalFare?.totalTaxAmount)));
    const totalTaxes = CurrencyExchange(Number(fare?.passengerTotalFare?.totalTaxAmount));

    const price = pricingairline(
      airline,
      pricing,
      baseFare,
      totalTaxes,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.fareComponents?.at(0)?.segments?.at(0)?.segment?.bookingCode
      ),
      codeShareSabre(segmentLength),
      // fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      "ECONOMY",
      segmentLength?.length,
      fare?.passengerType !== "INF"
    );
    return price?.total_fare * totalPax;
  };

  const totalFarePrice = (paxesPrices) => {
    const total = paxesPrices.reduce((sum, pax) => {
      return sum + (pax?.passengerInfo ? calculatePassengerTotal(pax.passengerInfo, pax.passengerInfo?.passengerNumber) : 0);
    }, 0);

    return TwoDecimalPoint(total);
  };


  const calculatePassengerDiscountTotal = (fare, totalPax) => {
    const baseFare = CurrencyExchange(Number(Number(fare?.passengerTotalFare?.totalFare) - Number(fare?.passengerTotalFare?.totalTaxAmount)));
    const totalTaxes = CurrencyExchange(Number(fare?.passengerTotalFare?.totalTaxAmount));

    const price = pricingairline(
      airline,
      pricing,
      baseFare,
      totalTaxes,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.fareComponents?.at(0)?.segments?.at(0)?.segment?.bookingCode
      ),
      codeShareSabre(segmentLength),
      // fare?.fareDetailsBySegment[0]?.cabin === "ECONOMY",
      "ECONOMY",
      segmentLength?.length,
      fare?.passengerType !== "INF"
    );
    return price?.sp * totalPax;
  };

  const totalDiscountFarePrice = (paxesPrices) => {
    const total = paxesPrices.reduce((sum, pax) => {
      return sum + (pax?.passengerInfo ? calculatePassengerDiscountTotal(pax.passengerInfo, pax.passengerInfo?.passengerNumber) : 0);
    }, 0);

    if (total > 0) {
      return `OFF ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }

  };

  const findPrices = (paxItem, paxType) => {
    const pax = paxItem?.find((paxItem) => paxItem?.passengerInfo?.passengerType === paxType)
    return {
      total: pax?.passengerInfo?.passengerTotalFare?.totalFare, basePrice: pax?.passengerInfo?.passengerTotalFare?.equivalentAmount, taxes: pax?.passengerInfo?.passengerTotalFare?.totalTaxAmount,
      paxesBreakUP: pax?.passengerInfo?.taxes
    }
  }


  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  /////////////////// scroll to show detail
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  /// convert elapsed time to hours, minutes
  const elapsedTime = (time) => {
    const duration = moment.duration(time, "minutes");
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  function calculateTotalTimeDifference(time1, time2) {
    const moment1 = moment(time1, "HH:mm:ssZ");
    const moment2 = moment(time2, "HH:mm:ssZ");
    const timeDifference = Math.abs(moment1 - moment2);
    const duration = moment.duration(timeDifference);
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return (
    <div key={index}>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sabre${index}`}
      >
        <div
          className="shadow cursorpointer d-flex justify-content-center align-items-center"
          onClick={() => {
            toggleDetail(index);
          }}
        >
          <div className="col-9">
            <div
              className="d-flex py-4"
            >
              <div className="col-2">
                <div className="text-center">
                  <img
                    src={`/NEW_LOGO/${fligthSegment?.at(0)?.schedules?.at(0)?.carrier?.marketing}.png`}
                    alt="logo"
                    width={90}
                    height={90}
                  />
                  <div className="pt-3">
                    {fligthSegment?.at(0)?.schedules?.map((code, index, arr) => (
                      <span className="h6">
                        {`${code?.carrier?.marketing} ${code?.carrier?.marketingFlightNumber} ${index < arr.length - 1 ? " - " : ""}`}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-10">
                <div className="d-flex justify-content-evenly align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <MdOutlineFlightTakeoff
                        size={30}
                        className="color_primary"
                      />
                    </div>
                    <div>
                      <h6>
                        {moment(fligthSegment?.at(0)?.schedules?.at(0)?.travdate).format(
                          "ddd DD-MMM-YY"
                        )}
                      </h6>
                      <h6 className="my-3">
                        {moment(fligthSegment?.at(0)?.schedules?.at(0)?.departure?.time, "HH:mm:ssZ").format("HH:mm")}
                      </h6>
                      <h6>
                        {fligthSegment?.at(0)?.schedules?.at(0)?.departure?.airport} -{" "}
                        {selectedFlight
                          ? getCity(locArrival)
                          : getCity(locDeparture)}
                      </h6>
                    </div>
                  </div>

                  <div>
                    <h6 className="my-3 text-center">
                      {calculateTotalTimeDifference(
                        fligthSegment?.at(0)?.schedules?.at(0)?.departure?.time,  // First departure time
                        fligthSegment?.at(0)?.schedules?.at(fligthSegment?.at(0)?.schedules?.length - 1)?.arrival?.time // Last arrival time
                      )}
                    </h6>
                    <div className="border_rounded mb-2" />
                    <h6 className="mx-3">
                      {fligthSegment?.at(0)?.schedules?.length === 1
                        ? "Non Stop"
                        : fligthSegment?.at(0)?.schedules?.length === 2
                          ? "One Stop" :
                          fligthSegment?.at(0)?.schedules?.length === 3
                            ? "Two-Stop" : "Three-Stop"}
                    </h6>

                    {fligthSegment?.at(0)?.schedules?.length > 1 && (
                      <h6 className="text-center">
                        {fligthSegment?.at(0)?.schedules
                          ?.slice(1)
                          ?.map((seg) => seg?.departure?.airport)
                          ?.join(", ")}
                      </h6>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <MdOutlineFlightLand
                        size={30}
                        className="color_primary"
                      />
                    </div>
                    <div>
                      <h6>
                        {moment(fligthSegment?.at(0)?.schedules?.at(0)?.travdate).format(
                          "ddd DD-MMM-YY"
                        )}
                      </h6>
                      <h6 className="my-3">
                        {moment(fligthSegment?.at(0)?.schedules?.at(fligthSegment?.at(0)?.schedules?.length - 1)?.arrival?.time, "HH:mm:ssZ").format("HH:mm")}
                      </h6>
                      <h6>
                        {fligthSegment?.at(0)?.schedules?.at(fligthSegment?.at(0)?.schedules.length - 1)?.arrival?.airport} - {" "}
                        {selectedFlight
                          ? getCity(locDeparture)
                          : getCity(locArrival)}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* price  */}
          <div className="col-3 left-shadow text-center align-self-stretch py-2">
            <div
              className={`d-flex flex-column align-items-center justify-content-center result_box_body`}
            >
              <div>
                <h6 className=" text-danger">{totalDiscountFarePrice(checkoutfare ? checkoutfare?.fare?.passengerInfoList : data?.pricingInformation?.at(0)?.fare?.passengerInfoList)}</h6>
              </div>
              <div className="d-flex align-items-center">
                <h4>{CurrencyCode()} {totalFarePrice(checkoutfare ? checkoutfare?.fare?.passengerInfoList : data?.pricingInformation?.at(0)?.fare?.passengerInfoList)}</h4>
                <img src={SabreLogo} alt="" width={35} height={35} className="mb-2 ms-2" />
              </div>
              <button
                onClick={() => {
                  toggleDetail(index);
                }}
                className="button_style w-75 mb-2 cursorpointer"
                type="button"
              >
                <div>
                  <MenuOpenIcon className="me-1" />
                  Flight Detail
                </div>
              </button>

              {
                !checkoutfare && (
                  data?.otherOptions?.length > 0 && !selectedFare && (
                    <button
                      className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setExpandFlightDetails(null));
                        setShowOtherOption(!showOtherOption);
                      }
                      }
                    >
                      <div>
                        <UnfoldMoreIcon className="me-1" />
                        {showOtherOption
                          ? "Hide Options"
                          : `${data?.otherOptions?.length} More Options`}
                      </div>
                    </button>
                  )
                )
              }

            </div>
          </div>
        </div>

        {/* /////////////////////// flight detail ///////////// */}
        {isExpanded ? (
          <div className="p-4">
            <div className="d-flex mb-4 flight_details_tabs">
              {["Fare options", "Flight details", "Fare Rules"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>

            {selectedTab === "Flight details" && (
              <>
                <div className="row rounded-2 shadow-sm border">
                  <div
                    className={`pb-4 w-full  p-4`}
                  >
                    {fligthSegment?.at(0)?.schedules?.map((item, inx, array) => {
                      return (
                        <ConnectedFlight
                          item={item}
                          inx={inx}
                          calculateTotalTimeDifference={
                            calculateTotalTimeDifference
                          }
                          elapsedTime={elapsedTime}
                          fligthSegment={array}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            )}


            {
              selectedTab === "Fare options" ? (
                <div className="rounded-2 shadow-sm border">
                  <AdditionalFares
                    data={data}
                    sabre={sabre}
                    selectedBrandFare={selectedBrandFare}
                    setSelectedBrandFare={setSelectedBrandFare}
                    totalFarePrice={totalFarePrice}
                    totalDiscountFarePrice={totalDiscountFarePrice}
                    calculatePassengerTotal={calculatePassengerTotal}
                    fligthSegment={fligthSegment}
                    findPrices={findPrices}
                    segmentLength={segmentLength}
                    calculateTotalTimeDifference={calculateTotalTimeDifference}
                    checkoutfare={checkoutfare}
                  />
                </div>
              ) : null}
            <div>
              {selectedTab === "Fare Rules" && (
                <div className="rounded-2 shadow-sm border bg-white p-4">
                  <SabrePenaltyAccordion selectedBrandFare={selectedBrandFare} CurrencyCode={CurrencyCode} />
                </div>
              )
              }
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SabreCard;

// connected flight component
const ConnectedFlight = ({
  item,
  inx,
  calculateTotalTimeDifference,
  fligthSegment,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${item?.departure?.airport}`
        );
        const data = response.data.data[0]?.airport_name;
        setairport_name(data);
      } catch (error) {
      }
    };

    const fetchDataArr = async () => {
      try {
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${item?.arrival?.airport}`
        );
        const data = response.data.data[0]?.airport_name;
        setArrairport_name(data);
      } catch (error) {
      }
    };

    fetchDataDep();
    fetchDataArr();
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <img
            src={`/NEW_LOGO/${item?.carrier?.operating}.png`}
            alt="logo"
            width={70}
            height={70}
          />
          <h6 className="mt-3 ms-2">
            {item?.carrier?.operating} {item?.carrier?.operatingFlightNumber}
          </h6>
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>{moment(item?.travdate).format("ddd DD-MMM-YY")}</h6>
              <h6 className="my-3">
                {moment(item?.departure?.time, "HH:mm:ssZ").format("HH:mm")}
              </h6>
              <h6>
                {item?.departure?.airport} - {airport_name}
              </h6>
              {item?.departure?.terminal && (
                <h6 className="color_primary">
                  (Terminal - {item?.departure?.terminal})
                </h6>
              )}
            </div>
          </div>

          <div>
            <div className="d-flex">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center">{calculateTotalTimeDifference(item?.departure?.time, item?.arrival?.time)}</h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>{moment(item?.travdate).format("ddd DD-MMM-YY")}</h6>
              <h6 className="my-3">
                {moment(item?.arrival?.time, "HH:mm:ssZ").format("HH:mm")}
              </h6>
              <h6>
                {item?.arrival?.airport} - {arr_airport_name}
              </h6>
              {item?.arrival?.terminal && (
                <h6 className="color_primary">
                  (Terminal - {item?.arrival?.terminal})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < fligthSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {calculateTotalTimeDifference(
                  item?.arrival?.time,
                  fligthSegment[inx + 1]?.departure?.time
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};

//additional fares component
const AdditionalFares = ({
  data,
  sabre,
  selectedBrandFare,
  setSelectedBrandFare,
  fligthSegment,
  totalFarePrice,
  totalDiscountFarePrice,
  calculatePassengerTotal,
  segmentLength,
  findPrices,
  calculateTotalTimeDifference,
  checkoutfare
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [boundToggle, setBoundToggle] = useState("outBound");

  const segmentsLength = segmentLength?.length;

  const outBoundformateDate =
    fligthSegment?.at(0)?.schedules?.at(0)?.travdate
  const outformattedDate = moment(outBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();
  const inBoundformateDate =
    fligthSegment?.at(1)?.schedules?.at(0)?.travdate
  const informattedDate = moment(inBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();

  const datafare = checkoutfare ? [checkoutfare] : data?.pricingInformation;

  const CustomPrevArrow = (props) => (
    <div
      onClick={props.onClick}
      className={`${data?.pricingInformation?.length <= 4 ? "d-none" : "d-block"
        } position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color`}
    >
      <FaChevronCircleLeft size={30} />
    </div>
  );
  const CustomNextArrow = (props) => (
    <div
      onClick={props.onClick}
      className={`${data?.pricingInformation?.length <= 4 ? "d-none" : "d-block"
        } position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index`}
    >
      <FaChevronCircleRight size={30} />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: datafare?.length <= 4 ? datafare?.length : 4,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };


  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const getAirportInfo = async (airportCode) => {
    const { data } = await NODEURL.get(
      `/api/v1/b2b/airport-list/${airportCode}`
    );
    const { code, city, airport_name } = data?.data;
    return `${code} - ${airport_name} - ${city}`;
  };

  const handleBooking = async (item) => {
    const segments = await Promise.all(
      segmentLength?.map(async (seg, index) => {

        const baggageSegment = item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.baggageInformation?.at(0)?.segments?.at(index);
        let baggage = "nil baggage";
        if (baggageSegment) {
          const { weight, unit, pieceCount } = baggageSegment;
          if (weight > 0) {
            baggage = `${weight} ${unit}`;
          } else if (pieceCount > 0) {
            baggage = `${pieceCount} piece${pieceCount > 1 ? "s" : ""}`;
          }
        }

        const locations = await Promise.all([
          getAirportInfo(seg?.departure?.airport),
          getAirportInfo(seg?.arrival?.airport),
        ]);
        return {
          departure_date: seg?.travdate,
          baggage: baggage,
          departure_time: moment(seg?.departure?.time, "hh:mm:ssZ").format("hh:mm"),
          arrival_date: seg?.travdate,
          arrival_time: moment(seg?.arrival?.time, "hh:mm:ssZ").format("hh:mm"),
          origin_terminal: seg?.departure?.terminal || "",
          desti_terminal: seg?.arrival?.terminal || "",
          flight_number: String(seg?.carrier?.operatingFlightNumber),
          number_in_party: "",
          res_book_desig_code: item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(0)?.segments?.at(index)?.segment?.bookingCode,
          status: "NN",
          des_location: locations?.at(1),
          dest_code: seg?.arrival?.airport,
          operatingairline_code: seg?.carrier?.operating,
          marketing_airline: seg?.carrier?.marketing,
          orig_location: locations?.at(0),
          orig_code: seg?.departure?.airport,
          marriage_grp: "O",
          cabin_class: item?.fare?.passengerInfoList?.at(0)
            ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName,
          segment_type: getSectorsName(
            searchValues?.locationDep,
            searchValues?.locationArrival
          ),
        };
      })
    )
    let bookingBody = {
      sabre: sabre,
      flight: data,
      fare: item,
      gds: "IATA BSP",
      price: totalFarePrice(item?.fare?.passengerInfoList),
      ttl_agent_fare: TwoDecimalPoint(
        CurrencyExchangeToPKR(Number(totalFarePrice(item?.fare?.passengerInfoList) || 0))
      ),
      // isCodeShare,
      // isEconomy,
      segmentsLength,
      // isExclusiveSector,
      ttlagent_curr_fare: Number(totalFarePrice(item?.fare?.passengerInfoList) || 0),
      ttl_supplier_fare: ~~item?.fare?.totalFare?.totalPrice || 0,
      ttlsup_curr_fare:
        TwoDecimalPoint(~~CurrencyExchange(item?.fare?.totalFare?.totalPrice)) || 0,
      adlt_ttl_d_curr:
        CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.total) || 0,
      chld_ttl_d_curr:
        CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.total) || 0,
      inft_ttl_d_curr:
        CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.total) || 0,
      adultBaseFare:
        CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.basePrice) || 0,
      childBaseFare: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.basePrice) || 0,
      infantBaseFare: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.basePrice) || 0,
      adultTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "ADT")?.taxes) || 0,
      childTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "CNN")?.taxes) || 0,
      infantTaxes: CurrencyExchangeToPKR(findPrices(item?.fare?.passengerInfoList, "INF")?.taxes) || 0,
      ADLT_TAXES: findPrices(item?.fare?.passengerInfoList, "ADT")?.paxesBreakUP || 0,
      CHLD_TAXES: findPrices(item?.fare?.passengerInfoList, "CNN")?.paxesBreakUP || 0,
      INFT_TAXES: findPrices(item?.fare?.passengerInfoList, "INF")?.paxesBreakUP || 0,
      // isEconomy:
      //   brandFare?.travelerPricings[0]?.fareDetailsBySegment[0]?.cabin ===
      //   "ECONOMY",
      // baggageName: brandFare.travelerPricings
      //   ?.at(0)
      //   ?.fareDetailsBySegment?.at(0)?.brandedFare,
      flightNumber: segmentLength?.at(0)?.carrier?.operatingFlightNumber,
      depDate: moment(
        segmentLength?.at(0)?.travdate
      ).format("ddd DD-MMM-YY"),
      depTime: moment(segmentLength?.at(0)?.departure?.time, "HH:mm:ssZ").format("HH:mm"),
      arrDate: moment(
        segmentLength?.at(segmentLength?.length - 1)?.travdate
      ).format("ddd DD-MMM-YY"),
      arrTime: moment(segmentLength?.at(segmentLength?.length - 1)?.arrival?.time, "HH:mm:ssZ").format("HH:mm"),
      flightDuration: calculateTotalTimeDifference(
        segmentLength?.at(0)?.departure?.time,
        segmentLength?.at(segmentLength?.length - 1)?.arrival?.time
      ),
      logo: "Sabre",
      code: item?.fare?.validatingCarrierCode,

      booking: {
        gds: "SABRE",
        pnr: "",
        agent_name: sabre?.agent_name,
        segments,
        tp_ur_code: "",
        platting_carrier: item?.fare?.governingCarriers,
        tp_ssr_verssion: 0,
        tp_trace_id: "",
      },
    };

    dispatch(
      setselectedFlight({
        ...bookingBody,
      })
    );

    navigate("/check-out");
  }



  return (
    <>
      <Slider  {...settings}>
        {datafare?.map((item, index) => {
          const brandFeatues = item?.fare?.passengerInfoList?.at(0)?.passengerInfo?.fareComponents?.at(0)?.brandFeatures;

          const amanities = brandFeatues?.map((brand) =>
            sabre?.brandFeatureDescs?.filter((feature) => feature?.id === brand?.ref)
          )?.flat();

          if (item?.soldOut) {
            return (
              <div className="px-4 ms-2 mb-2 mt-2 pt-2">
                <div className="fs-5 py-4  color_primary fw-bold">
                  {item?.soldOut?.soldOutLegs?.at(0)?.brandDescription}
                </div>
                <div className="h5 text-danger" style={{ marginTop: 32 }}>
                  Sold Out
                </div>
                <hr />
              </div>
            )
          }

          return (
            <div className="px-2 flight_brandFare_shodow ms-2 mb-2 mt-2 pt-2" key={index} onClick={() => {
              if (!checkoutfare) {
                setSelectedBrandFare(item)
                handleBooking(item)
              }
            }}>
              {item?.fare?.passengerInfoList?.at(0)
                ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand ?
                <div className="fs-5 py-4 color_primary fw-bold">
                  {item?.fare?.passengerInfoList?.at(0)
                    ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName}
                </div> : <></>
              }
              {/* <div className="text-danger d-flex align-items-center gap-2" style={{
                marginTop: item?.fare?.passengerInfoList?.at(0)
                  ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand ? 22 : 42
              }}>
                <AirlineSeatLegroomNormalIcon />{" "}
                <h6 className="mt-2">
                  {`${item?.fare?.passengerInfoList?.at(0)
                    ?.passengerInfo?.fareComponents?.at(0)?.segments?.at(0)?.segment?.seatsAvailable} Seat Left `}
                </h6>
              </div> 
              <hr /> */}
              {
                item?.fare?.passengerInfoList?.at(0)
                  ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand ?
                  <div>
                    {
                      amanities?.map((amanity, inx) => {
                        return (
                          <div className="d-flex align-items-center gap-2 pt-2">
                            <IoIosStarOutline className="color_primary" size={24} />
                            <h6 className="pt-2" key={inx}>
                              {amanity?.commercialName?.toLowerCase()}
                            </h6>
                          </div>
                        )
                      })}
                  </div>
                  :
                  <div>
                    <div className="d-flex align-items-center pt-2">
                      <Box width={40} className="color_primary">
                        {/* <FaSuitcaseRolling size={25} /> */}
                        <IoIosStarOutline className="color_primary" size={24} />
                      </Box>
                      <h6 className=" pt-2">
                        Hand Carry: 7 Kg
                      </h6>
                    </div>
                    <div className="d-flex pt-3">
                      <Box width={30} className="color_primary">
                        {/* <FaSuitcase size={22} /> */}
                        <IoIosStarOutline className="color_primary" size={24} />
                      </Box>

                      <div className="ps-1 pt-1 d-flex">
                        <h6>Check Bag:</h6>
                        <div>
                          {segmentLength?.map((seg, inx) => {
                            const baggageSegment = item?.fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation?.[0]?.segments?.[inx];
                            let baggageDescription = "nil baggage";
                            if (baggageSegment) {
                              const { weight, unit, pieceCount } = baggageSegment;
                              if (weight > 0) {
                                baggageDescription = `${weight} ${unit}`;
                              } else if (pieceCount > 0) {
                                baggageDescription = `${pieceCount} piece${pieceCount > 1 ? "s" : ""}`;
                              }
                            }
                            return (
                              <div key={inx} className="d-flex align-items-center">
                                <h6 className="ps-1">
                                  {seg?.departure?.airport} - {seg?.arrival?.airport}:
                                </h6>
                                <div className="ps-2 pb-2">{baggageDescription}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <Box display={"flex"} alignItems={"center"} pt={2}>
                      {/* <MdOutlineRestaurant size={25} className="color_primary" /> */}
                      <IoIosStarOutline className="color_primary" size={24} />
                      <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} pt={2}>
                      <Box>
                        {/* <AirlineSeatLegroomNormalIcon size={25} className="color_primary" /> */}
                        <IoIosStarOutline className="color_primary" size={24} />
                      </Box>
                      <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                    </Box>
                  </div>
              }

              <hr />

              <h6 className=" text-danger">{totalDiscountFarePrice(item?.fare?.passengerInfoList)}</h6>
              <LightTooltip
                title={
                  <div className="p-3" onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="d-flex me-4">
                        <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                        <h5>* {searchValues?.adultsCount}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {CurrencyCode()}{" "}
                        {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(0)?.passengerInfo, 1)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-end my-2">
                      <div className="d-flex">
                        <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                        <h5>* {searchValues?.childCount}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {CurrencyCode()}{" "}
                        {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(1)?.passengerInfo, 1) || 0}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="d-flex">
                        <h5 style={{ minWidth: "3rem" }}>INF</h5>
                        <h5>* {searchValues?.infantNo}</h5>
                      </div>
                      <h6 style={{ minWidth: "7rem" }}>
                        {CurrencyCode()}{" "}
                        {calculatePassengerTotal(item?.fare?.passengerInfoList?.at(2)?.passengerInfo, 1) || 0}
                      </h6>
                    </div>
                  </div>
                }
                placement="top"
              >
                <button
                  type="button"
                  className={`${selectedBrandFare?.fare?.passengerInfoList?.at(0)
                    ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName === item?.fare?.passengerInfoList?.at(0)
                      ?.passengerInfo?.fareComponents?.at(0)?.fareComponentDescs?.brand?.brandName ? "button_acitve" : "button_style"}  px-4 mb-2`}
                  onClick={() => {
                    if (!checkoutfare) {
                      setSelectedBrandFare(item)
                      handleBooking(item)
                    }
                  }}

                >
                  {`${CurrencyCode()} ${totalFarePrice(item?.fare?.passengerInfoList)}`}
                </button>
              </LightTooltip>
            </div>
          );
        })}
      </Slider>
    </>
  );
};


//additional fares component
const AdditionalFare = ({
  Data,
  airline,
  pricing,
  fligthSegment,
  user,
  sabreSupplier,
  setShowError,
  selectedFlight,
  sabreBrandFeatures,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const originalArray = Data?.AirItineraryPricingInfo;
  const additionalFaresArray = Data?.TPA_Extensions?.AdditionalFares?.map(
    (additionalFare) => additionalFare.AirItineraryPricingInfo
  );

  const newFaresArray = originalArray.concat(additionalFaresArray);

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const fares_array = newFaresArray.filter(
    (elemFilter) => elemFilter?.PTC_FareBreakdowns
  );

  const seatAvailable = (item) =>
    item?.FareInfos?.FareInfo?.reduce((min, current) =>
      current?.TPA_Extensions?.SeatsRemaining.Number <
        min?.TPA_Extensions?.SeatsRemaining.Number
        ? current
        : min
    );
  const adultFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.adultsCount
    );
  };
  ///// child fare
  const childFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.childCount
    );
  };
  ///// infant fare
  const infantFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.infantNo
    );
  };
  //calculate the total fare price
  const totalFarePrice = (fare) => {
    let adultFare = adultFareTotal(fare?.at(0));
    let childFare = childFareTotal(fare?.at(1));
    let infantFare = infantFareTotal(fare?.at(2));

    let total = adultFare + ~~childFare + ~~infantFare;

    return TwoDecimalPoint(total);
  };
  return (
    <div className="multi_fares_box pb-4">
      {fares_array?.map((item, index) => {
        const ptc_breakdown = item?.PTC_FareBreakdowns?.PTC_FareBreakdown;
        const brandFeatureRef = ptc_breakdown
          ?.at(0)
          ?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
            0
          )?.BrandFeatureRef;

        const brandFeatures = brandFeatureRef?.flatMap((feature) => {
          const array = sabreBrandFeatures?.filter(
            (sb) => sb?.Id === feature?.FeatureId
          );
          return [...array];
        });
        return (
          <div className="col-3 px-4" key={index}>
            <div className="fs-5 color_primary fw-bold">
              {
                ptc_breakdown
                  ?.at(0)
                  ?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
                    0
                  )?.BrandName
              }{" "}
            </div>
            <div className="text-danger my-3">
              <AirlineSeatLegroomNormalIcon />{" "}
              {seatAvailable(item)?.TPA_Extensions?.SeatsRemaining?.Number} Seat
              Left
            </div>
            {item?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)?.Endorsements
              ?.NonRefundableIndicator ? (
              <div className="non_refundable my-1">Non-Refundable</div>
            ) : (
              <div className="refundable my-1">Refundable</div>
            )}
            <hr />

            <div>
              <p className="fs-6 color_primary fw-bold">Baggage</p>
              <div className="d-flex align-items-center gap-2">
                <BsBagCheck size={20} className="color_primary" />
                <div>
                  {ptc_breakdown
                    .at(0)
                    ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                      0
                    )
                    ?.Allowance?.at(0)?.Weight ||
                    ptc_breakdown
                      ?.at(0)
                      ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                        0
                      )
                      ?.Allowance?.at(0)?.Pieces ||
                    0}{" "}
                  {ptc_breakdown
                    .at(0)
                    ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                      0
                    )
                    ?.Allowance?.at(0)?.Weight
                    ? ptc_breakdown
                      ?.at(0)
                      ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                        0
                      )
                      ?.Allowance?.at(0)?.Unit || "kg"
                    : "Pieces"}{" "}
                  Checked Bag
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                <MdOutlineRestaurant size={20} className="color_primary" />
                <div>{seatAvailable(item)?.TPA_Extensions?.Meal?.Code}</div>
              </div>
            </div>
            <hr />
            {/* refund and exchange dialog  */}
            {/* <RefundExchange
              penalties={
                ptc_breakdown?.at(0)?.PassengerFare?.PenaltiesInfo?.Penalty
              }
            /> */}
            {/* {brandFeatures?.length > 0 && (
              <BrandFeatures brandFeatures={brandFeatures} />
            )} */}
            <LightTooltip
              title={
                <div className="p-3">
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex me-4">
                      <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                      <h5>* {searchValues?.adultsCount}</h5>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        adultFareTotal(ptc_breakdown?.at(0), item)
                      )}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-end my-2">
                    <div className="d-flex">
                      <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                      <h5>* {searchValues?.childCount}</h5>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        childFareTotal(ptc_breakdown?.at(1), item)
                      )}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex">
                      <h5 style={{ minWidth: "3rem" }}>INF</h5>
                      <h5>* {searchValues?.infantNo}</h5>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {TwoDecimalPoint(
                        infantFareTotal(ptc_breakdown?.at(2), item)
                      )}
                    </h6>
                  </div>
                </div>
              }
              placement="top"
            >
              <button
                type="button"
                className="button_style px-4"
                onClick={() => {
                  // if (selectedFlight) {
                  //   dispatch(
                  //     setReturnFlight({
                  //       flight: Data,
                  //       price: totalFarePrice(ptc_breakdown),
                  //       adultBaseFare:
                  //         ~~ptc_breakdown?.at(0)?.PassengerFare?.EquivFare
                  //           ?.Amount,
                  //       adultTaxes:
                  //         ~~ptc_breakdown?.at(0)?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       childBaseFare:
                  //         ~~ptc_breakdown[1]?.PassengerFare?.EquivFare?.Amount,
                  //       childTaxes:
                  //         ~~ptc_breakdown[1]?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       infantBaseFare:
                  //         ~~ptc_breakdown[2]?.PassengerFare?.EquivFare?.Amount,
                  //       infantTaxes:
                  //         ~~ptc_breakdown[2]?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       baggageName: `${searchValues?.ticket_class?.toUpperCase()} (${
                  //         seatAvailable?.FareReference
                  //       })`,
                  //       logo: "Sabre",
                  //       code: fligthSegment?.at(0)?.MarketingAirline?.Code,
                  //       booking: {
                  //         gds: "SABRE", // should be gds code
                  //         agent: {
                  //           name: searchValues?.agent?.agent_name || "", // should be full name having space in between
                  //           email: searchValues?.agent?.email || "", // should be in proper email form
                  //           phone: searchValues?.agent?.phone || "", // should be without country code
                  //           country_code: "92", // country code without + sign
                  //         },
                  //         paxes: [
                  //           {
                  //             title: "", // MR | MS | MRS | MSTR | INF
                  //             first_name: "",
                  //             last_name: "",
                  //             nationality: "",
                  //             DOB: "",
                  //             doc_type: "", // CNIC | Passport
                  //             doc_no: "", // 11111-1111111-1 | asdf12345
                  //             doc_issue_country: "",
                  //             doc_expiry: "",
                  //             pax_type: "", // ADLT | CHLD | INFT
                  //           },
                  //         ],
                  //         segments: fligthSegment?.map((segment) => {
                  //           return {
                  //             departure_date:
                  //               segment?.DepartureDateTime?.split("T")?.at(0),
                  //             departure_time:
                  //               segment?.DepartureDateTime?.split("T")[1],
                  //             arrival_date:
                  //               segment?.ArrivalDateTime?.split("T")?.at(0),
                  //             arrival_time:
                  //               segment?.ArrivalDateTime?.split("T")[1],
                  //             origin_terminal:
                  //               segment?.DepartureAirport?.TerminalID || "", // in case of any
                  //             desti_terminal:
                  //               segment?.ArrivalAirport?.TerminalID || "", // in case of any
                  //             flight_number: segment?.FlightNumber,
                  //             number_in_party:
                  //               searchValues?.adultsCount +
                  //               searchValues?.childCount, // only mandatory for sabre
                  //             res_book_desig_code: segment?.ResBookDesigCode, // only mandatory for sabre
                  //             status: "NN",
                  //             des_location:
                  //               segment?.ArrivalAirport?.LocationCode, // only mandatory for sabre
                  //             marketing_airline:
                  //               segment?.MarketingAirline?.Code, // only mandatory for sabre
                  //             orig_location:
                  //               segment?.DepartureAirport?.LocationCode,
                  //             marriage_grp: segment?.MarriageGrp, // only mandatory for sabre
                  //             cabin_class: "Economy",
                  //             segment_type: getSectorsName(
                  //               segment?.DepartureAirport?.LocationCode,
                  //               segment?.ArrivalAirport?.LocationCode
                  //             ), // dom | siti | soto | group
                  //           };
                  //         }),
                  //         tp_ur_code: "", // mandatory for travelport only
                  //         platting_carrier: "", // mandatory for travelport only
                  //         tp_ssr_verssion: 3, // mandatory for travelport only
                  //         tp_trace_id: "", // mandatory for travelport only
                  //         adult_count: searchValues?.adultsCount,
                  //         child_count: searchValues?.childCount,
                  //         infant_count: searchValues?.infantNo,
                  //         ttl_agent_fare: totalFarePrice(ptc_breakdown), // "calculated price total"
                  //         user: { id: user?.user_id }, // logged in user id
                  //         agent_acc_id: searchValues?.agent?.acc_id || 0, // agent id in case of selected other wise logged in user id
                  //         supplier_acc_id: sabreSupplier?.acc_id || 0, // supplier id
                  //       },
                  //     })
                  //   );
                  //   if (searchValues?.agent === "" || (pricing && airline)) {
                  //     dispatch(setExpandFlightDetails(null));
                  //     navigate("/check-out");
                  //   } else {
                  //     setShowError(true);
                  //   }
                  // } else {
                  //   dispatch(
                  //     setselectedFlight({
                  //       flight: Data,
                  //       price: totalFarePrice(ptc_breakdown),
                  //       adultBaseFare:
                  //         ~~ptc_breakdown?.at(0)?.PassengerFare?.EquivFare
                  //           ?.Amount,
                  //       adultTaxes:
                  //         ~~ptc_breakdown?.at(0)?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       childBaseFare:
                  //         ~~ptc_breakdown[1]?.PassengerFare?.EquivFare?.Amount,
                  //       childTaxes:
                  //         ~~ptc_breakdown[1]?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       infantBaseFare:
                  //         ~~ptc_breakdown[2]?.PassengerFare?.EquivFare?.Amount,
                  //       infantTaxes:
                  //         ~~ptc_breakdown[2]?.PassengerFare?.Taxes?.TotalTax
                  //           ?.Amount,
                  //       baggageName: `${searchValues?.ticket_class?.toUpperCase()} (${
                  //         seatAvailable?.FareReference
                  //       })`,
                  //       logo: "Sabre",
                  //       code: fligthSegment?.at(0)?.MarketingAirline?.Code,
                  //       booking: {
                  //         gds: "SABRE", // should be gds code
                  //         agent: {
                  //           name: searchValues?.agent?.agent_name || "", // should be full name having space in between
                  //           email: searchValues?.agent?.email || "", // should be in proper email form
                  //           phone: searchValues?.agent?.phone || "", // should be without country code
                  //           country_code: "92", // country code without + sign
                  //         },
                  //         paxes: [
                  //           {
                  //             title: "", // MR | MS | MRS | MSTR | INF
                  //             first_name: "",
                  //             last_name: "",
                  //             nationality: "",
                  //             DOB: "",
                  //             doc_type: "", // CNIC | Passport
                  //             doc_no: "", // 11111-1111111-1 | asdf12345
                  //             doc_issue_country: "",
                  //             doc_expiry: "",
                  //             pax_type: "", // ADLT | CHLD | INFT
                  //           },
                  //         ],
                  //         segments: fligthSegment?.map((segment) => {
                  //           return {
                  //             departure_date:
                  //               segment?.DepartureDateTime?.split("T")?.at(0),
                  //             departure_time:
                  //               segment?.DepartureDateTime?.split("T")[1],
                  //             arrival_date:
                  //               segment?.ArrivalDateTime?.split("T")?.at(0),
                  //             arrival_time:
                  //               segment?.ArrivalDateTime?.split("T")[1],
                  //             origin_terminal:
                  //               segment?.DepartureAirport?.TerminalID || "", // in case of any
                  //             desti_terminal:
                  //               segment?.ArrivalAirport?.TerminalID || "", // in case of any
                  //             flight_number: segment?.FlightNumber,
                  //             number_in_party:
                  //               searchValues?.adultsCount +
                  //               searchValues?.childCount, // only mandatory for sabre
                  //             res_book_desig_code: segment?.ResBookDesigCode, // only mandatory for sabre
                  //             status: "NN",
                  //             des_location:
                  //               segment?.ArrivalAirport?.LocationCode, // only mandatory for sabre
                  //             marketing_airline:
                  //               segment?.MarketingAirline?.Code, // only mandatory for sabre
                  //             orig_location:
                  //               segment?.DepartureAirport?.LocationCode,
                  //             marriage_grp: segment?.MarriageGrp, // only mandatory for sabre
                  //             cabin_class: "Economy",
                  //             segment_type: getSectorsName(
                  //               segment?.DepartureAirport?.LocationCode,
                  //               segment?.ArrivalAirport?.LocationCode
                  //             ), // dom | siti | soto | group
                  //           };
                  //         }),
                  //         tp_ur_code: "", // mandatory for travelport only
                  //         platting_carrier: "", // mandatory for travelport only
                  //         tp_ssr_verssion: 3, // mandatory for travelport only
                  //         tp_trace_id: "", // mandatory for travelport only
                  //         adult_count: searchValues?.adultsCount,
                  //         child_count: searchValues?.childCount,
                  //         infant_count: searchValues?.infantNo,
                  //         ttl_agent_fare: totalFarePrice(ptc_breakdown), // "calculated price total"
                  //         user: { id: user?.user_id }, // logged in user id
                  //         agent_acc_id: searchValues?.agent?.acc_id || 0, // agent id in case of selected other wise logged in user id
                  //         supplier_acc_id: sabreSupplier?.acc_id || 0, // supplier id
                  //       },
                  //     })
                  //   );
                  // }
                }}
              >
                {CurrencyCode()} {totalFarePrice(ptc_breakdown)}
              </button>
            </LightTooltip>
          </div>
        );
      })}
    </div>
  );
};