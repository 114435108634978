import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  searchResult: [],
  searchResultData: [],
  searchValues: null,
  amadeus: [],
  amadeusBrandFare: [],
  amadeusPricing: {},
  pricingError: null,
  serene: null,
  sereneApi: [],
  sial: null,
  travelport: [],
  pia: null,
  airBlue: null,
  flyJinnah: null,
  flyJinnahFares: null,
  sabre: null,
  sabreBrandFeatures: null,
  loading: false,
  amadeusloading: false,
  pricingLoading: false,
  brandFareError: null,
  error: null,
  newDepDate: null,
  newArrivalDate: null,
  selectedFlight: null,
  expandFlightDetail: null,
  otherOptions: null,
  returnFlight: null,
  showMoreOption: null,
  bookButton: false,
  sialpnrData: null,
  pnrLoading: false,
  stop_filters: "ALL",
  gds_filter_drp: ["ALL"],
  gds_filter: "ALL",
  airline_Filter_drp: ["ALL"],
  airline_filter: "ALL",
  sort_by_filter: 1,
  all_prices_array: [],
  price_filter: [],
  refundable_filter: "ALL",
};

// getting search results
export const asyncGetFlightSearchResult = createAsyncThunk(
  "asyncGetFlightSearchResult/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/airport-list`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// get search results data
export const asyncFlightsSearchResults = createAsyncThunk(
  "asyncFlightsSearchResults/post",
  async (data, { dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/search-flights`, data);
      if (data?.gds === "Airsial") {
        dispatch(
          setSialData(
            response?.data?.message === "Flight Not fetched"
              ? []
              : response?.data?.data
          )
        );
        dispatch(setGDS_Filters_Drp("Air Sial"));
      } else if (
        data?.gds === "Sabre"
      ) {
        dispatch(setSabreFlight(response?.data?.data)
        );
        // dispatch(
        //   setSabreBrandFeatures(
        //     response?.data?.data?.OTA_AirLowFareSearchRS?.BrandFeatures
        //       ?.BrandFeature
        //   )
        // );
        dispatch(setGDS_Filters_Drp("Sabre"));
      } else if (response?.data?.data?.routes) {
        dispatch(setSereneData(response?.data?.data));
        dispatch(setGDS_Filters_Drp("Serene"));
      } else if (data.gds === "Serene-Api") {
        dispatch(setSereneApiData(response?.data?.data));
        dispatch(setGDS_Filters_Drp("Serene"));
      } else if (data.gds === "HITIT") {
        dispatch(setHITITdata(response?.data?.data));
        dispatch(setGDS_Filters_Drp("HITIT"));
      } else if (response?.data?.data?.PricedItinerary) {
        console.log(response?.data?.data?.PricedItinerary)
        dispatch(setAirBlue(response?.data?.data));
        dispatch(setGDS_Filters_Drp("Airblue"));
      } else if (response?.data?.response?.ondWiseFlightCombinations) {
        dispatch(
          setFlyJinnah(response?.data?.response?.ondWiseFlightCombinations)
        );
        dispatch(setGDS_Filters_Drp("Fly Jinnah"));
      } else if (response?.data?.data?.outbound) {
        dispatch(setAmadeus(response?.data?.data));
        dispatch(setGDS_Filters_Drp("Amadeus"));
      }
      return await response.data;
    } catch (error) {
      if (data.gds === "HITIT") dispatch(setHITITdata([]));
      if (data.gds === "Airsial") dispatch(setSialData(null));
      if (data.gds === "Serene") dispatch(setSereneData(null));
      if (data.gds === "Serene-Api") dispatch(setSereneApiData([]));
      if (data.gds === "AirBlue") dispatch(setAirBlue(null));
      if (data.gds === "Sabre") dispatch(setSabreFlight(null));
      if (data.gds === "Amadeus") dispatch(setAmadeus(null));
      if (data.gds === "FlyJinnah") dispatch(setFlyJinnah(null));
    }
  }
);

// amadeus brandFare api call
export const asyncAmadeusBrandFare = createAsyncThunk(
  "asyncAmadeusBrandFare/post",
  async ({ agent_name, flightOffers }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/amadeus-branded-fares", {
        agent_name: agent_name,
        flightOffers: [flightOffers],
      });
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
      return dispatch(setBrandFareError(error));
    }
  }
);

//amadeus pricing api call
export const asyncAmadeusPricing = createAsyncThunk(
  "asyncAmadeusPricing/post",
  async (
    { flightOffers, agent_name, type, navigate },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/amadeus-pricing", {
        type: type,
        flightOffers: [flightOffers],
        agent_name: agent_name,
      });
      dispatch(
        setAmadeusPricing(response.data?.data?.data?.flightOffers?.at(0))
      );
      if (navigate) navigate("/check-out");
      return response.data?.data?.data?.flightOffers?.at(0);
    } catch (error) {
      return dispatch(setPricingError(error?.response?.data?.message))
    }
  }
);

// Air sial pnr
export const asyncPostSialPnr = createAsyncThunk(
  "asyncPostSialPnr/post",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/sial-pnr`, body);
      dispatch(setPnrData(response?.data?.response));
      if (navigate) {
        navigate("/check-out");
      }
      return response?.data?.response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Serene pnr
export const asyncCreateSerenePnr = createAsyncThunk(
  "asyncCreateSerenePnr/post",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/serene-pnr`, body);
      dispatch(setPnrData(response?.data?.response));
      dispatch(setExpandFlightDetails(null));
      navigate("/check-out");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncSabreRevalidate = createAsyncThunk(
  "asyncSabreRevalidate/post",
  async (body, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/sabre-revalidate`, body);
      console.log("response", response);
      // dispatch(setExpandFlightDetails(null));
      // navigate("/check-out");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Fares (Fly Jinnah flight search result)
export const asyncGetFlyJinnahFares = createAsyncThunk(
  "asyncGetFlyJinnahFares/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/fly-jinnah-pnr`, data);
      dispatch(setFlyJinnahFares(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const FlightSearchSlice = createSlice({
  name: "FlightSearchSlice",
  initialState,
  reducers: {
    setBrandFareError(state, payload) {
      state.brandFareError = payload.payload;
    },
    setAmadeus(state, payload) {
      state.amadeus = payload?.payload;
    },
    setSearchValues(state, payload) {
      state.searchValues = payload?.payload;
    },
    setAmadeusPricing(state, payload) {
      state.amadeusPricing = payload?.payload;
    },
    setPricingError(state, payload) {
      state.pricingError = payload?.payload
    },
    setpricingErrorNull(state, payload) {
      state.pricingError = null
    },
    setSereneData: (state, payload) => {
      state.serene = payload?.payload;
    },
    setSereneApiData: (state, payload) => {
      state.sereneApi = payload?.payload;
    },
    setSialData: (state, payload) => {
      state.sial = payload.payload;
    },
    setHITITdata: (state, payload) => {
      state.pia = payload.payload;
    },
    setAirBlue: (state, payload) => {
      state.airBlue = payload.payload;
    },
    setFlyJinnah: (state, payload) => {
      state.flyJinnah = payload.payload;
    },
    setFlyJinnahFares: (state, payload) => {
      state.flyJinnahFares = payload.payload;
    },
    clearFlightResult: (state) => {
      state.serene = null;
      state.sereneApi = null;
      state.sial = null;
      state.pia = null;
      state.airBlue = null;
      state.sabre = null;
      state.sialpnrData = null;
      state.flyJinnah = null;
      state.flyJinnahFares = null;
      state.amadeus = null;
    },
    setNewDepDate: (state, payload) => {
      state.newDepDate = payload.payload;
    },
    setNewArrivalDate: (state, payload) => {
      state.newArrivalDate = payload.payload;
    },
    setselectedFlight: (state, payload) => {
      state.selectedFlight = payload?.payload;
    },
    setExpandFlightDetails: (state, payload) => {
      state.expandFlightDetail = payload?.payload;
    },
    setOtherOption: (state, payload) => {
      state.otherOptions = payload?.payload;
    },
    setReturnFlight: (state, payload) => {
      state.returnFlight = payload?.payload;
    },
    setSabreFlight: (state, payload) => {
      state.sabre = payload?.payload;
    },
    setSabreBrandFeatures: (state, payload) => {
      state.sabreBrandFeatures = payload?.payload;
    },
    setShowMoreOptions: (state, payload) => {
      state.showMoreOption = payload?.payload;
    },
    setBookButtonDisable: (state, payload) => {
      state.bookButton = payload?.payload;
    },
    setPnrData: (state, payload) => {
      state.sialpnrData = payload?.payload;
    },
    setStops_Filters: (state, payload) => {
      state.stop_filters = payload.payload;
    },
    setGDS_Filters_Drp: (state, payload) => {
      state.gds_filter_drp = Array.from(
        new Set(state.gds_filter_drp.concat(payload?.payload))
      );
    },
    set_GDS_selected_filter: (state, payload) => {
      state.gds_filter = payload?.payload;
    },
    set_Airline_filter_drp: (state, payload) => {
      state.airline_Filter_drp = Array.from(
        new Set(state.airline_Filter_drp.concat(payload?.payload))
      );
    },
    set_Airline_selected_filter: (state, payload) => {
      state.airline_filter = payload?.payload;
    },
    set_Filters_null: (state) => {
      state.stop_filters = "ALL";
      state.gds_filter_drp = ["ALL"];
      state.gds_filter = "ALL";
      state.airline_Filter_drp = ["ALL"];
      state.airline_filter = "ALL";
      state.all_prices_array = [];
    },
    set_sort_by_filter: (state, payload) => {
      state.sort_by_filter = payload.payload;
    },
    set_All_Prices_Array: (state, payload) => {
      const newValue = payload?.payload;
      // Check if the value doesn't already exist in the array
      if (!state.all_prices_array.includes(newValue)) {
        state.all_prices_array.push(newValue);

        // Optionally, if you want to keep the array sorted:
        state.all_prices_array.sort((a, b) => a - b);
      }
    },

    set_price_filter: (state, payload) => {
      state.price_filter = payload.payload;
    },
    set_Refundable_filter: (state, payload) => {
      state.refundable_filter = payload.payload;
    },
  },

  extraReducers: (builder) => {
    //amadeus brandFares///
    builder.addCase(asyncAmadeusBrandFare.pending, (state) => {
      state.amadeusloading = true;
      state.error = null;
    });
    builder.addCase(asyncAmadeusBrandFare.fulfilled, (state, { payload }) => {
      state.amadeusloading = false;
      state.error = null;
      state.amadeusBrandFare = payload;
    });
    builder.addCase(asyncAmadeusBrandFare.rejected, (state, { payload }) => {
      state.amadeusloading = false;
      state.error = payload;
    });

    // amadeus pricing call
    builder.addCase(asyncAmadeusPricing.pending, (state) => {
      state.pricingLoading = true;
      state.error = null;
    });
    builder.addCase(asyncAmadeusPricing.fulfilled, (state, { payload }) => {
      state.pricingLoading = false;
      state.error = null;
      state.amadeusPricing = payload;
    });
    builder.addCase(asyncAmadeusPricing.rejected, (state, { payload }) => {
      state.pricingLoading = false;
      state.error = payload;
    });

    // getting Airport
    builder.addCase(asyncGetFlightSearchResult.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetFlightSearchResult.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.recentAirPortData = payload;
      }
    );
    builder.addCase(
      asyncGetFlightSearchResult.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // getting search results data
    builder.addCase(asyncFlightsSearchResults.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncFlightsSearchResults.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.searchResultData = payload;
      }
    );
    builder.addCase(
      asyncFlightsSearchResults.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // create pnr sial
    builder.addCase(asyncPostSialPnr.pending, (state) => {
      state.pnrLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPostSialPnr.fulfilled, (state, { payload }) => {
      state.pnrLoading = false;
      state.error = null;
      state.sialpnrData = payload;
    });
    builder.addCase(asyncPostSialPnr.rejected, (state, { payload }) => {
      state.pnrLoading = false;
      state.error = null;
    });

    // Get Fly Jinnah flight fares details
    builder.addCase(asyncGetFlyJinnahFares.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetFlyJinnahFares.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.flyJinnahFares = payload;
    });
    builder.addCase(asyncGetFlyJinnahFares.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  setSearchValues,
  setSereneData,
  setSialData,
  setHITITdata,
  setSereneApiData,
  setFlyJinnah,
  setFlyJinnahFares,
  clearFlightResult,
  setNewDepDate,
  setNewArrivalDate,
  setselectedFlight,
  setExpandFlightDetails,
  setReturnFlight,
  setAirBlue,
  setSabreFlight,
  setSabreBrandFeatures,
  setShowMoreOptions,
  setBookButtonDisable,
  setPnrData,
  setStops_Filters,
  setGDS_Filters_Drp,
  set_GDS_selected_filter,
  set_Airline_filter_drp,
  set_Airline_selected_filter,
  set_Filters_null,
  set_sort_by_filter,
  set_All_Prices_Array,
  set_price_filter,
  set_Refundable_filter,
  setAmadeus,
  setOtherOption,
  setBrandFareError,
  setAmadeusPricing,
  setPricingError,
  setpricingErrorNull
} = FlightSearchSlice.actions;
export default FlightSearchSlice.reducer;
