import React, { useEffect, useState } from "react";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";

import { ChangeStatus } from "../../../Utils/ChangeStatus";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import {
  asyncDeleteSabreCredential,
  asyncGetSabreApiCredentials,
  asyncGetSingleSabreApiCred,
  asyncPostSabreApiCredentials,
  asyncUpdateSabreCredential,
  editSabreCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/SabreCredentialSlice";
import { dropMenuProp } from "../../../Utils/MenuProps";

function Sabre() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { SabreCredentialsData, loading, editSabreCredentials } = useSelector(
    (state) => state.sabreCredential
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  //use effect functions
  useEffect(() => {
    dispatch(asyncGetSabreApiCredentials());
  }, [dispatch]);

  //  table columns
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/sabre-credential/status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Agent Name",
      name: "agent_name",
    },
    // {
    //   label: "PCC",
    //   name: "pcc",
    // },
    // {
    //   label: "Client ID",
    //   name: "sabre_client_id",
    //   options: {
    //     customBodyRender: (value) => {
    //       return (
    //         <div style={{ width: 200, whiteSpace: "normal", wordWrap: "break-word" }}>
    //           {value}
    //         </div>
    //       );
    //     },
    //   },
    // },

    // {
    //   label: "Client Secret",
    //   name: "sabre_client_secrete",
    // },
    {
      label: "ERP",
      name: "sabre_epr",
    },
    {
      label: "IPCC",
      name: "sabre_ipcc",
    },
    {
      label: "Password",
      name: "sabre_password",
    },
    {
      label: "IATA",
      name: "sabre_lniata",
    },
    {
      label: "Domain",
      name: "sabre_domain",
    },
    {
      label: "Rest URL",
      name: "sabre_rest_url",
    },
    {
      label: "Soap URL",
      name: "sabre_sopa_url",
    },
    // {
    //   label: "Prohibited CRR",
    //   name: "prohibited_crr",
    // },
    // {
    //   label: "Allow CRR",
    //   name: "allow_crr",
    // },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="d-flex">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSingleSabreApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  //initital value
  const initialValues = {
    agent_name: editSabreCredentials?.agent_name || "",
    // pcc: editSabreCredentials?.pcc || "",
    sabre_client_id: editSabreCredentials?.sabre_client_id || "",
    sabre_client_secrete: editSabreCredentials?.sabre_client_secrete || "",
    sabre_epr: editSabreCredentials?.sabre_epr || "",
    sabre_ipcc: editSabreCredentials?.sabre_ipcc || "",
    sabre_password: editSabreCredentials?.sabre_password || "",
    sabre_lniata: editSabreCredentials?.sabre_lniata || "",
    sabre_domain: editSabreCredentials?.sabre_domain || "",
    sabre_rest_url: editSabreCredentials?.sabre_rest_url || "",
    sabre_sopa_url: editSabreCredentials?.sabre_sopa_url || "",
    // prohibited_crr: editSabreCredentials?.prohibited_crr || "",
    // allow_crr: editSabreCredentials?.allow_crr || "",
    ticket_status: editSabreCredentials?.ticket_status || 1,
  };

  useEffect(() => {
    if (editSabreCredentials) {
      const initialSupplier = newSupplier.find(supplier => supplier.supp_name === editSabreCredentials.agent_name);
      setSelectedSupplier(initialSupplier || null);
    }
  }, [editSabreCredentials, newSupplier]);

  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("Agent Name is required"),
    // pcc: yup.string().required("PCC is required"),
    // sabre_client_id: yup.string().required("Client ID is required"),
    // sabre_client_secrete: yup.string().required("Client Secrete is required"),
    sabre_epr: yup.string().required("EPR is required"),
    sabre_ipcc: yup.string().required("IPCC is required"),
    sabre_password: yup.string().required("Password is required"),
    // sabre_lniata: yup.string().required("lniata is required"),
    sabre_domain: yup.string().required("Domain is required"),
    sabre_rest_url: yup.string().required("Rest Url is required"),
    // sabre_sopa_url: yup.string().required("Sopa Url is required"),
    // prohibited_crr: yup
    //   .string()
    //   .matches(/^[\w,]+$/, "Only comma-separated values are allowed")
    //   .test("valid-format", "Invalid format", (value) => {
    //     if (!value) return true; // Empty value is allowed
    //     const values = value.split(",");
    //     return values.every((item) => /^\w+$/.test(item));
    //   })
    //   .required("Prohibited crr is required"),

    // allow_crr: yup
    //   .string()
    //   .matches(/^[\w,]+$/, "Only comma-separated values are allowed")
    //   .test("valid-format", "Invalid format", (value) => {
    //     if (!value) return true; // Empty value is allowed
    //     const values = value.split(",");
    //     return values.every((item) => /^\w+$/.test(item));
    //   })
    //   .required("Allow crr is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => {
                  dispatch(editSabreCredentialNull());
                  navigate("/api-credentials");
                }}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit Sabre</div>
          </div>
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editSabreCredentials?.id;
              if (editSabreCredentials) {
                dispatch(asyncUpdateSabreCredential({ id, values }));
                dispatch(editSabreCredentialNull());
              } else {
                dispatch(asyncPostSabreApiCredentials(values));
              }
              resetForm({});
              setSelectedSupplier(null)
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-start pt-2">
                    <div className="col-2 mt-4 h-5rem">
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newSupplier}
                        getOptionLabel={(option) => option.supp_name}
                        value={selectedSupplier}
                        getOptionSelected={(option, value) => option.supp_name === value.supp_name}
                        onChange={(event, newValue) => {
                          setSelectedSupplier(newValue)
                          if (newValue) {
                            setFieldValue("supplier", newValue);
                            setFieldValue("agent_name", newValue.supp_name);
                          } else {
                            setFieldValue("supplier", "");
                            setFieldValue("agent_name", "");

                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Select Agent Name"
                          />
                        )}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>

                    {/* <div className="col-2 mt-4">
                      <TextField
                        label="PCC"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pcc"
                        value={values.pcc}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pcc", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pcc"
                      />
                    </div> */}

                    {/* <div className="col-2 mt-4 ">
                    <TextField
                      label="Client Id"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      name="sabre_client_id"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={values.sabre_client_id}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("sabre_client_id", value);
                      }}
                    />
                    <ErrorMessage
                      component={"div"}
                      className="mt-2 text-danger"
                      name="sabre_client_id"
                    />
                  </div> */}

                    {/* <div className="col-2 mt-4">
                      <TextField
                        label="Client Secret"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_client_secrete"
                        value={values.sabre_client_secrete}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_client_secrete", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_client_secrete"
                      />
                    </div> */}

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre ERP"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_epr"
                        value={values.sabre_epr}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_epr", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_epr"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="IPCC"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_ipcc"
                        value={values.sabre_ipcc}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_ipcc", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_ipcc"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre Password"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_password"
                        value={values.sabre_password}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_password", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_password"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre LNIATA"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_lniata"
                        value={values.sabre_lniata}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_lniata", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_lniata"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre Domain"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_domain"
                        value={values.sabre_domain}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_domain", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_domain"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre Rest URL"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_rest_url"
                        value={values.sabre_rest_url}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_rest_url", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_rest_url"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="Sabre Sopa URL"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="sabre_sopa_url"
                        value={values.sabre_sopa_url}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("sabre_sopa_url", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="sabre_sopa_url"
                      />
                    </div>

                    {/* <div className="col-2 mt-4">
                      <TextField
                        label="Prohibited CRR"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="prohibited_crr"
                        value={values.prohibited_crr}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("prohibited_crr", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="prohibited_crr"
                      />
                    </div> */}

                    {/* <div className="col-2 pt-3 mt-5">
                      <TextField
                        label="Allow CRR"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="allow_crr"
                        value={values.allow_crr}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("allow_crr", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="allow_crr"
                      />
                    </div> */}
                    <div className="col-2 mt-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-12 text-center py-3 pb-4">
                    <button className="button_style px-5 me-3" type="submit">
                      {loading ? (
                        <BtnLoader />
                      ) : editSabreCredentials ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </button>
                    {editSabreCredentials && (
                      <button
                        type="button"
                        className="button_style px-5 bg-danger"
                        onClick={() => {

                          dispatch(editSabreCredentialNull());
                          setSelectedSupplier(null)
                        }
                        }
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            Sabre Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteSabreCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={SabreCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div >
  );
}

export default Sabre;
