import React, { useState } from "react";
import AgentPNR from "./ImportTab/AgentPNR";
import NewPnr from "./ImportTab/NewPnr";
import "../../Styles/import.css";
import * as yup from "yup";
import CustomDropDown from "../CustomDrp";
import { Form, Formik, FieldArray } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { asyncPostAgentPnr } from "../../redux/features/agentPnr/agentPnr";
import { useNavigate } from "react-router-dom";

const ImportTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    ispnr: "Agent PNR",
    // agent pnr states
    pnr: "",
    l_name: "",
    gds: "",
    supplier: "",
    select_agent: "",
    //new pnr states passanger Information
    PassangerInfo: [
      {
        pax_title: "",
        pax_type: "",
        f_name: "",
        pax_l_name: "",
      },
    ],
    //new pnr states flight Information
    flightInfo: [
      {
        from: "",
        to: "",
        dep_date: "",
        dep_time: "",
        arrival_date: "",
        arrival_time: "",
        airline_code: "",
        airline_no: "",
      },
    ],
    //new pnr states fare Information
    adt_base_fare: "",
    psf_sp_per: "",
    tax_code: "",
    tax_amount: "",
    agent: "",
  };
  

   // Validation schema using Yup
   const validationSchema = yup.object().shape({
    pnr: yup.string().required("PNR is required"),
    gds: yup.string().required("GDS is required"),
    select_agent: yup.string().required("Agent is required"),
    select_agent: yup.object().shape({
      agent_name: yup.string().required("Agent is required")
    }),
    supplier: yup.object().shape({
      supp_name: yup.string().required("Supplier Name is required")
    }),
  });
  const handleSubmit = (values) => {

    const agentPnr = {
      pnr: values.pnr,
      // l_name: values.l_name,
      gds: values.gds,
      select_agent: values.select_agent,
      supplier: values.supplier
    }
    dispatch(asyncPostAgentPnr({agentPnr, navigate}));

  };

  const arry = ["Agent PNR", "New PNR"];

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue,errors,touched }) => (
        <Form>
          <div style={{ paddingTop: 30, paddingBottom: 27 }}>
            <div className="row align-items-end">
              <div className="col-6 col-sm-3">
                <FormControl variant="standard" className="w-100" sx={{}}>
                  <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name={"ispnr"}
                    value={values?.ispnr}
                    onChange={(e) => setFieldValue("ispnr", e.target.value)}
                    label=""
                  >
                    {arry.map((list) => (
                      <MenuItem
                        value={list}
                        sx={{ m: 1, bgcolor: "#fff" }}
                        key={list}
                      >
                        {list}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div>
              {values?.ispnr === "Agent PNR" && (
                <AgentPNR values={values} errors={errors} setFieldValue={setFieldValue} touched={touched} />
              )}
              {values?.ispnr === "New PNR" && (
                <NewPnr
                  values={values}
                  setFieldValue={setFieldValue}
                  FieldArray={FieldArray}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImportTab;