import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { dropMenuProp } from "../../Utils/MenuProps";
import { useDispatch } from "react-redux";
import { asyncUpdateFlightGroupPassenger } from "../../redux/features/promotion/FlightGroupsSlice";
import { at, debounce } from "lodash";
import {
  getAsyncBookingDetails,
  getAsyncSearchPNR,
} from "../../redux/features/booking/bookingDetailsSlice";
import BtnLoader from "../AppForm/BtnLoader";
import { getAsyncSingleCreditNote } from "../../redux/features/PaxRefund/PaxRefundsSlice";
import { useNavigate } from "react-router-dom";

const PassengerDataWithoutOrWithEdit = ({
  data,
  obj,
  paxState,
  setActive_Tab,
  setPaxState,
  booking_id,
}) => {
  const dispatch = useDispatch();
  const [paxData, setPaxData] = useState([]);
  const user = JSON.parse(localStorage.getItem("loginUser"));
const navigate = useNavigate()
  const [is_Loading, seIs_Loading] = useState(false);
  useEffect(() => {
    setPaxData(
      data?.booking?.FlightBookingModels &&
        data?.booking?.FlightBookingModels?.at(0)?.pax_tables?.map(
          (p, ind) => ({
            pax_id: p?.pax_id,
            pax_type: p?.pax_type,
            title: p?.pax_title,
            first_name: p?.fName,
            last_name: p?.lname,
            DOB: moment(p?.DOB?.split("T")[0]).format("DD-MMM-YYYY"),
            Passport: p?.pspt_cnic,
            ticket_no: p?.tkt_number || "0",
          })
        )
    );
  }, [data]);

  const PaxTitle = [
    {
      title: "MR",
    },
    {
      title: "MRS",
    },
    {
      title: "MS",
    },
  ];

  const PaxType = [
    {
      type: "ADT",
    },
    {
      type: "CNN",
    },
    {
      type: "INF",
    },
  ];
  const [pnrError, setPnrError] = useState({});

  const debouncedHandleChange = debounce((e, index) => {
    const param = e.target.value;
    dispatch(getAsyncSearchPNR(param))
      .then((response) => {
        setPnrError({
          ...pnrError,
          ["open_" + index]: response?.payload ? true : false,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, 3000);
  return (
    <>
      {!paxState ? (
        <div className="booked_items_table">
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <div className="booked_tabel_items h6">Type</div>
            <div className="booked_tabel_items h6">Title</div>
            <div className="booked_tabel_items h6">First Name</div>
            <div className="booked_tabel_items h6">Last Name</div>
            <div className="booked_tabel_items h6">DOB</div>
            <div className="booked_tabel_items h6">Passport #</div>
            <div className="booked_tabel_items h6">Ticket-No</div>
          </div>
          {data?.booking?.FlightBookingModels &&
            data?.booking?.FlightBookingModels[0]?.pax_tables?.map(
              (pax, index) => (
                <div className="d-flex justify-content-between align-items-center border-bottom">
                  <div className="booked_tabel_items d-flex gap-2 ">
                    <p>{pax?.pax_type}</p>
                    {pax?.cn_number?.split("-")?.at(0) === "CN" ? (
                      <p
                        className="text-danger "
                        style={{ cursor: "pointer" }}
                        // onClick={(e) => {
                        //   dispatch(
                        //     getAsyncSingleCreditNote({ id: pax?.cn_number })
                        //   )
                        //     .then((res) =>
                        //    { const searchedData=res?.payload
                        //        const booking_id = searchedData?.acc_voucher_trans
                        //          ?.at(0)
                        //          ?.trans_description?.split("/")
                        //          ?.at(0);
                        //        const tkt_number = searchedData?.acc_voucher_trans
                        //          ?.at(0)
                        //          ?.trans_description?.split("/")
                        //          ?.at(4);
                        //        const voucherId = searchedData?.voucher_id;

                        //        navigate("/manual-credit-new", {
                        //          state: {
                        //            voucherId: booking_id,
                        //            // postVoucher: postVoucher,
                        //            tkt_voucher_no: tkt_number,
                        //            voucher_id: voucherId,
                        //            voucher_no: pax?.cn_number,
                        //          },
                        //        });
                        //      }
                        //     )
                        //     .catch((err) => console.log(err));
                         
                        // }}
                      >
                        ({pax?.cn_number})
                      </p>
                    ) : null}
                  </div>
                  <div
                    className={`${
                      obj[data?.booking && data?.booking?.doc_status]
                    } booked_tabel_items booktablebody`}
                  >
                    {pax?.pax_title}
                  </div>
                  <div
                    className={`${
                      obj[data?.booking && data?.booking?.doc_status]
                    } booked_tabel_items booktablebody`}
                  >
                    {pax?.fName}
                  </div>
                  <div
                    className={`${
                      obj[data?.booking && data?.booking?.doc_status]
                    } booked_tabel_items booktablebody`}
                  >
                    {pax?.lname}
                  </div>
                  <div className="booked_tabel_items booktablebody">
                    {}
                    {moment(pax?.dob?.split("T")[0]).format("DD-MMM-YYYY")}
                  </div>
                  <div className="booked_tabel_items booktablebody">
                    {pax?.pspt_cnic}
                  </div>
                  <div className="booked_tabel_items booktablebody">
                    {pax?.tkt_number || ""}
                  </div>
                </div>
              )
            )}
        </div>
      ) : (
        <div className="booked_items_table">
          <div className="d-flex  border-bottom ">
            <div className="booked_tabel_items h6">Type</div>
            <div className="booked_tabel_items h6">Title</div>
            <div className="booked_tabel_items h6">First Name</div>
            <div className="booked_tabel_items h6">Last Name</div>
            <div className="booked_tabel_items h6 ">DOB</div>
            <div className="booked_tabel_items h6">Passport #</div>
            <div className="booked_tabel_items h6">Ticket-No</div>
          </div>

          <div>
            <Formik
              initialValues={{
                paxData,
              }}
              onSubmit={(values) => {
                let id =
                  data?.booking?.FlightBookingModels &&
                  data?.booking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
                    ?.book_id;
                let body = {
                  ...values,
                };
                seIs_Loading(true);

                dispatch(asyncUpdateFlightGroupPassenger({ id, body }))
                  .then(() => {
                    // Once the update is successful, fetch the updated booking details
                    dispatch(getAsyncBookingDetails({ id: booking_id }))
                      .then(() => {
                        setTimeout(() => {
                          setPaxState(false);
                          seIs_Loading(false);
                          setActive_Tab("Details");
                        }, 2500);
                      })
                      .catch((err) => {
                        console.error(
                          "Error fetching updated booking details:",
                          err
                        );
                      });
                  })
                  .catch((err) => {
                    console.error("Error updating passenger:", err);
                  });
              }}
              render={({ values, setFieldValue }) => (
                <Form>
                  <FieldArray
                    name="paxData"
                    render={(arrayHelpers) => (
                      <div className="d-flex  border-bottom flex-column gap-4  py-4">
                        {values.paxData && values.paxData.length > 0
                          ? values.paxData.map((pax, index) => (
                              <div key={index}>
                                <div style={{ display: "flex", gap: 110 }}>
                                  <div>
                                    <FormControl
                                      variant="standard"
                                      sx={{ m: 1, minWidth: 70 }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="pax_type"
                                        value={values?.paxData[index]?.pax_type}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `paxData.${index}.pax_type`,
                                            e.target.value
                                          );
                                        }}
                                        MenuProps={dropMenuProp}
                                      >
                                        {PaxType?.map((p, i) => (
                                          <MenuItem value={p?.type}>
                                            {p?.type}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>

                                  <div>
                                    <FormControl
                                      variant="standard"
                                      sx={{ m: 1, minWidth: 70 }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name="title"
                                        value={values?.paxData[index]?.title}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `paxData.${index}.title`,
                                            e.target.value
                                          )
                                        }
                                        MenuProps={dropMenuProp}
                                      >
                                        {PaxTitle?.map((item, index) => (
                                          <MenuItem
                                            value={item?.title}
                                            sx={{ m: 1, bgcolor: "#fff" }}
                                            // key={selectPassangerTypeList?.key}
                                          >
                                            {item?.title}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>

                                  <div>
                                    <TextField
                                      variant="standard"
                                      name="first_name"
                                      className="w-80 "
                                      style={{ width: 150 }}
                                      value={values?.paxData[index]?.first_name}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `paxData.${index}.first_name`,
                                          e.target.value.toUpperCase()
                                        );
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <TextField
                                      variant="standard"
                                      name="last_name"
                                      className="w-80"
                                      // style={{ width: 100 }}
                                      value={values?.paxData[index]?.last_name}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `paxData.${index}.last_name`,
                                          e.target.value.toUpperCase()
                                        );
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <DatePicker
                                      name={`paxData.${index}.DOB`}
                                      selected={moment(
                                        values?.paxData[index]?.DOB
                                      ).toDate()}
                                      className=" date_picker  "
                                      calendarClassName="custom-datepicker  "
                                      showYearDropdown
                                      scrollableYearDropdown
                                      dropdownMode="select"
                                      // minDate={
                                      //   values?.passenger[index]?.Type ===
                                      //   "ADULT"
                                      //     ? null
                                      //     : values?.passenger[index]?.Type ===
                                      //       "CHILD"
                                      //     ? moment(currentDate)
                                      //         .subtract(12, "years")
                                      //         .toDate()
                                      //     : values?.passenger[index]?.Type ===
                                      //       "INFANT"
                                      //     ? moment(currentDate)
                                      //         .subtract(2, "years")
                                      //         .toDate()
                                      //     : null
                                      // }
                                      // maxDate={
                                      //   values?.passenger[index]?.Type ===
                                      //   "ADULT"
                                      //     ? moment(currentDate)
                                      //         .subtract(12, "years")
                                      //         .toDate()
                                      //     : values?.passenger[index]?.Type ===
                                      //       "CHILD"
                                      //     ? moment(currentDate)
                                      //         .subtract(2, "years")
                                      //         .toDate()
                                      //     : values?.passenger[index]?.Type ===
                                      //       "INFANT"
                                      //     ? moment(currentDate)
                                      //         .subtract(0, "years")
                                      //         .toDate()
                                      //     : null
                                      // }
                                      placeholderText="DOB"
                                      onChange={(date) => {
                                        if (date) {
                                          const formattedDate =
                                            moment(date).format("YYYY-MM-DD");
                                          setFieldValue(
                                            `paxData.${index}.DOB`,
                                            formattedDate
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <TextField
                                      variant="standard"
                                      name="Passport"
                                      className="w-80"
                                      value={values?.paxData[index]?.Passport}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `paxData.${index}.Passport`,
                                          e.target.value.toUpperCase()
                                        );
                                      }}
                                    />
                                  </div>
                                  <div style={{ width: 300 }}>
                                    <TextField
                                      variant="standard"
                                      name="ticket_no"
                                      className="w-80"
                                      value={values?.paxData[index]?.ticket_no}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `paxData.${index}.ticket_no`,
                                          e.target.value.toUpperCase()
                                        );
                                        debouncedHandleChange(e, index);
                                      }}
                                      disabled={
                                        user?.user_id !== 1 ||
                                        data?.booking?.doc_status === "Pending"
                                      }
                                    />
                                    {pnrError["open_" + index] ? (
                                      <p className="position-absolute text-danger ">
                                        Ticket number already exist.
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                        <div className="text-center">
                          <button
                            className="px-4 py-2 selectbtn_style "
                            type="submit"
                          >
                            {is_Loading ? <BtnLoader /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default PassengerDataWithoutOrWithEdit;
