import React, { useState } from 'react'
import MultiSabreCard from './MultiSabreCard';

const MultiSabre = ({
    sabre,
    sabreItem,
    index,
    toggleDetail,
    expandFlightDetail,
    selectedFare,
}) => {
    const [showOtherOption, setShowOtherOption] = useState(false);
    return (
        <div
            className="search_engin_result_box flight_card_shadow bg-white"
            id={`Sabre${index}`}
            key={index}
        >
            <MultiSabreCard
                sabreItem={sabreItem}
                sabre={sabre}
                selectedFare={selectedFare}
                key={index}
                index={"Sabre" + index}
                isExpanded={expandFlightDetail === "Sabre" + index}
                toggleDetail={toggleDetail}
                showOtherOption={showOtherOption}
                setShowOtherOption={setShowOtherOption}
            />

            {showOtherOption ? (
                <div>
                    {sabreItem?.otherOptions?.map((otherOption, index) => {
                        return (
                            <MultiSabreCard
                                sabreItem={otherOption}
                                sabre={sabre}
                                key={index}
                                index={"Sabre" + index}
                                isExpanded={expandFlightDetail === "Sabre" + index}
                                toggleDetail={toggleDetail}
                            />
                        );
                    })}
                </div>
            ) : null}
        </div>
    )
}

export default MultiSabre
